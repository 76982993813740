import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Erro400 } from '../dto/erros';
import { EventDto } from '../dto/event';
import { KitDto } from '../dto/kit';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
  })
export class KitService{
    apiHost: string = environment.apiHost;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) { }

  public apply(uuid: string, campanhaUuid: string, replace: boolean, callback: Function){
    let params = new HttpParams();
    params = params.set('replace', String(replace));
    console.log(JSON.stringify(params));

    this.http.post(
      this.apiHost + '/api/v1/campaignkit/' + uuid + '/apply/' + campanhaUuid, null, 
      {headers: this.loginService.authorizationHeader(), params: params}).subscribe(
      (sucesso: Event) => {
          callback(null, sucesso);
      },
      (falha: Erro400) => {
          callback(falha, null);
      }
    );
  }

  public search(callback: Function) {
    this.http.get(this.apiHost + '/api/v1/campaignkit').subscribe(
      (themes: KitDto[]) => {
        callback(null, themes);
      },
      (error: any) => {
        callback(error, null);
      }
    );
  }

  public remove(uuid: string, callback: Function){
    this.http.delete(this.apiHost + '/api/v1/campaignkit/' + uuid, {headers: this.loginService.authorizationHeader()}).subscribe(
      (sucesso: any) => {
        callback(null, sucesso);
      },
      (falha: Erro400) => {
        callback(falha, null);
      }
    );
  }

  public create(kit: KitDto, callback: Function){
    this.http.post(this.apiHost + '/api/v1/campaignkit', kit, {headers: this.loginService.authorizationHeader()}).subscribe(
      (sucesso: KitDto) => {
          callback(null, sucesso);
      },
      (falha: Erro400) => {
          callback(falha, null);
      }
    );
  }

  public update(uuid: string, kit: KitDto, callback: Function){
    this.http.put(this.apiHost + '/api/v1/campaignkit/' + uuid, kit, {headers: this.loginService.authorizationHeader()}).subscribe(
      (sucesso: KitDto) => {
          callback(null, sucesso);
      },
      (falha: Erro400) => {
          callback(falha, null);
      }
    );
  }

  public get(uuid: string, callback: Function){
    this.http.get(this.apiHost + '/api/v1/campaignkit/' + uuid, {headers: this.loginService.authorizationHeader()}).subscribe(
        (sucesso: KitDto) => {
            callback(null, sucesso);
        },
        (falha: Erro400) => {
            callback(falha, null);
        }
    );
  }

  public upload(uuid: string, day: string, dynamic: string, ativo: string, file: File, callback: Function){
    var formdata: FormData = new FormData();
    if(day){
      formdata.append('day', day);
    }
    if(dynamic){
      formdata.append('dynamic', dynamic);
    }
    formdata.append('active', ativo);
    formdata.append('image', file);
    this.http.post(this.apiHost + '/api/v1/campaignkit/' + uuid + '/image', formdata, {headers: this.loginService.authorizationHeader()}).subscribe(
      (sucesso: KitDto) => {
          callback(null, sucesso);
      },
      (falha: Erro400) => {
          callback(falha, null);
      }
    );
  }

}