export interface Type {
    id: number;
    name: string;
    nameId: string;
  }
  
  export const types = [
    {
      id: 1,
      name: 'Gestor',
      nameId: 'Gestor'
    
    },
    {
      id: 2,
      name: 'Padrão',
      nameId: 'Padrao'
    
    }
  ];