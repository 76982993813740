import { Theme } from './theme';
export class FiltroBuscaDinamica {
    name: string;
    theme: string;
    code: string;

    constructor(filtroBuscaDinamica: FiltroBuscaDinamica = null) {
        if (filtroBuscaDinamica) {
            this.name = filtroBuscaDinamica.name;
            this.theme = filtroBuscaDinamica.theme;
            this.code = filtroBuscaDinamica.code;
        }
    }
}
