export class UsuarioFiltros {
   public firstName : string;
   public profileName : string;
   public pageSize : number;

   constructor(firstName:string = "" , profile:string = "", pageSize:number = 5) {
      this.firstName = firstName;
      this.profileName = profile;
      this.pageSize = pageSize;
   }

}
