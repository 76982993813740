import { Component, DoCheck, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subtitle } from 'src/app/dto/Subtitle';
import { Agenda, AgendaAtividade, AgendaDia, AgendaDinamica, AgendaItemRequest, AgendaRequest } from 'src/app/dto/agenda';
import { Dinamica } from 'src/app/dto/dinamica';
import { Erro400 } from 'src/app/dto/erros';
import { EventDto } from 'src/app/dto/event';
import { Interesse } from 'src/app/dto/interesse';
import { Theme } from 'src/app/dto/theme';
import { Unidade } from 'src/app/dto/unidade';
import { AgendaService } from 'src/app/services/agenda.service';
import { DinamicaService } from 'src/app/services/dinamica.service';
import { InteresseService } from 'src/app/services/interesse.service';
import { UnidadeService } from 'src/app/services/unidade.service';
import { dynamics } from "../agenda-dia/dynamics";
import { AgendaDinamicaComponent } from "../agenda-dinamica/agenda-dinamica.component";

@Component({
  selector: 'app-agenda-dia',
  templateUrl: './agenda-dia.component.html',
  styleUrls: ['./agenda-dia.component.css']
})

export class AgendaDiaComponent implements OnInit, OnChanges,DoCheck {
  TAB_DINAMICA = 'DINAMICAS';
  TAB_AREA = 'AREA';
  editarAtividade: boolean;
  dinamica: AgendaDinamica;
  dinamicas: Dinamica[];
  request: AgendaRequest;
  agenda: Agenda;
  tipo: string;
  arquivo: File;
  handleModal: boolean;
  agendaAtividade: AgendaAtividade;
  confirm: boolean;
  activities: AgendaItemRequest[] = [];
  dinamicaEscolhida: string;
  listaInteresses: Interesse[] = [];
  numeroAtividades: number;
  abaActive = this.TAB_DINAMICA;
  classeModalEditarDia:string = 'modal';
  editarNomeDiaModal:boolean = false;
  novoNomeDia:string = '';
  dynamics = dynamics;
  classAbaDinamica = 'is-active';
  classAbaArea = '';
  unidades : Unidade[] = [];
  unidadesEscolhidas : Unidade[] = [];
  mensagem: string = 'Dinâmica salva com sucesso';
  novoSubtitulo = '';
  isEditSubtitle = false;
  accordionActive = new Subtitle({subtitle: '', uuid: null, ordem:0}) ;
  isUpAccordion = true;

  @Input() evento: EventDto;
  @Input() dia: AgendaDia;
  @Input() temas: Theme[];
  @Output() agendaEditada = new EventEmitter();
  @Output() diaSelecionado = new EventEmitter();
  @Output() receberAgendaAoExcluirDinamica = new EventEmitter();

  @ViewChild(AgendaDinamicaComponent) child: AgendaDinamicaComponent;

  @ViewChild('subtitulo')
  subtituloInput!: ElementRef<HTMLInputElement>;

  constructor(
    private agendaService: AgendaService,
    private dinamicaService: DinamicaService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private interesseService: InteresseService,
    private unidadeService : UnidadeService
  ) { }
  ngDoCheck() {

    if (this.subtituloInput !== undefined) {
      this.subtituloInput.nativeElement.focus();
    }


  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dia){
      if(this.dia.subtitles.length>0 && this.accordionActive !== null){
        this.accordionActive = this.dia.subtitles.find(subtitle => {
          if(subtitle !== null && subtitle.subtitle !== null && this.accordionActive !== null){
            return subtitle.subtitle === this.accordionActive.subtitle;
          }
        });

      }
      this.isEditSubtitle = false;
      if(this.dia.subtitles.length>0){
        if(this.accordionActive == null){
          this.accordionActive = this.dia.subtitles[0];
        }
      }

    }
  }

  ngOnInit() {
    this.dinamica = new AgendaDinamica();
    this.dia.activities.forEach(atividade => {
      let addAtividade = new AgendaItemRequest(atividade);
      this.activities.push({...addAtividade});
    });
    this.numeroAtividades = this.activities.length;
    this.editarAtividade = false
    if(this.dia.subtitles.length>0){
      this.accordionActive = this.dia.subtitles[0];
    }
  }

  exporModalEditarDia() {
    this.classeModalEditarDia = 'modal is-active'
    this.listaInteresses = [this.dia.interestDetail]


  }

  fecharEditarDia() {
    this.classeModalEditarDia = 'modal';
  }

  editarDia() {
    let request:AgendaRequest = new AgendaRequest()
    if(this.dia.interest && !this.dia.order){
      this.toastr.error('', 'É necessário informar a ordem');
      return
    }
    request.interest = this.dia.interest;
    request.order = this.dia.order;
    request.uuid = this.dia.uuid;
    request.required = this.dia.required;
    request.subtitles = this.dia.subtitles;
    if(this.novoNomeDia){
      request.nome = this.novoNomeDia;
      this.dia.nome = this.novoNomeDia;
    }
    this.agendaService.editar(this.evento.uuid, request, (erro:any, sucesso:any) => {
      if (erro) {

        if (erro.error && erro.error.message && erro.status === 400) {
          this.toastr.error(erro.error.message)
        } else {
          this.toastr.error("Erro inesperado: " + JSON.stringify(erro))
        }

      } else {
        this.fecharEditarDia();
      }
      this.editarNomeDiaModal = false;
      this.novoNomeDia = '';
    })

  }

  recebendoAtivacaoDoModalAbrir(respostaDoModal){
    this.handleModal = respostaDoModal
  }

  recebendoAgendaAtividade(agendaAtividade){
    this.agendaAtividade = agendaAtividade
  }

  modalFechar(){
    this.handleModal = false
  }

  modalConfirmarExclusao(){
    this.child.excluirDinamicaDeAtividadeJaRealizada(this.agendaAtividade);
    this.handleModal = false;
  }

  obterDinamicas() {
    if (this.dinamica.theme && this.dinamica.type) {
      this.dinamicaService.buscar(this.dinamica.theme, this.dinamica.type, (erro: Erro400, sucesso: Array<Dinamica>) => {
        if (sucesso) {
          this.dinamicas = sucesso;
          if(sucesso.length > 0){
            this.dinamica.dynamic = sucesso[0].uuid;
            this.dinamicaEscolhida = sucesso[0].uuid;
          }
        } else if (erro && erro.error) {
          this.toastr.error('', erro.error.message);
        }
      });
    }else{
      this.dinamicas = [];
    }
  }

  obterUuidDinamicas(event){
    this.dinamicaEscolhida = event;
  }
  adicionarDinamica() {
    if(this.dinamica.type === 'FACT_OR_FAKE' || this.dinamica.type === 'GAME' || this.dinamica.type === 'VIDEO' || this.dinamica.type === 'NEW_HABIT' || this.dinamica.type === 'EXTRA'){
      if(!this.dinamica.dynamic){
        this.toastr.error('', 'É necessário selecionar uma dinâmica');
        return
      }
    }


    this.activities = [];
    this.dia.activities.forEach(atividade => {
      let addAtividade = new AgendaItemRequest(atividade);
      this.activities.push({...addAtividade});
    });

    if(this.editarAtividade){
      for(var i=0; i<this.activities.length; i++){
        var atual: AgendaItemRequest = this.activities[i];
        if(atual.uuidAtividade === this.dinamica.uuid){
          this.activities[i].uuid = this.dinamicaEscolhida;
          this.editarAtividade = false;
          break;
        }
      }
    }else{
      let addAtividade = new AgendaItemRequest();
      addAtividade.uuid = this.dinamicaEscolhida;
      addAtividade.order = this.numeroAtividades;
      addAtividade.subtitle = this.accordionActive;
      this.activities.push({...addAtividade});
    }

    this.request = new AgendaRequest();
    this.request.uuid = this.dia.uuid;
    this.request.day = this.dia.day;
    this.request.order = this.dia.order;
    this.request.required = this.dia.required;
    this.request.activities = this.activities;
    this.request.subtitles = this.dia.subtitles;
    this.mensagem = 'Dinâmica salva com sucesso';
    this.editarAgenda();
  }

  editarAgenda() {
    this.spinner.show();
    this.agendaService.editar(this.evento.uuid, this.request, (erro: Erro400, sucesso: Agenda) => {
      if (sucesso) {
        this.toastr.success('', this.mensagem);
        this.dinamica = new AgendaDinamica();
        this.dinamicaEscolhida = "";
        this.agenda = sucesso;
        this.retornarAgenda();
        this.activities = [];
        this.mensagem = 'Dinâmica salva com sucesso';
      } else if (erro && erro.error) {
        this.toastr.error('', erro.error.message);
      }
      this.spinner.hide();
    });
  }


  upload() {
    this.spinner.show();
    this.agendaService.upload(this.evento.uuid, this.dia.uuid, this.tipo, this.arquivo, null, (erro: Erro400, sucesso: Agenda) => {
      if (sucesso) {
        this.toastr.success('', 'Arquivo enviado com sucesso');
        this.agenda = sucesso;
        this.retornarAgenda();
      } else if ( erro && erro.error) {
        this.toastr.error('', erro.error.message);
      } else {
        this.toastr.error('', 'Aconteceu um erro ao tentar enviar a imagem, por favor, contate o responsável pelo sistema');
      }
      this.spinner.hide();
    });
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.arquivo = event.target.files[0];
    }
  }

  editar() {
    this.request = new AgendaRequest();
    this.request.uuid = this.dia.uuid;
    this.request.day = this.dia.day;
    this.validarSubtitulos();
    this.activities = [];
    this.dia.activities.forEach(atividade => {
      let addAtividade = new AgendaItemRequest(atividade);
      this.activities.push(addAtividade);
    });
    this.request.order = this.dia.order;
    this.request.required = this.dia.required;
    this.request.activities = this.activities;
    this.request.units = this.unidadesEscolhidas.map((u) => u.uuid)
    this.request.subtitles = this.dia.subtitles;
    this.editarAgenda();
  }

  validarSubtitulos() {
    if (this.dia.subtitles && this.dia.subtitles.length > 0) {
      let atividadesComSubtitulo = this.dia.activities.filter(a => a.subtitle && a.subtitle !== null)
      this.dia.activities = atividadesComSubtitulo;
    }

  }
  excluir() {
    if (! confirm('Tem certeza que deseja excluir o dia ' + this.dia.day + '? As pontuações, ações e publicações também serão excluídas.')) {
      return;
    }
    this.spinner.show()
    this.agendaService.excluir(this.evento.uuid, this.dia.uuid, (erro: Erro400, sucesso: Agenda) => {
      if (sucesso) {
        this.toastr.success('', 'Dia ' + this.dia.day + ' excluído com sucesso');
        this.dia = null;
        this.agenda = sucesso;
        this.retornarDiaSelecionado();
        this.retornarAgenda();
      } else {
        this.toastr.error('', erro.error.message);
      }
      this.spinner.hide();
    });
  }
  retornarAgenda() {
    this.agendaEditada.emit(this.agenda);
  }

  retornarDiaSelecionado() {
    this.diaSelecionado.emit(this.dia);
  }

  receberExcluirDinamica(){
    this.receberAgendaAoExcluirDinamica.emit(this.request)
  }

  receberAgenda(agendaEditada) {
    this.agenda = agendaEditada;

    this.retornarAgenda();
  }

  receberDinamica(dinamicaEdicao) {
    this.dinamica = dinamicaEdicao;
    this.editarAtividade = true;
    this.dinamicaEscolhida = this.dinamica.dynamic
    this.obterDinamicas();
  }

  handleFiltroInteresse(valor) {
    this.interesseService.buscar(valor, true, (erro:any, sucesso:Array<Interesse>) => {
      if (erro) {
        alert("Erro ao buscar interesses: " + JSON.stringify(erro))
      } else {
        this.listaInteresses = sucesso
      }
    })
  }

  editarNomeDoDia(){
    this.editarNomeDiaModal = true;
    this.novoNomeDia = this.dia.nome;

  }
  cancelarModalEditarNomeDoDia(){
    this.editarNomeDiaModal = false;
  }

  onChangeAbaDinamica(){
    this.classAbaDinamica = 'is-active'
    this.classAbaArea = ''
    this.abaActive = this.TAB_DINAMICA;
  }

  OnChangesAbaArea(){
    this.classAbaDinamica = ''
    this.classAbaArea = 'is-active'
    this.abaActive = this.TAB_AREA;
  }

  onSaveUnidades(event){
    this.unidadesEscolhidas = event;
    this.activities = [];
    this.mensagem = 'Área/unidade alterada com sucesso';
    this.editar();

  }

  incluirSubtitulo(){
    let ordem = 0
    if (this.dia.subtitles.length > 0){
      ordem = this.dia.subtitles.slice(-1)[0].ordem + 1
    }

    let subtitulo = new Subtitle({subtitle: this.novoSubtitulo,uuid: null, ordem});

    let atividadeComSubtitulo = [];
    if(this.dia.activities.length > 0 && this.dia.subtitles.length === 0 ){
      this.dia.activities.forEach(atividade => {
        atividade.subtitle = subtitulo;

      });
    }

    this.dia.subtitles = this.dia.subtitles.concat(subtitulo);
    this.accordionActive = {...subtitulo};
    this.isEditSubtitle = true;

  }

  isEditModeAccordion(subtitle: Subtitle){
    return this.accordionActive && this.accordionActive.uuid === subtitle.uuid && this.isEditSubtitle;
  }

  EditModeSubtitle(subtitle){
    this.accordionActive = {...subtitle};
    this.isEditSubtitle = true;
    this.isUpAccordion = true;
  }
  updateSubtitle(subtitle){
    this.accordionActive = {...subtitle};
    this.editar();
    this.isEditSubtitle = false;
    this.isUpAccordion = true;

  }
  isActiveAccordion(subtitle: Subtitle){
    if(this.accordionActive != null){
      if(this.accordionActive.uuid !== null){
        return this.accordionActive.uuid === subtitle.uuid;
      }else if(subtitle.uuid === null && subtitle.subtitle !== null){
        return true;
      }
      return false
    }

  }

  cancelSubtitle(subtitle: Subtitle){
    if(subtitle.uuid){
      this.isEditSubtitle = false;
    }else{
      if(this.dia.subtitles.length > 0){
        let arraySubtitle = this.dia.subtitles
        arraySubtitle.pop()
        this.dia.subtitles = arraySubtitle;
      }
      this.isEditSubtitle = false;
    }
  }

  upAcordion(subtitle){
    this.accordionActive = subtitle;
  }

  isActiveAccordionClass(subtitle){
    if(this.isActiveAccordion(subtitle)){
      return 'is-active link-ccordion';
    }
    return 'link-ccordion';
  }

  changerAccordionUpOrDown(subtitle){
    if(this.isActiveAccordion(subtitle)){
      this.isUpAccordion = !this.isUpAccordion;
    }else{
      this.accordionActive = {...subtitle};
      this.isUpAccordion = true;
    }

  }

  onUpAccordion(subtitle){
   return this.isActiveAccordion(subtitle) && this.isUpAccordion;
  }

  onRemoveAgendaSubtitulo(subtitle : Subtitle){
    if (! confirm('Tem certeza que deseja excluir o subtitulo ' + subtitle.subtitle + '? As pontuações, ações e publicações também serão excluídas.')) {
      return;
    }

    this.dia.subtitles = this.dia.subtitles.filter(sub => {
      if(sub != null){
        return sub.uuid !== subtitle.uuid
      }
    });

    this.dia.activities = this.dia.activities.filter(atividade => {
      if(atividade.subtitle != null){
        return atividade.subtitle.uuid !== subtitle.uuid;
      }});

      if(this.dia.subtitles.length > 1){
        this.dia.subtitles.filter(sub => sub.ordem > subtitle.ordem).forEach(sub => {
          sub.ordem = sub.ordem - 1;
        });
      }

     this.editar();
  }

  ableToUp(subtitle : Subtitle){
    let able: boolean = true;
    for(var i=0; i<this.dia.subtitles.length; i++){
      if(this.dia.subtitles[i].uuid === subtitle.uuid){
        if(i === 0){
          able = false;
        }
      }
    }
    return able
  }

  ableToDown(subtitle : Subtitle){
    let able: boolean = true;
    for(var i=0; i<this.dia.subtitles.length; i++){
      if(this.dia.subtitles[i].uuid === subtitle.uuid){
        if(i === (this.dia.subtitles.length - 1)){
          able = false;
        }
      }
    }
    return able
  }

  up(subtitulo:Subtitle) {
    this.ordenarSubtitulos();
    const indexSubtitulo = this.dia.subtitles.findIndex((sub) => sub.uuid === subtitulo.uuid);
    if (indexSubtitulo !== -1 && indexSubtitulo > 0) {
      let subtituloAnterior = this.dia.subtitles[indexSubtitulo - 1];
      let ordemSubtituloAnterior = subtituloAnterior.ordem;
      let ordemSubtituloAtual = subtitulo.ordem;
      this.dia.subtitles[indexSubtitulo].ordem = ordemSubtituloAnterior;
      subtituloAnterior.ordem = ordemSubtituloAtual;
      this.editar();
      this.isEditSubtitle = false;
      this.isUpAccordion = true;
    }
  }

  ordenarSubtitulos() {
    this.dia.subtitles.sort((a, b) => a.ordem - b.ordem);
    this.dia.subtitles.forEach((subtitulo, indice) => {
      subtitulo.ordem = indice;
    });
  }



  down(subtitulo: Subtitle) {
    this.ordenarSubtitulos();
    const indiceSubtitulo = this.dia.subtitles.findIndex(sub => sub.uuid === subtitulo.uuid);
    if (indiceSubtitulo !== -1 && indiceSubtitulo < this.dia.subtitles.length - 1) {
      let proximoSubtitulo = this.dia.subtitles[indiceSubtitulo + 1];
      let ordemProximoSubtitulo = proximoSubtitulo.ordem;
      let ordemSubtituloAtual = subtitulo.ordem;
      this.dia.subtitles[indiceSubtitulo].ordem = ordemProximoSubtitulo;
      proximoSubtitulo.ordem = ordemSubtituloAtual;
      this.editar();
      this.isEditSubtitle = false;
      this.isUpAccordion = true;
    }
  }




}
