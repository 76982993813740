import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Bloco } from 'src/app/dto/bloco-dinamica/bloco';
import { FiltroBuscaDinamica } from 'src/app/dto/filtroBuscaDinamica';
import { Theme } from 'src/app/dto/theme';
import { BlocoDinamicaService } from 'src/app/services/bloco-dinamica.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-bloco-dinamica-list',
  templateUrl: './bloco-dinamica-list.component.html',
  styleUrls: ['./bloco-dinamica-list.component.css']
})
export class BlocoDinamicaListComponent implements OnInit {
  blocos: Bloco[] = [];

  filtroDinamica: FiltroBuscaDinamica = new FiltroBuscaDinamica();

  themes: Theme[] = [];
  textoBuscar: string = "Buscar"
  classeBuscar: string = "button is-primary"

  constructor(
    private blocoDinamicaService: BlocoDinamicaService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private themeService: ThemeService,
    private router: Router

    ) { }

  ngOnInit() {
    this.filtroDinamica.name = '';
    this.filtroDinamica.theme = ""
    this.themeService.themes(null, (__erro: any, temasRetornados: Theme[]) => {
      this.themes = temasRetornados;
    });
    this.buscar();
  }

  onSetFiltro(event: FiltroBuscaDinamica ) {
    this.filtroDinamica = event;
    this.buscar();
  }

  buscar() {
    this.textoBuscar = "Buscando..."
    this.classeBuscar = "button is-light"
    this.blocoDinamicaService.buscar(this.filtroDinamica).subscribe(
      (sucess: Bloco[]) => {
        this.blocos = sucess;
        this.textoBuscar = "Buscar"
        this.classeBuscar = "button is-primary"
      },
      error => {
        this.toastr.error(error.error.message);
      }

    );

  }
  onClickRow(event: Bloco) {
    let navigationExtras: NavigationExtras
    navigationExtras = {
      state: {
        bloco: event
      }
    };
    this.router.navigate(['/bloco/edit/' + event.uuid], navigationExtras);
  }

}
