export class Questao{
    uuid: string
    order: number
    fact: boolean
    enunciation: string
    answer: string
    picture: string

    constructor(q: Questao = null){
        if(q){
            this.uuid = q.uuid;
            this.order = q.order;
            this.fact = q.fact;
            this.enunciation = q.enunciation;
            this.answer = q.answer;
            this.picture = q.picture;
        }else{
            this.fact = false;
        }
    }

    isFact(){
        if(this.fact){
            return "checked";
        }else{
            return "";
        }
    }
}