import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Interesse } from 'src/app/dto/interesse';
import { InteresseService } from 'src/app/services/interesse.service';
import { Erro400 } from 'src/app/dto/erros';

@Component({
  selector: 'app-interesse-form',
  templateUrl: './interesse-form.component.html',
  styleUrls: ['./interesse-form.component.css']
})
export class InteresseFormComponent implements OnInit {

  interesse: Interesse

  constructor( 
    private interesseService: InteresseService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {
    this.resetaTela('rota');
  }

  resetaTela(origem: string){
    this.interesse = new Interesse();
 
    if(origem && origem == 'rota'){
      var uuid = this.route.snapshot.paramMap.get("uuid");
      if(uuid){
        this.interesse = new Interesse(history.state.interesse);
      }
    }
  }

  persist(redirect: string){
    this.spinner.show();
    if(this.interesse.uuid){
      
      this.interesseService.editar(this.interesse, (error: any, sucesso: Interesse) => {
        
        if(sucesso){
          this.interesse = new Interesse(sucesso);
          this.toastr.success('', 'O interesse foi editado com sucesso');
          if(redirect){
            if(redirect === 'NOVO') {
              this.resetaTela('cadastro');
            } else{
              this.router.navigate([redirect])
            }
          }
        }
        if(error){
          if(error.error && error.error.message){
            this.toastr.error('', error.error.message);
          } else{
            this.toastr.error('', 'Falha ao editar interesse, entre em contato com o suporte ou tente novamente mais tarde');
          }
        }
        this.spinner.hide();
      });
    }else{
      this.interesseService.criar(this.interesse, (error: any, sucesso: Interesse) => {
        if(sucesso){
          this.interesse = new Interesse(sucesso);
          this.toastr.success('', 'O interesse foi criado com sucesso');
          if(redirect){
            if(redirect === 'NOVO') {
              this.resetaTela('cadastro');
            } else{
              this.router.navigate([redirect])
            }
          }
        }
        if(error){
          if(error.error && error.error.message){
            this.toastr.error('', error.error.message);
          } else{
            this.toastr.error('', 'Falha ao cadastrar interesse, entre em contato com o suporte ou tente novamente mais tarde');
          }
        }
        this.spinner.hide();
      });
    }
  }

  delete(){
    this.spinner.show();
    if(this.interesse.uuid){
      this.interesseService.remover(this.interesse.uuid, (error: any, sucesso: any) => {

        if(error){
          if(error.error && error.error.message){
            this.toastr.error('', error.error.message);
          } else{
            this.toastr.error('', 'Falha ao excluir interesse, entre em contato com o suporte ou tente novamente mais tarde');
          }
        } else{
          this.toastr.success('', 'O interesse foi excluído com sucesso');
          this.router.navigate(['interesses'])
        }
        this.spinner.hide();
      })
    }
  }



}
