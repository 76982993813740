import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';


/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private loginService: LoginService,
        private spinner: NgxSpinnerService
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                let loginFalho:boolean = false
                loginFalho = req.url.endsWith('auth')
                this.spinner.hide();
                if(! loginFalho && (error.status == 401 || error.status == 403)){
                    this.loginService.logout(() => {
                        this.router.navigateByUrl('')
                    })
                }
            return throwError(error);
        }));
    }
}