import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import {
	DatePickerModule,
	DateTimePickerModule
} from "@syncfusion/ej2-angular-calendars";
import { TextMaskModule } from "angular2-text-mask";
import { NgxNumberFormatModule } from "ngx-number-format";
import { QuillModule } from "ngx-quill";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpsRequestInterceptor } from "./intercept/request-intercept";
import { HttpResponseInterceptor } from "./intercept/response-intercept";
import { AgendaAreaComponent } from './views/agenda-dia/agenda-area/agenda-area.component';
import { UnidadeItemComponent } from './views/agenda-dia/agenda-area/unidade-item/unidade-item.component';
import { AgendaDiaComponent } from "./views/agenda-dia/agenda-dia.component";
import { AgendaDinamicaComponent } from "./views/agenda-dinamica/agenda-dinamica.component";
import { AgendaComponent } from "./views/agenda/agenda.component";
import { AvisosComponent } from './views/avisos/avisos.component';
import { BibliotecalistComponent } from "./views/biblioteca-list/biblioteca-list.component";
import { BlocoDinamicaFormComponent } from './views/bloco-dinamica-form/bloco-dinamica-form.component';
import { DadosBlocoComponent } from './views/bloco-dinamica-form/dados-bloco/dados-bloco.component';
import { DinamicasFormComponent } from './views/bloco-dinamica-form/dinamicas-form/dinamicas-form.component';
import { BlocoDinamicaListComponent } from "./views/bloco-dinamica-list/bloco-dinamica-list.component";
import { DataTableBlocoComponent } from './views/bloco-dinamica-list/data-table-bloco/data-table-bloco.component';
import { FiltroBuscaComponent } from './views/bloco-dinamica-list/filtro-busca/filtro-busca.component';
import { CamposFormComponent } from "./views/campos-form/campos-form.component";
import { ClienteslistComponent } from "./views/clientes-list/clientes-list.component";
import { AreaFormComponent } from './views/clients-form/area-form/area-form.component';
import { SubareaFormComponent } from './views/clients-form/area-form/subarea-form/subarea-form.component';
import { AreaListComponent } from './views/clients-form/area-list/area-list.component';
import { ClientesformComponent } from "./views/clients-form/clientes-form.component";
import { ComoFuncionaListComponent } from './views/como-funciona-list/como-funciona-list.component';
import { ComoFuncionaListFormComponent } from './views/como-funciona/como-funciona-list-form/como-funciona-list-form.component';
import { NoSanitizePipe } from "./views/como-funciona/como-funciona-list-form/utils/nosanitizerpipe";
import { ArquivoErrosComponent } from './views/elegibilidade/arquivo-erros/arquivo-erros.component';
import { ArquivosComponent } from './views/elegibilidade/arquivos/arquivos.component';
import { ElegibilidadeComponent } from "./views/elegibilidade/elegibilidade.component";
import { EstilosFormComponent } from './views/estilos-form/estilos-form.component';
import { ImagemFundoComponent } from './views/estilos-form/imagem-fundo/imagem-fundo.component';
import { EventoformComponent } from "./views/event-form/evento-form.component";
import { UploadImagemComponent } from './views/event-form/upload-imagem/upload-imagem/upload-imagem.component';
import { EventoslistComponent } from "./views/eventos-list/eventos-list.component";
import { ExtraFormComponent } from "./views/extra-form/extra-form-component";
import { ExtraListComponent } from "./views/extra-list/extra-list.component";
import { FatoFakeFormComponent } from "./views/fato-fake-form/fato-fake-form.component";
import { FatoFakeListComponent } from "./views/fato-fake-list/fato-fake-list.component";
import { GameFormComponent } from "./views/game-form/game-form.component";
import { GameListComponent } from "./views/game-list/game-list.component";
import { GestoresComponent } from './views/gestores/gestores.component';
import { HomeComponent } from "./views/home/home.component";
import { ImagemApresentacaoDinamicaComponent } from "./views/imagem-apresentacao-dinamica/imagem-apresentacao-dinamica.component";
import { InteresseFormComponent } from "./views/interesse-form/interesse-form.component";
import { InteresseListComponent } from "./views/interesse-list/interesse-list.component";
import { KitFormComponent } from "./views/kit-form/kit-form.component";
import { KitIconesFormComponent } from "./views/kit-icones-form/kit-icones-form.component";
import { KitIconesListComponent } from './views/kit-icones-list/kit-icones-list.component';
import { KitListComponent } from "./views/kit-list/kit-list.component";
import { LoginComponent } from "./views/login/login.component";
import { MensagemFormComponent } from './views/mensagem-form/mensagem-form.component';
import { MensagemListComponent } from './views/mensagem-list/mensagem-list.component';
import { MensagemVideoFormComponent } from "./views/mensagem-video-form/mensagem-video-form.component";
import { MensagemVideoListComponent } from "./views/mensagem-video-list/mensagem-video-list.component";
import { MuralFormComponent } from './views/mural-form/mural-form-component';
import { MuralListComponent } from "./views/mural-list/mural-list.component";
import { NewHabitForm } from "./views/new-habit-form/new-habit-form.component";
import { NewHabitListComponent } from "./views/new-habit-list/new-habit-list.component";
import { ParticipanteForm } from "./views/participante-form/participante-form.component";
import { ParticipanteListComponent } from "./views/participante-list/participante-list.component";
import { RelatorioComponent } from "./views/relatorio/relatorio.component";
import { TemplateFormComponent } from './views/template-form/template-form.component';
import { TemplateListComponent } from './views/template-list/template-list.component';
import { ThemesFormComponent } from "./views/themes-form/themes-form.component";
import { ThemesListComponent } from "./views/themes-list/themes-list.component";
import { UploadTrofeuKitComponent } from "./views/upload-trofeu-kit/upload-trofeu-kit.component";
import { UserformComponent } from "./views/user-form/user-form.component";
import { UserslistComponent } from "./views/users-list/users-list.component";
import { VideoFormComponent } from "./views/video-form/video-form.component";
import { VideoListComponent } from "./views/video-list/video-list.component";
registerLocaleData(localePt);

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		HomeComponent,
		UserslistComponent,
		UserformComponent,
		ClienteslistComponent,
		EventoslistComponent,
		BibliotecalistComponent,
		ClientesformComponent,
		EventoformComponent,
		ThemesListComponent,
		ThemesFormComponent,
		FatoFakeListComponent,
		FatoFakeFormComponent,
		VideoListComponent,
		VideoFormComponent,
		GameListComponent,
		GameFormComponent,
		MuralListComponent,
		AgendaComponent,
		AgendaDiaComponent,
		AgendaDinamicaComponent,
		ElegibilidadeComponent,
		KitListComponent,
		KitFormComponent,
		UploadTrofeuKitComponent,
		ParticipanteListComponent,
		ParticipanteForm,
		NewHabitListComponent,
		NewHabitForm,
		InteresseListComponent,
		InteresseFormComponent,
		MensagemVideoFormComponent,
		MensagemVideoListComponent,
		MensagemListComponent,
		MensagemFormComponent,
		EstilosFormComponent,
		CamposFormComponent,
		ExtraListComponent,
		ExtraFormComponent,
		RelatorioComponent,
		GestoresComponent,
		KitIconesListComponent,
		KitIconesFormComponent,
		TemplateListComponent,
		TemplateFormComponent,
		ComoFuncionaListFormComponent,
		ComoFuncionaListComponent,
		NoSanitizePipe,
		ImagemApresentacaoDinamicaComponent,
		MuralFormComponent,
		BlocoDinamicaListComponent,
		FiltroBuscaComponent,
		DataTableBlocoComponent,
		BlocoDinamicaFormComponent,
		DadosBlocoComponent,
		DinamicasFormComponent,
		ArquivosComponent,
		ArquivoErrosComponent,
		AgendaAreaComponent,
		UploadImagemComponent,
		ImagemFundoComponent,
		AvisosComponent,
		AreaListComponent,
		AreaFormComponent,
		SubareaFormComponent,
		UnidadeItemComponent,
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		FormsModule,
		TextMaskModule,
		NgxSpinnerModule,
		NgxNumberFormatModule,
		DatePickerModule,
		DateTimePickerModule,
		QuillModule,
		ReactiveFormsModule,
		ToastrModule.forRoot({
			timeOut: 5000,
			positionClass: "toast-top-right",
			preventDuplicates: false,
		}),
		BrowserAnimationsModule,
		DropDownsModule,
	],
	providers: [
		{ provide: LOCALE_ID, useValue: "pt" },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpResponseInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpsRequestInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
