import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Unidade } from 'src/app/dto/unidade';

@Component({
  selector: 'app-area-list',
  templateUrl: './area-list.component.html',
  styleUrls: ['./area-list.component.css']
})
export class AreaListComponent  {

  @Output() onRemove = new EventEmitter<Unidade>();
  @Output() onEdit = new EventEmitter<Unidade>();
  @Input() unidades: Array<Unidade>;



  removerUnidade(unidade: Unidade){
    this.onRemove.emit(unidade);

  }

  editarUnidade(unidade: Unidade){
    this.onEdit.emit(unidade);
  }
}
