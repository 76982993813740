import { OnInit, Component } from '@angular/core';
import { Game } from 'src/app/dto/game';
import { GameService } from 'src/app/services/game.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme.service';
import { Erro400 } from 'src/app/dto/erros';
import { Theme } from 'src/app/dto/theme';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-game-form',
    templateUrl: './game-form.component.html',
    styleUrls: ['./game-form.component.css']
  })
export class GameFormComponent implements OnInit{
  game: Game;
  temas: Array<Theme>;

  quillConfig={
    toolbar: {
      container: [
        ['bold', 'underline', 'strike'],        // toggled buttons
        [{ 'size': [] }]
      ],
    }
  }

  public tipoPontuacao: Array<any> = [
    {value:'MAIOR_PONTUACAO', name:'Maior pontuação'}, 
    {value:'MENOR_PONTUACAO', name:'Menor pontuação'}
  ]

  constructor(
    private temaService: ThemeService,
    private gameService: GameService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
    ) { }

  ngOnInit(): void {
    this.resetaTela('rota');
  }

  resetaTela(origem: string){
    this.game = new Game();
    this.temaService.themes(undefined, (erro: Erro400, sucesso: Array<Theme>) => {
      if(sucesso){
        this.temas = sucesso;
      }else if(erro && erro.error){
        this.toastr.error('', erro.error.message);
      }
    })

    if(origem && origem == 'rota'){
      var uuid = this.route.snapshot.paramMap.get("uuid");
      if(uuid){
        this.game = new Game(history.state.data);
      }
    }
  }

  cadastrarGame(bt: string){
    if(this.validatePresentation()){
      this.spinner.show();
      if(this.game.uuid){
        this.gameService.editar(this.game, (erro: Erro400, sucesso: Game) => {
          if(sucesso){
            this.game = new Game(sucesso);
            this.toastr.success('', 'O game foi atualizado com sucesso');
            if(bt){
              if(bt == 'NOVO'){
                this.resetaTela('cadastro');
              }else{
                this.router.navigate(['game'])
              }
            }
          }else if(erro && erro.error){
            this.toastr.error('', erro.error.message);
          }
          this.spinner.hide();
        });
      }else{
        this.gameService.criar(this.game, (erro: Erro400, sucesso: Game) => {
          if(sucesso){
            this.game = new Game(sucesso);
            this.toastr.success('', 'O game foi cadastrado com sucesso');
            if(bt){
              if(bt == 'NOVO'){
                this.resetaTela('cadastro');
              }else{
                this.router.navigate(['game'])
              }
            }
          }else if(erro && erro.error){
            this.toastr.error('', erro.error.message);
          }
          this.spinner.hide();
        });
      }
    }
  }

  validatePresentation(){
    if(!this.game.presentation){
      this.toastr.error('', 'Campo "Apresentação" é de preenchimento obrigatório');
      return false;
    }else{
      return true;
    }
  }

}