export class Theme{
    uuid: string;
    name: string;
    backgroundColor: string;
    foregroundColor: string;

    constructor(theme: Theme = null) {
        if (theme) {
          this.uuid = theme.uuid;
          this.name = theme.name;
          this.backgroundColor = theme.backgroundColor;
          this.foregroundColor = theme.foregroundColor;
        }else{
          this.uuid = "" ;
          this.name = "";
          this.backgroundColor = "";
          this.foregroundColor = "";

        }
    }
}
