import { OnInit, Component } from '@angular/core';
import { EventService } from 'src/app/services/evento.service';
import { Router } from '@angular/router';
import { Event } from 'src/app/dto/event';

@Component({
    selector: 'app-eventos-list',
    templateUrl: './eventos-list.component.html',
    styleUrls: ['./eventos-list.component.css']
  })
export class EventoslistComponent implements OnInit{
  eventos: Array<Event>;
  nome: string;
  cliente: string;
  codigo: string;

  pagina: number = 0;
  quantidadePorPagina: number = 10;
  temMais: boolean = true;

  textoCarregarMais: string = "Carregar mais";
  classeCarregarMais: string = "button is-primary";

  textoBuscar: string = "Buscar";
  classeBuscar: string = "button is-primary";

  constructor(private eventosService: EventService, private router: Router){
  }

  ngOnInit(): void {
    this.pagina = 0;
    this.nome = "";
    this.cliente = "";
    this.codigo = "";

    this.eventos = new Array<Event>();
    this.eventosService.buscar(this.nome, this.cliente, this.codigo, this.pagina, this.quantidadePorPagina, (erro: any, sucesso: Array<Event>) => {
      if(sucesso){
        this.eventos = sucesso;
        if(sucesso.length == this.quantidadePorPagina){
          this.temMais = true;
        }else{
          this.temMais = false;
        }
      }
    });
  }

  novo(){
    this.router.navigate(['/events/new']);
  }

  buscar(){
    this.textoBuscar = "Buscando...";
    this.classeBuscar = "button is-light";
    this.pagina = 0;
    this.eventos = new Array<Event>();
    
    this.eventosService.buscar(this.nome, this.cliente, this.codigo, this.pagina, this.quantidadePorPagina, (erro: any, sucesso: Array<Event>) => {
      this.textoBuscar = "Buscar";
      this.classeBuscar = "button is-primary";
      if(sucesso){
        this.eventos = sucesso;
        if(sucesso.length == this.quantidadePorPagina){
          this.temMais = true;
        }else{
          this.temMais = false;
        }
      }
    });
  }

  carregarMais(){
    this.textoCarregarMais = "Carregando...";
    this.classeCarregarMais = "button is-light";
    this.pagina++;
    this.eventosService.buscar(this.nome, this.cliente, this.codigo, this.pagina, this.quantidadePorPagina, (erro: any, sucesso: Array<Event>) => {
      this.textoCarregarMais = "Carregar mais";
      this.classeCarregarMais = "button is-primary";
      if(sucesso){
        this.eventos = this.eventos.concat(sucesso);
        if(sucesso.length == this.quantidadePorPagina){
          this.temMais = true;
        }else{
          this.temMais = false;
        }
      }
    });
  }

}