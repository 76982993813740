import { Client } from "./client";
import { ComoFunciona } from "./comoFunciona";
import { Estilo } from './estilo';
import { FieldDto } from "./field";
import { Frontend } from "./frontend";
import { KitIcones } from "./kitIcones";
import { MensagemDTO } from './mensagem';
import { Template } from "./template";
import { Theme } from "./theme";

export class EventDto {
	uuid: string;
	name: string;
	code: string;
	dateCreated: string;
	launchDate: string;
	startDate: string;
	endDate: string;
	preEventStartDate: string;
	preEventEndDate: string;
	endContentDate: string;
	feedbackDate: string;
	active: boolean;
	test: boolean;
	client: string;
	logoLeft: string;
	logoRight: string;
	presentationImage: string;
	description: string;
	tipoMatricula: string;
	nomeMatricula: string;
	scheduleType: string;
	captcha: boolean;
	ageType: string;
	title: string;
	subtitle: string;

	launch: Date;
	start: Date;
	end: Date;
	preEventStartDateInterest: Date;
	preEventEndDateInterest: Date;
	endContent: Date;
	feedback: Date;

	trophyEnabled: string;
	trophyDisabled: string;

	domain: string;
	restrictEligibility: boolean;
	requiredInterests: boolean;

	kit: string;

	icons:string;

	legalTerm: boolean;

	messages: Array<MensagemDTO>;

	daysOfContent: Number;

	styles: Array<Estilo>;

	fields: Array<FieldDto>;

	template: string;

	hideClientName: boolean;

	workers: number;

  	visible: boolean;

	comoFunciona: string;

	frontend: string;

	showLikes: boolean;

	topLeftDesktop: string;

	topRightDesktop: string;

	presentationDesktop: string;

	privacyPolicy: string;

	titleVisible: boolean;
  showRankingGame: boolean;
  registrationUnitMandatory: boolean;
  levelUnit: number;

  descricaoTipoInscricao: string;


	constructor(evento: Event = null) {
		if (evento) {
			this.launch = this.parse(evento.launchDate);
			this.start = this.parse(evento.startDate);
			this.end = this.parse(evento.endDate);
			this.preEventStartDateInterest = evento.preEventStartDate
				? this.parse(evento.preEventStartDate)
				: null;
			this.preEventEndDateInterest = evento.preEventEndDate
				? this.parse(evento.preEventEndDate)
				: null;
			this.feedback = evento.feedbackDate
				? this.parse(evento.feedbackDate)
				: null;
			this.endContent = evento.endContentDate
				? this.parse(evento.endContentDate)
				: null;
			this.uuid = evento.uuid;
			this.name = evento.name;
			this.code = evento.code;
			this.dateCreated = evento.dateCreated;
			this.launchDate = evento.launchDate;
			this.startDate = evento.startDate;
			this.endDate = evento.endDate;
			this.preEventStartDate = evento.preEventStartDate;
			this.preEventEndDate = evento.preEventEndDate;
			this.endContentDate = evento.endContentDate;
			this.feedbackDate = evento.feedbackDate;
			this.active = evento.active;
			this.client = evento.client.uuid;
			this.logoLeft = evento.logoLeft;
			this.logoRight = evento.logoRight;
			this.presentationImage = evento.presentationImage;
			this.description = evento.description;
			this.test = evento.test;
			this.trophyEnabled = evento.trophyEnabled;
			this.trophyDisabled = evento.trophyDisabled;
			this.nomeMatricula = evento.nomeMatricula;
			this.tipoMatricula = evento.tipoInscricao;
			this.scheduleType = evento.scheduleType;
			this.captcha = evento.captcha;
			this.domain = evento.dominio;
			this.restrictEligibility = evento.elegibilidade;
			this.requiredInterests = evento.interessesObrigatorios;
			this.ageType = evento.ageType;
			this.kit = evento.kit;
			this.title = evento.title;
			this.subtitle = evento.subtitle;
			this.legalTerm = evento.legalTerm;
			this.messages = evento.messages;
			this.daysOfContent = evento.daysOfContent;
			this.styles = evento.styles;
			this.fields = evento.fields;
			this.icons = evento.icons != null ? evento.icons.uuid : "";
			if(evento.template){
				this.template = evento.template.uuid;
			}
			this.hideClientName = evento.hideClientName;
			this.workers = evento.workers;
      		this.visible = evento.visible;
			if(evento.comoFunciona){
				this.comoFunciona = evento.comoFunciona.uuid;
			}
      		this.frontend = evento.frontend.uuid;
			this.showLikes = evento.showLikes;
      this.showRankingGame = evento.showRankingGame;
			this.topLeftDesktop = evento.topLeftDesktop;
			this.topRightDesktop = evento.topRightDesktop;
			this.presentationDesktop = evento.presentationDesktop;
			this.privacyPolicy = evento.privacyPolicy;
			this.titleVisible = evento.titleVisible;
      this.registrationUnitMandatory = evento.registrationUnitMandatory;
      this.levelUnit = evento.levelUnit;
      this.descricaoTipoInscricao = evento.descricaoTipoInscricao;
		} else {
			this.active = true;
			this.captcha = false;
			this.hideClientName = false;
      		this.visible = true;
			this.showLikes = false;
			this.ageType = 'AGE'
			this.titleVisible = true;
      this.showRankingGame = true;
      this.registrationUnitMandatory = false;
      this.tipoMatricula = 'ELEGIBILIDADE'
      this.restrictEligibility = true;

      this.registrationUnitMandatory = false;
      this.levelUnit = 2;
		}

	}

	isCaptcha() {
		if (this.captcha) {
			return "checked";
		} else {
			return "";
		}
	}

	checked() {
		if (this.active) {
			return "checked";
		} else {
			return "";
		}
	}

	teste() {
		if (this.test) {
			return "checked";
		} else {
			return "";
		}
	}

	elegibilidadeCk() {
		if (this.restrictEligibility) {
			return "checked";
		} else {
			return "";
		}
	}

	interessesCk(){
		if(this.requiredInterests){
			return "checked";
		}else{
			return "";
		}
	}

	legalTermCheck(){
        if(this.legalTerm){
            return "checked";
        }else{
            return "";
        }
    }

	formataData() {
		if (this.launch) {
			this.launchDate = this.format(this.launch);
		}
		if (this.start) {
			this.startDate = this.format(this.start);
		}
		if (this.end) {
			this.endDate = this.format(this.end);
		}
		if (this.feedback) {
			this.feedbackDate = this.format(this.feedback);
		}
		if (this.preEventStartDateInterest) {
			this.preEventStartDate = this.format(this.preEventStartDateInterest);
		}
		if (this.preEventEndDateInterest) {
			this.preEventEndDate = this.format(this.preEventEndDateInterest);
		}
		if(this.endContent){
			this.endContentDate = this.format(this.endContent);
		}
		this.dateCreated = this.format(new Date());
	}

	format(data: Date) {
		var dia = data.getDate().toString(),
			diaF = dia.length == 1 ? "0" + dia : dia,
			mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
			mesF = mes.length == 1 ? "0" + mes : mes,
			anoF = data.getFullYear();
		return (
			diaF +
			"/" +
			mesF +
			"/" +
			anoF +
			" " +
			data.getHours() +
			":" +
			data.getMinutes() +
			":" +
			data.getSeconds()
		);
	}

	parse(data: string) {
		var arrDataExclusao = data.split("/");
		var stringFormatada =
			arrDataExclusao[1] + "/" + arrDataExclusao[0] + "/" + arrDataExclusao[2];
		var dataFormatada1 = new Date(stringFormatada);

		return dataFormatada1;
	}
	isHideClientName() {
		if (this.hideClientName) {
			return "checked";
		} else {
			return "";
		}
	}
	isShowLikes() {
		if (this.showLikes) {
			return "checked";
		} else {
			return "";
		}
	}
	isTitleVisible() {
		if (this.titleVisible) {
			return "checked";
		} else {
			return "";
		}
	}
  isRegistrationUnitMandatory(){
        if(this.registrationUnitMandatory){
            return "checked";
        }else{
            return "";
        }
    }

  isShowRankingGame() {
    if (this.showRankingGame) {
      return "checked";
    } else {
      return "";
    }
  }


}

export class Event {
	uuid: string;
	name: string;
	code: string;
	dateCreated: string;
	launchDate: string;
	startDate: string;
	endDate: string;
	preEventStartDate: string;
	preEventEndDate: string;
	endContentDate: string;
	feedbackDate: string;
	active: boolean;
	client: Client;
	logoLeft: string;
	logoRight: string;
	description: string;
	presentationImage: string;
	tema: Theme;
	test: boolean;
	trophyEnabled: string;
	trophyDisabled: string;
	tipoInscricao: string;
	descricaoTipoInscricao: string;
	scheduleType: string;
	nomeMatricula: string;
	captcha: boolean;
	dominio: string;
	elegibilidade: boolean;
	interessesObrigatorios: boolean;
	ageType: string;
	kit: string;
	title: string;
	subtitle: string;
	legalTerm: boolean;
	messages: Array<MensagemDTO>;
	daysOfContent: Number;
	styles: Array<Estilo>;
	fields: Array<FieldDto>;
	icons: KitIcones;
	template: Template;
	hideClientName: boolean;
	workers: number;
  	visible: boolean;
	comoFunciona: ComoFunciona;
	frontend: Frontend;
	showLikes: boolean;
  showRankingGame: boolean;
	topLeftDesktop: string;
	topRightDesktop: string;
	presentationDesktop: string;
	privacyPolicy: string;
	titleVisible: boolean;
  registrationUnitMandatory: boolean;
  levelUnit: number;


}


export class EventCopiaDto {
	code: string;
}
