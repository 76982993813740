import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Client } from 'src/app/dto/client';
import { Erro400 } from 'src/app/dto/erros';
import { Unidade } from 'src/app/dto/unidade';
import { ClientsService } from 'src/app/services/clients.service';
import { UnidadeService } from 'src/app/services/unidade.service';


@Component({
    selector: 'app-clientes-form',
    templateUrl: './clientes-form.component.html',
    styleUrls: ['./clientes-form.component.css']
  })
export class ClientesformComponent implements OnInit{
  cliente: Client;

  classeAbaCli: string;
  classeAbaUni: string;

  unidades: Array<Unidade>;
  unidade: Unidade;
  isSaved = false;
  isAreaEdit = false;
  name: string = null;
  parentId: string = null;
  full: boolean = true;

  constructor(
    private clienteService: ClientsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private unidadeService: UnidadeService
  ){}

  ngOnInit(): void {
    this.mudaAbaPrincipal("cli");
    this.cliente = new Client();
    this.unidades = [];
    this.unidade = new Unidade();

    var uuid = this.route.snapshot.paramMap.get("uuid");
    if(uuid){
      this.isSaved = true;
      this.clienteService.get(uuid, (erro: Erro400, sucesso: Client) => {
        if(sucesso){
          this.cliente = sucesso;
        }else if(erro && erro.error){
          this.toastr.error('', erro.error.message);
        }
      })
      this.carregarUnidades(uuid);
    }
  }

  carregarUnidades(uuid: string){
    this.unidadeService.buscar(uuid, this.name, this.parentId, this.full, (erro: Erro400, sucesso: Array<Unidade>) => {
      if(sucesso){
        this.unidades = sucesso;
      }
      else if(erro && erro.error){
        this.toastr.error('', erro.error.message);
      }
    });
  }

  cadastrarCliente(){
    this.spinner.show();
    if(this.cliente.uuid){
      this.clienteService.editar(this.cliente, (erro: Erro400, sucesso: Client) => {
        if(sucesso){
          this.toastr.success('', 'Cliente atualizado com sucesso');
          this.router.navigate(['/clients']);

        }else if(erro && erro.error){
          this.toastr.error('', erro.error.message);
        }
        this.spinner.hide();

      });
    }else{
      this.clienteService.criar(this.cliente, (erro: Erro400, sucesso: Client) => {
        if(sucesso){
          this.toastr.success('', 'Cliente cadastrado com sucesso');
          this.router.navigate(['/clients']);

        }else if(erro && erro.error){
          this.toastr.error('', erro.error.message);
        }
        this.spinner.hide();
      });
    }
  }

  cadastrarUnidade(unidade: Unidade, somenteSalvar: boolean){
    this.unidade = unidade;
    this.spinner.show();
    if(this.unidade.uuid){
      this.unidadeService.criar(this.cliente.uuid, this.unidade, (erro: Erro400, sucesso: Unidade) => {
        if(sucesso){

          this.carregarUnidades(this.cliente.uuid);
          this.toastr.success('', 'Unidade atualizada com sucesso');
          this.unidade = new Unidade(sucesso);
          if(!somenteSalvar){
            this.criarUnidade();
            this.isAreaEdit = true;
          }
        }else if(erro && erro.error){
          this.toastr.error('', erro.error.message);
          this.isAreaEdit = true;
          this.unidade =  new Unidade(unidade);

        }
        this.spinner.hide();
      });
    }else{
      this.unidadeService.criar(this.cliente.uuid, this.unidade, (erro: Erro400, sucesso: Unidade) => {
        if(sucesso){

          this.carregarUnidades(this.cliente.uuid);
          this.toastr.success('', 'Unidade cadastrada com sucesso');
          this.unidade = new Unidade(sucesso);
          if(!somenteSalvar){
            this.criarUnidade();
            this.isAreaEdit = true;
          }

        }else if(erro && erro.error){
          this.toastr.error('', erro.error.message);
          this.unidade =  new Unidade(unidade);
          this.isAreaEdit = true;
        }
        this.spinner.hide();
      });
    }
  }

  editarUnidade(unidade: Unidade){
    this.unidade = unidade;
    this.isAreaEdit = true;
  }

  criarUnidade(){
    this.unidade = new Unidade();
    this.unidade.accounted = true;
    this.unidade.active = true;
    this.isAreaEdit = true;
  }

  removerUnidade(unidade: Unidade, isSubunidade: boolean= false){
    this.spinner.show();
    this.unidadeService.remover(this.cliente.uuid, unidade, (erro: Erro400, sucesso: any) => {
      if(sucesso){
        this.carregarUnidades(this.cliente.uuid);
        const mensagem = isSubunidade ? 'Subunidade removida com sucesso' : 'Unidade removida com sucesso';
        this.toastr.success('', mensagem);
      }else if(erro && erro.error){
        this.toastr.error('', erro.error.message);
      }
      this.spinner.hide();
    })
  }

  cnpjMask() {
    return [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
  }

  telefoneMask(){
    return ['(', /[0-9]/, /[0-9]/, ')',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/];
  }

  mudaAbaPrincipal(aba: string){
    if(aba == 'cli'){
      this.classeAbaCli = "is-active";
      this.classeAbaUni = "";
    }else if(aba == 'uni'){
      this.classeAbaCli = "";
      this.classeAbaUni = "is-active";
      this.isAreaEdit = false;
    }
  }

  cadastrarUnidadeSave(unidade: Unidade){
    this.cadastrarUnidade(unidade, true);
    this.isAreaEdit = true;
    this.unidade =  new Unidade(unidade);
  }

  cadastrarUnidadeSaveAndList(unidade: Unidade){
    this.isAreaEdit = false;
    this.cadastrarUnidade(unidade, true);

  }

  cadastrarUnidadeSaveAndNew(unidade: Unidade){
    this.cadastrarUnidade(unidade,false);

  }
}
