import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Mural } from '../dto/mural';
import { FiltroBiblioteca } from '../dto/filtroBiblioteca';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';
import { Erro400 } from '../dto/erros';
@Injectable({
  providedIn: 'root'
})
export class MuralService {


  apiHost: string = environment.apiHost

  constructor( private http : HttpClient , private loginService: LoginService) { }

  criarAndeditar(mural: Mural) {
    return this.http.post<Mural>(this.apiHost + '/api/v1/mural/', mural,{headers: this.loginService.authorizationHeader()});
  }

  buscar(filtro: FiltroBiblioteca = new FiltroBiblioteca()) {
    let httpParams = new HttpParams()
    if (filtro.name) {
      httpParams = httpParams.set('name', filtro.name)
    }
    if (filtro.theme) {
      httpParams = httpParams.set('theme', filtro.theme)
    }
    if (filtro.code) {
      httpParams = httpParams.set('code', filtro.code)
    }
    if (filtro.pageSize) {
      httpParams = httpParams.set('page_size', filtro.pageSize.toString())
    }

    return this.http.get<Mural[]>(this.apiHost + '/api/v1/mural', { params: httpParams ,headers: this.loginService.authorizationHeader()});
  }

  public adicionarImagem(
    mural: Mural,
    tipo: string,
    file: File,
    callback: Function
  ) {
    var formdata: FormData = new FormData();
    formdata.append("file", file);

    let params = new HttpParams();
    params = params.set("type", tipo);

    this.http
      .post(this.apiHost + '/api/v1/dynamic/' + mural.uuid + '/image', formdata, {headers: this.loginService.authorizationHeader()})
      .subscribe(
        (sucesso: Mural) => {
          callback(null, sucesso);
        },
        (falha: Erro400) => {
          callback(falha, null);
        }
      );
  }
  clonar(mural: Mural) {
    return this.http.put(this.apiHost + '/api/v1/mural/'+mural.uuid+'/clone',null,{headers: this.loginService.authorizationHeader()});

  }

}
