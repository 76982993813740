export class FieldDto{
    uuid: string;
    field: string;
    label: string;
    order: number;
    required: boolean;

    constructor(field: FieldDto = null){
        if(field){
            this.uuid = field.uuid
            this.field = field.field
            this.label = field.label
            this.order = field.order
            this.required = field.required
        }
    }

    requiredChecked() {
		if (this.required) {
			return "checked";
		} else {
			return "";
		}
	}
}

export class FieldRequestDto{
    field: string;
    label: string;
    order: number;
    required: boolean;
}