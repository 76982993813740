import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Bloco } from 'src/app/dto/bloco-dinamica/bloco';

@Component({
  selector: 'app-data-table-bloco',
  templateUrl: './data-table-bloco.component.html',
  styleUrls: ['./data-table-bloco.component.css']
})
export class DataTableBlocoComponent implements OnChanges {
  @Input()
  blocos: Bloco[];

  @Output()
  onClick: EventEmitter<Bloco> = new EventEmitter();

  ngOnChanges(__changes: SimpleChanges) {
    window.scrollTo(0, 0);
  }


  onClickRow(bloco: Bloco) {
    this.onClick.emit(bloco);
  }

}
