import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoginService } from '../services/login.service';

@Injectable()

export class HttpsRequestInterceptor implements HttpInterceptor {
    constructor(
        private autorizacaoService: LoginService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.autorizacaoService.autorizacao() && this.autorizacaoService.autorizacao().token) {
            const requestComCabecalho = req.clone({
                headers: req.headers
                    .set('Authorization', this.autorizacaoService.autorizacao().token)
                });

            return next.handle(requestComCabecalho);
        }
        return next.handle(req);
    }
}


@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpsRequestInterceptor,
            multi: true,
        },
    ],
})

export class InterceptorModule { }
