import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Erro400 } from 'src/app/dto/erros';
import { CardHabit, NewHabit, NewHabitsCopiar } from 'src/app/dto/newHabit';
import { Theme } from 'src/app/dto/theme';
import { NewHabitService } from 'src/app/services/new-habit.service';
import { ThemeService } from 'src/app/services/theme.service';
import { DeletaImagemService } from '../../services/deleta-imagem.service';

@Component({
    selector: 'app-new-habit-form',
    templateUrl: './new-habit-form.component.html',
    styleUrls: ['./new-habit-form.component.css']
  })
export class NewHabitForm implements OnInit{

  habit: NewHabit;
  temas: Array<Theme>;
  public imagem: File;

  copia: NewHabitsCopiar;

  classeAbaNH: string;
  classeAbaCards: string;

  classeAbaLD: string;
  classeAbaCD: string;

  card: CardHabit;

  imagemCard: File;


  quillConfig={
    toolbar: {
      container: [
        ['bold', 'underline', 'strike'],        // toggled buttons
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'size': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'align': [] }]
      ],
    }
  }

  constructor(
    private temaService: ThemeService,
    private newHabitsService: NewHabitService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private deleteImagemService: DeletaImagemService,
  ){

  }

  ngOnInit(): void {
    this.copia = new NewHabitsCopiar();
    this.resetaTela('rota');
  }

  resetaTela(origem: string){
    this.mudaAbaPrincipal('nh');
    this.mudaAbaCards('lista');
    this.habit = new NewHabit();
    this.temaService.themes(undefined, (erro: Erro400, sucesso: Array<Theme>) => {
      if(sucesso){
        this.temas = sucesso;
      }else if(erro && erro.error){
        this.toastr.error('', erro.error.message);
      }
    })

    if(origem && origem == 'rota'){
      var uuid = this.route.snapshot.paramMap.get("uuid");
      if(uuid){
        this.habit = new NewHabit(history.state.habit);
      }
    }
  }

  cadastrarNovosHabitos(bt: string){
    if(this.validatePresentation()){
      this.spinner.show();
      if(this.habit.uuid){
        this.newHabitsService.editar(this.habit, (erro: Erro400, sucesso: NewHabit) => {
          if(sucesso){
            this.habit = new NewHabit(sucesso);
            if (this.card) {
              this.habit.cards.forEach(c => {
                if (c.order === this.card.order) {
                  this.card = c;
                }
              });
            }
            this.toastr.success('', 'O novos hábitos foi atualizado com sucesso');
            if(bt){
              if(bt == 'NOVO'){
                this.resetaTela('cadastro');
              }else{
                this.router.navigate(['newhabits'])
              }
            }
          }else if(erro && erro.error){
            this.toastr.error('', erro.error.message);
          }
          this.spinner.hide();
        });
      }else{
        this.newHabitsService.criar(this.habit, (erro: Erro400, sucesso: NewHabit) => {
          if(sucesso){
            this.habit = new NewHabit(sucesso);
            this.toastr.success('', 'O novos hábitos foi cadastrado com sucesso');
            if(bt){
              if(bt == 'NOVO'){
                this.resetaTela('cadastro');
              }else{
                this.router.navigate(['newhabits'])
              }
            }
          }else if(erro && erro.error){
            this.toastr.error('', erro.error.message);
          }
          this.spinner.hide();
        });
      }
    }
  }

  validatePresentation(){
    if(!this.habit.presentation){
      this.toastr.error('', 'Campo "Apresentação" é de preenchimento obrigatório');
      return false;
    }else{
      return true;
    }
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.imagem = event.target.files[0];
    }
  }

  onSelectFileCard(event) {
    if (event.target.files && event.target.files[0]) {
      this.imagemCard = event.target.files[0];
    }
  }

  enviarImagem(){
    this.spinner.show();
    let arquivo: File = null;
    arquivo = this.imagem;
    this.newHabitsService.enviarImagem(this.habit.uuid, arquivo, (erro: Erro400, sucesso: NewHabit) => {
      if(sucesso){
        this.habit = new NewHabit(sucesso);
        this.toastr.success('', 'Arquivo enviado com sucesso');
      }else if(erro && erro.error){
        this.toastr.error('', erro.error.message);
      }else{
        this.toastr.error('', 'Aconteceu um erro ao tentar enviar a imagem, por favor, contate o responsável pelo sistema');
      }
      this.spinner.hide();
    });
  }

  enviarImagemCard(){
    this.spinner.show();
    let arquivo: File = null;
    arquivo = this.imagemCard;
    this.newHabitsService.enviarImagemCard(this.habit.uuid, this.card.uuid, arquivo, (erro: Erro400, sucesso: NewHabit) => {
      if(sucesso){
        this.habit = new NewHabit(sucesso);
        this.habit.cards.forEach(c => {
          if(c.uuid === this.card.uuid){
            this.card = c;
          }
        });
        this.toastr.success('', 'Arquivo enviado com sucesso');
      }else if(erro && erro.error){
        this.toastr.error('', erro.error.message);
      }else{
        this.toastr.error('', 'Aconteceu um erro ao tentar enviar a imagem, por favor, contate o responsável pelo sistema');
      }
      this.spinner.hide();
    });
  }

  mudaAbaPrincipal(aba: string){
    if(aba == 'nh'){
      this.classeAbaNH = "is-active";
      this.classeAbaCards = "";
    }else if(aba == 'ch'){
      this.classeAbaNH = "";
      this.classeAbaCards = "is-active";
    }
  }

  mudaAbaCards(aba: string){
    if(aba == 'lista'){
      this.classeAbaLD = "is-active";
      this.classeAbaCD = "";
    }else if(aba == 'editor'){
      this.classeAbaLD = "";
      this.classeAbaCD = "is-active";
    }
  }

  formCard(card: CardHabit){
    this.imagemCard = null;
    if(card){
      this.card = card;
    }else{
      this.card = new CardHabit();
    }
    this.mudaAbaCards('editor')
  }

  cadastrarCardNovosHabitos(){
    if(this.validateCardDescription()){
      if(this.card.uuid){
        for(let i=0; i<this.habit.cards.length; i++){
          if(this.habit.cards[i].uuid === this.card.uuid){
            this.habit.cards[i] = this.card;
          }
        }
      }else{
        let posicao = -1;
        for(let i=0; i<this.habit.cards.length; i++){
          if (!this.habit.cards[i].uuid && this.habit.cards[i].order === this.card.order) {
            posicao = i;
          }
        };
        if (posicao > -1) {
          this.habit.cards.splice(posicao, 1);
        }
        this.habit.cards.push(this.card);
      }
      this.cadastrarNovosHabitos(undefined);
    }
  }

  validateCardDescription(){
    if(!this.card.description){
      this.toastr.error('', 'Campo "Descrição" é de preenchimento obrigatório');
      return false;
    }else{
      return true;
    }
  }

  clonarNovosHabitos(){
    if(this.habit.uuid){
      this.copia.description = this.habit.description,
      this.copia.presentation = this.habit.presentation,
      this.copia.theme = this.habit.theme.uuid;

      this.spinner.show();
      this.newHabitsService.clonarNewHabit(this.habit, this.copia, (erro: Erro400, sucesso: NewHabit) => {
        if(sucesso){
          this.habit = new NewHabit(sucesso);
          this.toastr.success('', 'Novos Hábitos clonado com sucesso')
          this.router.navigate(['newhabits/edit' + sucesso.uuid ])
        } else if(erro && erro.error){
          this.toastr.error('', erro.error.message);
        }
      })
      this.spinner.hide();

    }
  }

  deleteCard(){
    if(!confirm("Tem certeza que deseja deletar este card?")){
      return;
    }
    this.spinner.show();
    this.newHabitsService.deleteCard(this.card.uuid, this.habit.uuid, (err, sucess)=>{
      this.spinner.hide();
      if(err){
        this.toastr.error("Não foi possível prosseguir com a solicitação. Por favor, tente novamente mais tarde.");
      }else{
        this.toastr.success("Card foi removido com sucesso.");
        this.habit = new NewHabit(sucess);
        this.mudaAbaCards('lista');
      }
    })
  }

  deleteImagem(uuid: string, tipo: string){
    if(!confirm("Tem certeza que deseja excluir a imagem")){
      return;
    }
    this.spinner.show();
    this.deleteImagemService.deletaImagem(uuid,tipo).subscribe(
      (_) => {
        this.spinner.hide();
        switch(tipo){
          case 'NEW_HABITS':
            this.habit.picture = null;
            this.imagem = null;
            break;
          case 'NEW_HABITS_CARD':
            this.card.picture = null;
            this.imagemCard = null;
        }
        this.toastr.success("Imagem removida com sucesso!");
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
      }
    )
  }



}
