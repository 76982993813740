import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DinamicaService } from 'src/app/services/dinamica.service';

@Component({
  selector: 'app-imagem-apresentacao-dinamica',
  templateUrl: './imagem-apresentacao-dinamica.component.html',
  styleUrls: ['./imagem-apresentacao-dinamica.component.css']
})
export class ImagemApresentacaoDinamicaComponent implements OnInit {
  arquivo: File;
  
  constructor( 
    private dinamicaService: DinamicaService, 
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private sanitizer:DomSanitizer) {}

  @Input() dinamicaUuid: string;

  imageUrl: SafeUrl = undefined;

  sendImagem(){
    if(!this.arquivo){
      this.toastr.error('Selecione um arquivo antes de enviar a imagem.');
      return;
    }
    this.spinner.show();
    this.dinamicaService.enviarImagem(this.dinamicaUuid, this.arquivo, (err, sucess)=>{
      this.spinner.hide();
      if(err){
        this.toastr.error(err.err.message);
      }else{
        this.toastr.success('Imagem enviada com sucesso!');
        this.loadImage();
        this.arquivo = null;
      }
    })
  }

  delete(){
    if(!confirm("Tem certeza que deseja excluir a imagem?")){
      return;
    }
    this.spinner.show();
    this.dinamicaService.deleteImagem(this.dinamicaUuid, (err, sucess)=>{
      this.spinner.hide();
      if(err){
        this.toastr.error("Não foi possível deletar a imagem. Tente novamente mais tarde.");
      }else{
        this.toastr.success("Imagem deletada com sucesso!");
        this.imageUrl = undefined;
      }
    })
  }

  onChange(e){
    if (e.target.files && e.target.files[0]) {
      this.arquivo = e.target.files[0];
    }
  }

  loadImage(){
    if(!this.dinamicaUuid){
      return;
    }
    this.spinner.show();
    this.dinamicaService.getImagem(this.dinamicaUuid, (err, sucess)=>{
      this.spinner.hide();
      if(err){
        this.imageUrl = undefined;
      }else{
        const url = URL.createObjectURL(sucess);
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(url);
      }
    })
  }

  ngOnInit() {
    this.loadImage();
  }

}
