import { HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ElegibilidadeDTO } from 'src/app/dto/elegibilidade';
import ArchiveStatus from 'src/app/dto/elegiblidade-async/ArchiveStatus';
import EligibilityErrorPage from 'src/app/dto/elegiblidade-async/EligibilityErrorPage';
import EligibilityStatusPage from 'src/app/dto/elegiblidade-async/EligibilityStatusPage';
import { Erro400, ErroImportacao } from 'src/app/dto/erros';
import { ElegibilidadeService } from 'src/app/services/elegibilidade.service';

@Component({
  selector: 'app-elegibilidade',
  templateUrl: './elegibilidade.component.html',
  styleUrls: ['./elegibilidade.component.css']
})
export class ElegibilidadeComponent implements OnInit {
  els: Array<ElegibilidadeDTO>;
  erros: Array<ErroImportacao>;
  eligibilityStatusCurrentPage: number = 0;
  eligibilityErrorCurrentPage: number = 0;
  eligibilityStatusPage = new EligibilityStatusPage();
  eligibilityErroPage = new EligibilityErrorPage();
  lockLoadMoreArquivo = false;
  lockUpdateArquivo = false;
  lockLoadMoreErro = false;
  elegibility = new ArchiveStatus();
  @Input() uuidEvento: string;

  arquivo: File;
  tipo: string;

  constructor(
    private elegibilidadeService: ElegibilidadeService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.erros = null;
    this.arquivo = null;
    this.tipo = 'ADD';
    this.els = new Array<ElegibilidadeDTO>();
    this.getEligibilityStatus();

  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.arquivo = event.target.files[0];
    }
  }

  onSelectArchiveEligibility(elegibility: ArchiveStatus) {
    this.lockLoadMoreErro = true;
    this.eligibilityErrorCurrentPage = 0;
    this.elegibility = elegibility;
    this.eligibilityErroPage = new EligibilityErrorPage();
    if (elegibility.status == 'Erro') {
      this.getEligibilityErro(elegibility.uuid);
    }
  }

  getEligibilityErro(uuid: string) {
    this.elegibilidadeService.getEligibilityErro(this.uuidEvento, uuid, this.eligibilityErrorCurrentPage).subscribe(
      (sucess: EligibilityErrorPage) => {
        this.eligibilityErroPage.content = this.eligibilityErroPage.content.concat(sucess.content);
        this.eligibilityErrorCurrentPage = sucess.page;
        this.eligibilityErroPage.page = sucess.page;
        this.eligibilityErroPage.pageSize = sucess.pageSize;
        this.eligibilityErroPage.totalElements = sucess.totalElements;
        this.eligibilityErroPage.totalPages = sucess.totalPages;
        this.lockLoadMoreErro = false;
      },
    );

  }

  importar() {
    this.spinner.show();
    this.elegibilidadeService.importar(this.uuidEvento, this.tipo, this.arquivo).subscribe(() => {
      this.arquivo = null;
      this.erros = null;
      this.spinner.hide();
      this.toastr.success('', 'Arquivo recebido. Acompanhe o status do processamento na tabela abaixo.')
    }, (erro) => {
      this.arquivo = null;
      if (erro.error && erro.error.details) {
        this.erros = erro.error.details;
      }
      if (erro.error && erro.error.status && erro.error.status == 500) {
        this.toastr.error('', "Aconteceu um erro interno, por favor, contacte o administrador");
      } else if (erro.error && erro.error.message) {
        this.toastr.error('', erro.error.message);
      } else {
        this.toastr.error('', "Aconteceu um erro interno, por favor, contacte o administrador");
      }
      this.spinner.hide();
    }
    )
  }

  getEligibilityStatus() {
    this.elegibilidadeService.getEligibilityStatus(this.uuidEvento, this.eligibilityStatusCurrentPage).subscribe(
      (sucess: EligibilityStatusPage) => {
        this.eligibilityStatusPage.content = this.eligibilityStatusPage.content.concat(sucess.content);
        this.eligibilityStatusPage.page = sucess.page;
        this.eligibilityStatusPage.pageSize = sucess.pageSize;
        this.eligibilityStatusPage.totalElements = sucess.totalElements;
        this.eligibilityStatusPage.totalPages = sucess.totalPages;
        this.lockLoadMoreArquivo = false;
        this.lockUpdateArquivo = false;
      },
      (erro: Erro400) => {
        this.toastr.error('', erro.error.message);
      }
    );
  }

  onLoadMore() {
    this.lockLoadMoreArquivo = true;
    this.eligibilityStatusCurrentPage++;
    this.getEligibilityStatus();
  }

  onLoadMoreErro(){
    this.lockLoadMoreErro = true;
    this.eligibilityErrorCurrentPage++;
    this.getEligibilityErro(this.elegibility.uuid);    
  }

  onUpdate() {
    this.lockUpdateArquivo = true;
    this.eligibilityStatusCurrentPage = 0;
    this.eligibilityStatusPage = new EligibilityStatusPage();
    this.getEligibilityStatus();
  }
  download() {
    this.elegibilidadeService.download(this.uuidEvento, (erro: Erro400, sucesso: any) => {
      if (sucesso) {
        const temp: HttpHeaders = sucesso.headers;
        this.downLoadFile(sucesso.body, 'application/ms-excel');
      } else if (erro) {
        this.toastr.error('', erro.error.message);
      }
    })
  }

  downLoadFile(data: any, type: string) {
    /*let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        this.toastr.error('', 'Please disable your Pop-up blocker and try again.');
    }*/
    var blob = new Blob([data], { type: type.toString() });
    var url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = "elegibilidade-" + this.uuidEvento + ".xlsx";
    anchor.href = url;
    anchor.click();
  }

}