import { OnInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme.service';
import { Erro400 } from 'src/app/dto/erros';
import { Theme } from 'src/app/dto/theme';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Mural } from 'src/app/dto/mural';
import { MuralService } from 'src/app/services/mural.service';
import { DeletaImagemService } from 'src/app/services/deleta-imagem.service';

@Component({
    selector: 'app-mural-form',
    templateUrl: './mural-form.component.html',
    styleUrls: ['./mural-form.component.css']
  })
export class MuralFormComponent implements OnInit{
  mural: Mural;
  temas: Array<Theme>;
  muralTema: string;

  public image: File;

  quillConfig={
    toolbar: {
      container: [
        ['bold', 'underline', 'strike'],        // toggled buttons
        [{ 'size': [] }]
      ],
    }
  }

  constructor(
    private temaService: ThemeService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private muralService: MuralService,
    private deleteImagemService: DeletaImagemService
    ) { }

  ngOnInit(): void {
    this.image = null;
    this.resetaTela('rota');
  }

  resetaTela(origem: string){
    this.mural = new Mural();
    this.temaService.themes(undefined, (erro: Erro400, sucesso: Array<Theme>) => {
      if(sucesso){
        this.temas = sucesso;
      }else if(erro && erro.error){
        this.toastr.error('', erro.error.message);
      }
    })

    if(origem && origem == 'rota'){
      var uuid = this.route.snapshot.paramMap.get("uuid");
      if(uuid){
        this.mural = new Mural(history.state.data);
      }
    }
  }

  cadastrarMural(bt: string){
    let isEdicao = this.mural.uuid ? true : false;

    if(this.validatePresentation() && this.mural.description ){
      this.spinner.show();
      this.muralService.criarAndeditar(this.mural).subscribe(
        (sucesso: Mural) => {
          if(sucesso){
            this.mural = new Mural(sucesso);
            if (isEdicao) this.toastr.success('', 'O mural foi atualizado com sucesso');
            else this.toastr.success('', 'O mural foi criado com sucesso');
            if(bt){
              if(bt == 'NOVO'){
                this.resetaTela('cadastro');
              }else{
                this.router.navigate(['mural'])
              }
            }
          }
          this.spinner.hide();
        },
        (erro: Erro400) => {
          if(erro && erro.error){
            this.toastr.error('', erro.error.message);
          }
          this.spinner.hide();
        }
      )

    }
  }

  validatePresentation(){
    if(!this.mural.presentation){
      this.toastr.error('', 'Campo "Apresentação" é de preenchimento obrigatório');
      return false;
    }else{
      return true;
    }
  }

  uploadImagem(tipo: string) {
		let arquivo: File = null;
		arquivo = this.image;

		this.spinner.show();
		this.muralService.adicionarImagem(
			this.mural,
			tipo,
			arquivo,
			(erro: Erro400, sucesso: Mural) => {
				this.spinner.show();
				if (tipo === "image") {
					if (sucesso) {
            let picture
						picture = sucesso.picture;
            this.mural.picture = picture;
						this.toastr.success("", "Imagem enviada com sucesso");

					}
					if (erro) {
						if (erro.error && erro.error.message) {
							this.toastr.error("", erro.error.message);
						} else {
							this.toastr.error(
								"",
								"Aconteceu um erro ao tentar substituir a imagem, entre em contato com o suporte ou tente novamente mais tarde"
							);
						}
					}
					this.spinner.hide();
				}
			}
		);
	}

	onSelectFile(event, tipo: string) {
		if (event.target.files && event.target.files[0]) {
			if (tipo === "image") {
				this.image = event.target.files[0];
			}
		}
	}
  clonar(){
    this.muralService.clonar(this.mural).subscribe(
      (sucesso: Mural) => {
        if(sucesso){
          this.mural = new Mural(sucesso);
          this.toastr.success('', 'O mural foi clonado com sucesso');
          this.router.navigate(['mural/edit/' + this.mural.uuid]);
        }
      },
      (erro: Erro400) => {
        if(erro && erro.error){
          this.toastr.error('', erro.error.message);
        }
      }
    )

  }
  deleteImagem(tipo: string,uuid: string){
    console.log('deleteImagem');
    if(!confirm("Tem certeza que deseja excluir a imagem")){
      return;
    }
    this.spinner.show();
    this.deleteImagemService.deletaImagem(uuid,tipo).subscribe(
      (_) => {
        this.spinner.hide();
        this.mural.picture = null;
        this.image = null;
        this.toastr.success("Imagem removida com sucesso!");
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
      }
    )
  }
}
