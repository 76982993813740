import { EventDto } from "./event"

export class MensagemDTO{
    uuid: string
    code: string
    language: string
    message: string
    event: EventDto

    toJSON(){
        let json = {
            'code': this.code,
            'language': this.language,
            'message': this.message,
            'event': this.event.uuid
         };
         return json;
    }
}