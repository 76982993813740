import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Erro400 } from '../dto/erros';
import { Video } from '../dto/video';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
  })
export class VideoService{
    apiHost: string = environment.apiHost

    constructor(private http: HttpClient, private loginService: LoginService) { }

    buscar(name: string, theme: string, code: string, callback: Function) {

      let httpParams = new HttpParams()
      if (name) {
        httpParams = httpParams.set('name', name)
      }
      if (theme) {
        httpParams = httpParams.set('theme', theme)
      }
      if (code) {
        httpParams = httpParams.set('code', code)
      }
  
      this.http.get(this.apiHost + '/api/v1/video', { params: httpParams }).subscribe(
        (coachings: Video[]) => {
          callback(null, coachings);
        },
        (erro: any) => {
          callback(erro, null);
        }
  
      );
    }

    public criar(video: Video, callback: Function){
      this.http.post(this.apiHost + '/api/v1/video', video.toJSON(), {headers: this.loginService.authorizationHeader()}).subscribe(
          (sucesso: Video) => {
              callback(null, sucesso);
          },
          (falha: Erro400) => {
              callback(falha, null);
          }
      );
    }

    public editar(video: Video, callback: Function){
      this.http.put(this.apiHost + '/api/v1/video/' + video.uuid, video, {headers: this.loginService.authorizationHeader()}).subscribe(
          (sucesso: Video) => {
              callback(null, sucesso);
          },
          (falha: Erro400) => {
              callback(falha, null);
          }
      );
    }

}