export class BlocoDinamica {
    uuid: string;
    uuidRegistro: string;
    tipo: string;
    theme:string;
    nome: string;

    constructor(blocoDinamica: BlocoDinamica = null) {
      if (blocoDinamica) {
        this.uuid = blocoDinamica.uuid;
        this.uuidRegistro = blocoDinamica.uuidRegistro;
        this.tipo = blocoDinamica.tipo;
        this.theme = blocoDinamica.theme;
        this.nome = blocoDinamica.nome;
      }
    }
}
