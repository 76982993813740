import { OnInit, Component } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { User } from 'src/app/dto/user';
import { Router } from '@angular/router';

@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.css']
  })
export class UserslistComponent implements OnInit{

  users: Array<User>;

  nome: string;
  sobrenome: string;
  genero: string;
  email: string;
  matricula: string;
  codigo: string;

  pagina: number = 0;
  quantidadePorPagina: number = 10;
  temMais: boolean = true;

  textoCarregarMais: string = "Carregar mais";
  classeCarregarMais: string = "button is-primary";

  textoBuscar: string = "Buscar";
  classeBuscar: string = "button is-primary";
  
  constructor(private usersService: UsersService, private router: Router){
  }

  ngOnInit(): void {
    this.pagina = 0;
    this.nome = "";
    this.sobrenome = "";
    this.genero = "";
    this.email = "";
    this.matricula = '';
    this.codigo = "";

    this.users = new Array<User>();
    this.usersService.buscar(this.nome, this.sobrenome, this.genero, this.email, this.pagina,
      this.quantidadePorPagina, this.matricula, this.codigo, (erro: any, sucesso: Array<User>) => {
      if(sucesso){
        this.users = sucesso;
        if(sucesso.length == this.quantidadePorPagina){
          this.temMais = true;
        }else{
          this.temMais = false;
        }
      }
    });
  }

  buscar(){
    this.textoBuscar = "Buscando...";
    this.classeBuscar = "button is-light";
    this.pagina = 0;
    this.users = new Array<User>();

    this.usersService.buscar(this.nome, this.sobrenome, this.genero, this.email, this.pagina, this.quantidadePorPagina,
      this.matricula, this.codigo, (erro: any, sucesso: Array<User>) => {
      this.textoBuscar = "Buscar";
      this.classeBuscar = "button is-primary";
      if(sucesso){
        this.users = sucesso;
        if(sucesso.length == this.quantidadePorPagina){
          this.temMais = true;
        }else{
          this.temMais = false;
        }
      }
    });
  }

  carregarMais(){
    this.textoCarregarMais = "Carregando...";
    this.classeCarregarMais = "button is-light";
    this.pagina++;
    this.usersService.buscar(this.nome, this.sobrenome, this.genero, this.email, this.pagina, this.quantidadePorPagina,
      this.matricula, this.codigo, (erro: any, sucesso: Array<User>) => {
      this.textoCarregarMais = "Carregar mais";
      this.classeCarregarMais = "button is-primary";
      if(sucesso){
        this.users = this.users.concat(sucesso);
        if(sucesso.length == this.quantidadePorPagina){
          this.temMais = true;
        }else{
          this.temMais = false;
        }
      }
    });
  }

  novo(){
    this.router.navigate(['/users/new'])
  }

  editar(guid: string){
    
  }

}