import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Agenda, AgendaDia, AgendaDiaDto, AgendaDiaEditDto, AgendaDiaEditRequest, AgendaDiaView, AgendaEditDto, AgendaRequest } from 'src/app/dto/agenda';
import { Erro400 } from 'src/app/dto/erros';
import { EventDto } from 'src/app/dto/event';
import { Interesse } from 'src/app/dto/interesse';
import { Theme } from 'src/app/dto/theme';
import { AgendaService } from 'src/app/services/agenda.service';
import { DataService } from 'src/app/services/data.service';
import { InteresseService } from 'src/app/services/interesse.service';


@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {

  agenda: Agenda;
  agendaDia: AgendaDiaDto;
  request: AgendaRequest;
  diaSelecionado: AgendaDia;
  diaSelect: string;
  listDias: AgendaDiaView[];
  classeModalEditarAgenda: string;
  agendaEdit: AgendaDiaEditDto[];
  interests: Interesse[] = [];
  data: Array<Interesse>;
  diasInvalidos = "";
  @Input() evento: EventDto;
  @Input() temas: Theme[];

  constructor(
    private agendaService: AgendaService,
    private dataService: DataService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private interesseService: InteresseService
  ) { }

  ngOnInit() {
    this.diaSelecionado = null;
    this.diaSelect = null;
    this.fecharModalEditarAgenda();
    this.agendaService.buscar(this.evento.uuid, (erro: Erro400, sucesso: Agenda) => {
      if (sucesso) {
        this.agenda = sucesso;
        if (this.agenda.days && this.agenda.days.length > 0) {
          this.diaSelecionado = this.agenda.days[0];
          this.diaSelect = this.diaSelecionado.uuid;
          this.criarListaDias();
        }
      } else if (erro && erro.error) {
        this.toastr.error('', erro.error.message);
      }
    });

    this.agendaDia = new AgendaDiaDto();
  }

  cadastrarAgenda() {
    if (!this.agendaDia.day && !this.agendaDia.interest) {
      this.toastr.error('', 'É necessário informar o interesse ou a data');
      return
    }
    if (this.agendaDia.interest && !this.agendaDia.order) {
      this.toastr.error('', 'É necessário informar a ordem');
      return
    }
    this.spinner.show();
    this.request = new AgendaRequest();
    if (this.agendaDia.day) {
      this.request.day = this.dataService.format(this.agendaDia.day);
    }
    if (this.agendaDia.interest) {
      this.request.interest = this.agendaDia.interest
    }
    if (this.agendaDia.order) {
      this.request.order = this.agendaDia.order
    }
    if (this.agendaDia.nome) {
      this.request.nome = this.agendaDia.nome
    }
    this.request.required = this.agendaDia.required;
    this.agendaService.criar(this.evento.uuid, this.request, (erro: Erro400, sucesso: Agenda) => {
      if (sucesso) {
        this.toastr.success('', 'Agenda salva com sucesso');
         const dia = this.encontrarDiaAgenda(sucesso)
        this.agenda = sucesso;
        this.agendaDia = new AgendaDiaDto();
        const agendaView = new AgendaDiaView(null);
        if (this.request.day) {
          agendaView.day = this.request.day.substring(0, 10);
        }
        if (this.request.interest) {
          agendaView.interest = this.request.interest
        }
        if (this.request.order) {
          agendaView.order = this.request.order
        }
        if (dia && dia.uuid){
          agendaView.uuid = dia.uuid;
        }
        agendaView.required = this.request.required
        this.selecionarDia(agendaView);
      } else if (erro && erro.error) {
        this.toastr.error('', erro.error.message);
      }
      this.spinner.hide();
    });
  }

  encontrarDiaAgenda(sucesso: Agenda){
    for(let j = 0; j < sucesso.days.length; j++) {
      let day = sucesso.days[j];
      let encontrei = false;
      for(let i = 0; i < this.agenda.days.length; i++) {
        if(day.uuid === this.agenda.days[i].uuid) {
          encontrei = true;
          break;
        }
        
      }
      if(encontrei === false){
        return day;
      }
    }
    sucesso.days.forEach(day => {
      
    })
    return null;
  }

  receberDiaSelecionado(diaSelecionado) {
    this.diaSelecionado = diaSelecionado;
  }

  receberAgenda(agendaEditada) {
    this.agenda = agendaEditada;
    if (!this.diaSelecionado) {
      this.diaSelecionado = this.agenda.days[0];
    }
    this.selecionarDia(new AgendaDiaView(this.diaSelecionado));
  }

  receberAgendaAoExcluirDinamica(atualizarAoExcluir) {
    this.request = atualizarAoExcluir
    this.agendaService.buscar(this.evento.uuid, (erro: Erro400, sucesso: Agenda) => {
      if (sucesso) {
        this.agenda = sucesso;
        this.receberAgenda(sucesso);
      } else if (erro && erro.error) {
        this.toastr.error('', erro.error.message);
      }
    });
  }

  selecionarDiaSelect() {
    let dia = null;
    this.agenda.days.forEach(d => {
      if (d.uuid === this.diaSelect) {

        dia = d;
      }
    })


    this.selecionarDia(new AgendaDiaView(dia))
  }

  selecionarDia(dia: AgendaDiaView) {


    this.agenda.days.forEach(day => {
      if (day.day) {
        if (dia.uuid === day.uuid) {
          this.diaSelecionado = day;
        }
      } else {
        if (dia.interest === day.interest && dia.order === day.order) {
          this.diaSelecionado = day
          if (this.listDias) {
            this.listDias.forEach(ld => {
              if (ld.interest && ld.interest === dia.interest) {
                this.diaSelecionado.interest_name = ld.interest_name;
              }
            })
          }
        }
      }
    });
    this.criarListaDias();
  }

  criarListaDias() {
    if (this.agenda.days) {
      this.listDias = new Array<AgendaDiaView>();
      this.agenda.days.forEach(dia => {
        const diaView = new AgendaDiaView(dia);
        if (this.diaSelecionado && diaView.uuid === this.diaSelecionado.uuid) {
          diaView.class = 'is-active';
        }
        if (dia.interest) {
          this.interests.forEach(i => {
            if (i.uuid === dia.interest) {
              diaView.interest_name = i.name
            }
          })
        }
        this.listDias.push(diaView);
        this.listDias.sort(function (d1, d2) {
          if (d1.day && d2.day) {
            if (d1.day < d2.day) {
              return -1;
            } else if (d1.day === d2.day) {
              return 0;
            } else {
              return 1;
            }
          } else if (d1.day && !d2.day) {
            return -1;
          } else if (!d1.day && d2.day) {
            return 1;
          } else {
            if (d1.order < d2.order) {
              return -1
            } else if (d1.order === d2.order) {
              return 0
            } else {
              return 1
            }
          }
        })
      });
    }
  }

  abrirModalEditarAgenda() {
    this.agendaEdit = new Array<AgendaDiaEditDto>();

    this.agenda.days.forEach(dia => {
      const diaEdit = new AgendaDiaEditDto();
      diaEdit.uuid = dia.uuid;
      diaEdit.dia = dia.day;
      this.agendaEdit.push(diaEdit);
    });

    this.classeModalEditarAgenda = 'modal is-active';
  }

  fecharModalEditarAgenda() {
    this.classeModalEditarAgenda = 'modal';
  }

  editarAgenda() {
    this.spinner.show();
    const agendaEditRequest = new AgendaEditDto();
    agendaEditRequest.agenda = new Array<AgendaDiaEditRequest>();
    this.agendaEdit.forEach(dia => {
      if (dia.novoDia) {
        const agendaDiaRequest = new AgendaDiaEditRequest();
        agendaDiaRequest.uuid = dia.uuid;
        agendaDiaRequest.day = this.dataService.formatOnlyData(dia.novoDia);
        agendaEditRequest.agenda.push(agendaDiaRequest);
      }
    });

    this.agendaService.editarDatas(this.evento.uuid, agendaEditRequest, (erro: Erro400, sucesso: Agenda) => {
      if (sucesso) {
        this.toastr.success('', 'Datas da Agenda editadas com sucesso');
        this.agenda = sucesso;
        this.diaSelecionado = this.agenda.days[0];
        this.criarListaDias();
        this.fecharModalEditarAgenda();
        this.spinner.hide();
      } else if (erro && erro.error) {
        this.toastr.error('', erro.error.message);
      }
      this.spinner.hide();
    });
  }

  diasCampanha() {
    let diffDays = this.evento.daysOfContent
    if (!diffDays) {
      const diffTime = Math.abs(this.evento.endContent.getTime() - this.evento.start.getTime());
      let diffDays = ((Math.ceil(diffTime / (1000 * 60 * 60 * 24)))) + 1;
    }
    return diffDays;
  }

  validaAgendasInteresse() {
    if (this.evento.scheduleType === 'INTEREST') {
      const dias = parseInt(this.diasCampanha() + '');

      return dias < this.agenda.days.length;
    } else {
      return true;
    }
  }

  openDrop() {
    this.data = Array<any>();
  }

  handleFilter(value) {
    this.interesseService.buscar(value, true, (erro: Erro400, sucesso: Array<Interesse>) => {
      if (sucesso) {
        let lista: Array<any> = []
        for (var i = 0; i < sucesso.length; i++) {
          let presente: boolean = false
          if (this.listDias && this.listDias.length > 0) {
            for (var j = 0; j < this.listDias.length; j++) {
              if (this.listDias[j].interest === sucesso[i].uuid) {
                presente = true;
                break;
              }
            }
          }
          if (!presente) {
            lista.push(sucesso[i])
          }
        }

        this.interests = lista;
        this.data = lista;
      } else if (erro && erro.error) {
        this.toastr.error('', erro.error.message);
      }
    });
    // this.data = this.interests.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  validarDiasRepidosSemUnidades() {
    if(this.evento.scheduleType ==='CHRONOLOGIC'){
      let diasInvalidosList: AgendaDia[] = []
      this.agenda.days.forEach((d) => {
        const diasRepitidos = this.agenda.days.filter(ad => ad.day === d.day);
  
        this.varificarSeDiasRepidosComAreaVazia(diasRepitidos, diasInvalidosList)
  
      })
      this.atribuirTextoDiasInvalidos(diasInvalidosList)
      return diasInvalidosList.length > 0;
    }
    
    return false;
    
  }

  varificarSeDiasRepidosComAreaVazia(diasRepitidos : AgendaDia[], diasInvalidosList: AgendaDia[]){
    if (diasRepitidos && diasRepitidos.length > 1) {
      diasRepitidos.forEach((dr) => {
        if (dr.units === null || dr.units.length === 0) {
          const diaExistir = diasInvalidosList.find(di => di.uuid === dr.uuid);
          if (!diaExistir) {
            diasInvalidosList.push(dr);
          }

        }
      })
    }
  }

  atribuirTextoDiasInvalidos(diasInvalidosList:AgendaDia[]) {

      this.diasInvalidos = "";
      if (diasInvalidosList.length === 1) {
        let interesseText = ""
        if( diasInvalidosList[0].interest){
          interesseText = `- ${diasInvalidosList[0].interestDetail.name}`
        }
        let diaText = "00/00/0000";
        if(diasInvalidosList[0].day){
          diaText = diasInvalidosList[0].day;
        }
        const diaNome = `- ${diasInvalidosList[0].nome}`
        
        this.diasInvalidos = `O dia ${diaText}${diaNome}${interesseText} não possui `
      } else {
        diasInvalidosList.forEach((dias) => {
          let interesseText = ""
          if( dias.interest){
            interesseText = `- ${dias.interestDetail.name}`
          }
          
          const   diaText = dias.day;
          

          const diaNome = `- ${dias.nome}`
          

          if (this.diasInvalidos === "") {                   
            this.diasInvalidos = `Os dias ${diaText}${diaNome}${interesseText}`
          } else {
            this.diasInvalidos = `${this.diasInvalidos}, ${diaText}${diaNome}${interesseText}`
          }
  
        })
        this.diasInvalidos = `${this.diasInvalidos} não possuem`
      }
    

  }
}
