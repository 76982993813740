import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Agenda, AgendaEditDto, AgendaRequest } from '../dto/agenda';
import { Erro400 } from '../dto/erros';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {

  apiHost: string = environment.apiHost;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) { }

  deleteByEvento(uuidEvento: string){
    return this.http.delete(this.apiHost + '/api/v1/event/' + uuidEvento + '/schedule');
  }


  buscar(uuidEvento: string, callback: Function) {
    this.http.get(this.apiHost + '/api/v1/event/' + uuidEvento + '/schedule').subscribe(
      (agendas: Agenda) => {
        callback(null, agendas);
      },
      (erro: any) => {
        callback(erro, null);
      }

    );
  }

  public criar(uuidEvento: string, agenda: AgendaRequest, callback: Function){
    this.http.post(this.apiHost + '/api/v1/event/' + uuidEvento + '/schedule', agenda,
     {headers: this.loginService.authorizationHeader()}).subscribe(
        (sucesso: Agenda) => {
            callback(null, sucesso);
        },
        (falha: Erro400) => {
            callback(falha, null);
        }
    );
  }

  public editarDatas(uuidEvento: string, agenda: AgendaEditDto, callback: Function){
    this.http.put(this.apiHost + '/api/v1/event/' + uuidEvento + '/schedule', agenda,
     {headers: this.loginService.authorizationHeader()}).subscribe(
        (sucesso: Agenda) => {
            callback(null, sucesso);
        },
        (falha: Erro400) => {
            callback(falha, null);
        }
    );
  }

  public editar(uuidEvento: string, agenda: AgendaRequest, callback: Function){
    this.http.put(this.apiHost + '/api/v1/event/' + uuidEvento + '/schedule/' + agenda.uuid, agenda,
     {headers: this.loginService.authorizationHeader()}).subscribe(
        (sucesso: Agenda) => {
            callback(null, sucesso);
        },
        (falha: Erro400) => {
            callback(falha, null);
        }
    );

  }

  public excluir(uuidEvento: string, uuidAgenda: string, callback: Function){
    this.http.delete(this.apiHost + '/api/v1/event/' + uuidEvento + '/schedule/' + uuidAgenda).subscribe(
        (sucesso: Agenda) => {
            callback(null, sucesso);
        },
        (falha: Erro400) => {
            callback(falha, null);
        }
    );
  }

  public upload(uuid: string, guid: string, tipo: string, file: File, uuidDinamic: string, callback: Function){
    const formdata: FormData = new FormData();
    formdata.append('file', file);

    let params = new HttpParams();
    params = params.set('type', tipo);
    
    if(uuidDinamic !== null){
      params = params.set('uuidDinamic', uuidDinamic);
    }

    this.http.post(this.apiHost + '/api/v1/event/' + uuid + '/schedule/' + guid + '/prize', formdata,
      {headers: this.loginService.authorizationHeader(), params: params}).subscribe(
        (sucesso: Agenda) => {
          callback(null, sucesso);
        },
        (falha: Erro400) => {
          callback(falha, null);
        }
    );
  }

}
