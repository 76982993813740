import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FiltroBuscaDinamica } from 'src/app/dto/filtroBuscaDinamica';
import { Theme } from 'src/app/dto/theme';



@Component({
  selector: 'app-filtro-busca',
  templateUrl: './filtro-busca.component.html',
  styleUrls: ['./filtro-busca.component.css'],
})
export class FiltroBuscaComponent implements OnInit {

  filtroDinamica: FiltroBuscaDinamica = new FiltroBuscaDinamica();
  @Output()
  filtroEmitter: EventEmitter<FiltroBuscaDinamica> = new EventEmitter();

  @Input()
  themes: Theme[];

  @Input()
  textoBuscar: string = "Buscar"

  @Input()
  classeBuscar: string = "button is-primary"
  constructor( ) { }

  ngOnInit() {
    this.filtroDinamica.theme = ""
  }

  emitterFilter(){
    this.filtroEmitter.emit(this.filtroDinamica);
  }

}
