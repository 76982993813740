import { OnInit, Component } from '@angular/core';
import { Client } from 'src/app/dto/client';
import { ClientsService } from 'src/app/services/clients.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-clientes-list',
    templateUrl: './clientes-list.component.html',
    styleUrls: ['./clientes-list.component.css']
  })
export class ClienteslistComponent implements OnInit{
  clientes: Array<Client>;

  nome: string;
  notas: string;

  pagina: number = 0;
  quantidadePorPagina: number = 10;
  temMais: boolean = true;

  textoCarregarMais: string = "Carregar mais";
  classeCarregarMais: string = "button is-primary";

  textoBuscar: string = "Buscar";
  classeBuscar: string = "button is-primary";
  
  constructor(private clienteService: ClientsService, private router: Router){
  }
    
  ngOnInit(): void {
    this.pagina = 0;
    this.nome = "";
    this.notas = "";

    this.clientes = new Array<Client>();
    this.clienteService.buscar(this.nome, this.notas, this.pagina, this.quantidadePorPagina, (erro: any, sucesso: Array<Client>) => {
      if(sucesso){
        this.clientes = sucesso;
        if(sucesso.length == this.quantidadePorPagina){
          this.temMais = true;
        }else{
          this.temMais = false;
        }
      }
    });  
  }

  buscar(){
    this.textoBuscar = "Buscando...";
    this.classeBuscar = "button is-light";
    this.pagina = 0;
    this.clientes = new Array<Client>();
    
    this.clienteService.buscar(this.nome, this.notas, this.pagina, this.quantidadePorPagina, (erro: any, sucesso: Array<Client>) => {
      this.textoBuscar = "Buscar";
      this.classeBuscar = "button is-primary";
      if(sucesso){
        this.clientes = sucesso;
        if(sucesso.length == this.quantidadePorPagina){
          this.temMais = true;
        }else{
          this.temMais = false;
        }
      }
    });
  }

  carregarMais(){
    this.textoCarregarMais = "Carregando...";
    this.classeCarregarMais = "button is-light";
    this.pagina++;
    this.clienteService.buscar(this.nome, this.notas, this.pagina, this.quantidadePorPagina, (erro: any, sucesso: Array<Client>) => {
      this.textoCarregarMais = "Carregar mais";
      this.classeCarregarMais = "button is-primary";
      if(sucesso){
        this.clientes = this.clientes.concat(sucesso);
        if(sucesso.length == this.quantidadePorPagina){
          this.temMais = true;
        }else{
          this.temMais = false;
        }
      }
    });
  }

  novo(){
    this.router.navigate(['/clients/new'])
  }

}