import { Component, OnInit, Input } from "@angular/core";
import { MensagemVideo, MensagemVideoDTO } from "src/app/dto/mensagem-video";
import { MensagemVideoService } from "src/app/services/mensagem-video.service";
import { Event, EventDto, EventCopiaDto } from "src/app/dto/event";
import { Erro400 } from "src/app/dto/erros";
import { NavigationExtras, Router, ActivatedRoute } from "@angular/router";

@Component({
	selector: "app-mensagem-video-list",
	templateUrl: "./mensagem-video-list.component.html",
	styleUrls: ["./mensagem-video-list.component.css"],
})
export class MensagemVideoListComponent implements OnInit {
	mensagemVideos: MensagemVideo[];
	eventoUuid: string = "";
	@Input() evento: EventDto;

	mensagemVideoSelecionada: MensagemVideoDTO;

	classeAbaList: string;
	classeAbaMensagemVideo: string;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private mensagemVideoService: MensagemVideoService
	) {}

	ngOnInit(): void {
		this.classeAbaList = "is-active";
		this.classeAbaMensagemVideo = "";

		this.eventoUuid = this.route.snapshot.paramMap.get("uuid");
		this.todas();
	}

	todas() {
		this.mensagemVideoService.listarTodos(
			this.eventoUuid,
			(error: any, sucesso: MensagemVideo[]) => {
				if (sucesso) {
					this.mensagemVideos = sucesso;
				}
			}
		);
	}

	newMensagem(novo: string) {
		if (novo === "novo") {
			this.router.navigate(["/events/" + this.eventoUuid + "/video-message"]);
		}
	}

	renderFormMensagemVideo(mensagem: MensagemVideo) {
		this.mensagemVideoSelecionada = new MensagemVideoDTO(mensagem);
		this.alterarAba("form");
	}

	retornarParaListagem(aba) {
		this.mensagemVideoSelecionada = new MensagemVideoDTO();
		if (aba === "lista") {
			this.todas();
		}
		this.alterarAba(aba);
	}

	alterarAba(aba: string) {
		if (aba === "lista") {
			this.classeAbaList = "is-active";
			this.classeAbaMensagemVideo = "";
		} else if (aba === "form") {
			this.classeAbaList = "";
			this.classeAbaMensagemVideo = "is-active";
		}
	}
}
