import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.css']
})
export class AvisosComponent implements OnInit {
  @Input() titulo: string ;
  @Input() mensagem ;
  @Input() ativo;
  @Output() acao = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }


  atualizarAcao(acao) {
    this.acao.emit(acao);
  }
}
