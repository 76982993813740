import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ComoFunciona } from '../dto/comoFunciona';
import { Erro400 } from '../dto/erros';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class ComoFuncionaService {
  apiHost: string = environment.apiHost

  constructor(private http: HttpClient, private loginService: LoginService) {
   }
  public criar(comofunciona: ComoFunciona, callback: Function){
    this.http.post(this.apiHost + '/api/v1/help', comofunciona, {headers: this.loginService.authorizationHeader()}).subscribe(

      (sucesso: ComoFunciona) => {
            callback(null, sucesso);
        },
        (falha: Erro400) => {
            callback(falha, null);
        }
    );
  }
  public buscarComoFunciona(titulo: string, pagina: number, quantidadePorPagina: number,callback: Function){
    let params = new HttpParams();
    if(titulo){          
      params = params.set('titulo', titulo);
    }
        
    params = params.set('page', pagina + '');
    params = params.set('pageSize', quantidadePorPagina + '');

    this.http.get(this.apiHost + '/api/v1/help', {headers: this.loginService.authorizationHeader(), params: params}).subscribe(

      
      (sucesso: Array<ComoFunciona>) => {
            callback(null, sucesso);
        },
        (falha: Erro400) => {
            callback(falha, null);
        }
    );
  }
  
  public setMensagemHelp(mensagem:string){
    var comoFunciona = new ComoFunciona();
    comoFunciona.helpMessage = mensagem;
    return comoFunciona;
  }
}

