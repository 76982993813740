import { Component, Input, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Erro400 } from "src/app/dto/erros";
import { EventDto } from "src/app/dto/event";
import { MensagemDTO } from "src/app/dto/mensagem";
import { MensagemService } from "src/app/services/mensagem.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-mensagem-list',
    templateUrl: './mensagem-list.component.html',
    styleUrls: ['./mensagem-list.component.css']
  })
export class MensagemListComponent implements OnInit{
    @Input() evento: EventDto;

    mensagens: Array<MensagemDTO>;
    codigo: string;
    idioma: string;
    texto: string;

    textoBuscar: string = "Buscar";
    classeBuscar: string = "button is-primary";

    textoCarregarMais: string = "Carregar mais";
    classeCarregarMais: string = "button is-primary";

    formulario: boolean;
    mensagem: MensagemDTO;

    pagina: number = 0;
    quantidadePorPagina: number = 10;
    temMais: boolean = true;

    constructor(private mensagemService: MensagemService, private toastr: ToastrService, private spinner: NgxSpinnerService, private router: Router){

    }
    
    ngOnInit(): void {
      this.pagina = 0;
      this.formulario = false;
      if(this.evento){
        this.mensagens = this.evento.messages;
        this.temMais = false;
        
      }else{
        this.mensagemService.find(null, null, null, null, this.pagina, this.quantidadePorPagina, (erro: Erro400, ms: MensagemDTO[]) => {
          if(ms){
            this.mensagens = ms;
            if(ms.length == this.quantidadePorPagina){
              this.temMais = true;
            }else{
              this.temMais = false;
            }
          }else if(erro && erro.error){
            this.toastr.error('', erro.error.message);
          }
        })
      }
    }

    novo(){
      this.formulario = true;
      this.mensagem = new MensagemDTO();
    }

    buscar(){
      this.pagina = 0;
      this.textoBuscar = "Buscando...";
      this.classeBuscar = "button is-light";
      let eventoUuid = null;
      if(this.evento){
        eventoUuid = this.evento.uuid;
      }
      this.mensagemService.find(eventoUuid, this.codigo, this.idioma, this.texto, this.pagina, this.quantidadePorPagina, (erro: Erro400, ms: Array<MensagemDTO>) => {
        this.textoBuscar = "Buscar";
        this.classeBuscar = "button is-primary";
        if(ms){
          this.mensagens = ms;
          if(ms.length == this.quantidadePorPagina){
            this.temMais = true;
          }else{
            this.temMais = false;
          }
        }else if(erro && erro.error){
          this.toastr.error('', erro.error.message);
        }
      });
    }

    carregarMais(){
      this.textoCarregarMais = "Carregando...";
      this.classeCarregarMais = "button is-light";
      this.pagina++;
      let eventoUuid = null;
      if(this.evento){
        eventoUuid = this.evento.uuid;
      }
      this.mensagemService.find(eventoUuid, this.codigo, this.idioma, this.texto, this.pagina, this.quantidadePorPagina, (erro: Erro400, ms: Array<MensagemDTO>) => {
        this.textoCarregarMais = "Carregar mais";
        this.classeCarregarMais = "button is-primary";
        if(ms){
          this.mensagens = this.mensagens.concat(ms);
          if(ms.length == this.quantidadePorPagina){
            this.temMais = true;
          }else{
            this.temMais = false;
          }
        }else if(erro && erro.error){
          this.toastr.error('', erro.error.message);
        }
      });
    }

    editar(mensagem: MensagemDTO){
      this.formulario = true;
      this.mensagem = mensagem;
    }

    voltar(){
      this.formulario = false;
    }

    retornarParaListagem(acao) {
      if (acao === "LISTA") {
        this.ngOnInit();
        this.buscar();
      }
    }

    apagarMensagem(mensagem: MensagemDTO) {
      this.mensagem = mensagem
      
      this.spinner.show();
      this.mensagemService.delete(
        this.mensagem,
        (erro: any, sucesso: any) => {
          if (erro) {
            if (erro.error && erro.error.message) {
              this.toastr.error("", erro.error.message);
            } else {
              this.toastr.error(
                "",
                "Falha ao excluir mensagem, entre em contato com o suporte ou tente novamente mais tarde"
              );
            }
          } else {
            this.toastr.success(
              "",
              "A mensagem foi removida com sucesso"
            );
            this.retornarParaListagem('LISTA');
            
          }
          this.spinner.hide();
        }
      );
    }
}