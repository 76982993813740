import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Erro400 } from '../dto/erros';
import { Unidade } from '../dto/unidade';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
  })
export class UnidadeService{
    private apiHost: string = environment.apiHost;

    constructor(
        private http:HttpClient,
        private loginService: LoginService
    ) { }

    public buscar(clienteUuid: string, name:string, parentId: string, full: boolean, callback: Function) {
        let params = new HttpParams();
        if (name) {
          params = params.set("name", name);
        }

        if (parentId) {
          params = params.set("parentId", parentId);
        }

        if (full) {
          params = params.set("full", full.toString());
        }

        const requestOptions = {
          headers: this.loginService.authorizationHeader(),
          params: params,
        };
        
        this.http
          .get(
            this.apiHost + "/api/v1/client/" + clienteUuid + "/unit",
            requestOptions
          )
          .subscribe(
            (sucesso: Array<Unidade>) => {
              callback(null, sucesso);
            },
            (falha: Erro400) => {
              callback(falha, null);
            }
          );
    }

    public criar(uuid: string, unidade: Unidade, callback: Function){
        this.http.post(this.apiHost + '/api/v1/client/' + uuid + '/unit', unidade, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: Unidade) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }
    public remover(uuid: string, unidade: Unidade, callback: Function){
        this.http.delete(this.apiHost + '/api/v1/client/' + uuid + '/unit/' + unidade.uuid, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: any) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }
}
