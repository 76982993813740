export class Icone {
    uuid: string = "" ;
    dinamica: string = "";
    status: boolean ;
    url: string = "";

    constructor(icone: Icone){
        if(icone){
            this.uuid = icone.uuid;
            this.dinamica = icone.dinamica;
            this.status = icone.status;
            this.url = icone.url;
        }
    }
}

export const iconeDynamics = [
    { value: "NEW_HABIT", label: "Novos hábitos"}, 
    { value: "FACT_OR_FAKE", label: "Fato ou fake"}, 
    { value: "GAME", label: "Game"}, 
    { value: "EXTRA", label: "Extra"}, 
    { value: "MURAL", label: "Mural"}, 
    { value: "VIDEO", label: "Video"}
];

export class IconePost{
    uuid: string = "";
    dynamic: string = "";
    status: string = "";
    file: File = null;

    constructor(iconePost: IconePost = undefined){
        if(iconePost){
            this.uuid = iconePost.uuid;
            this.dynamic  = iconePost.dynamic;
            this.status = iconePost.uuid;
        }
    }
}

export class KitIconesCreate{
    name: string = "";
    defaultKit: boolean = false;
    description: string = "";
    uuid: string = null;

    constructor(kitIconesCreate: KitIconesCreate = null){
        if(kitIconesCreate){
            this.uuid = kitIconesCreate.uuid;
            this.name = kitIconesCreate.name;
            this.defaultKit = kitIconesCreate.defaultKit;
            this.description = kitIconesCreate.description;
        }
    }
    checked(){
        return this.defaultKit? "active": "";
    }
    toggleDefaultKit(){
        this.defaultKit = !this.defaultKit;
    }
}

export class KitIconesDelete{
    uuid: string = null;
    replace: string = null;
    constructor(kitIconesDelete: KitIconesDelete = null){
        if(kitIconesDelete){
            this.uuid = kitIconesDelete.uuid;
            this.replace = kitIconesDelete.replace;
        }
    }
}
export class KitIcones {
    uuid: string = "";
    name: string = "";
    default: string = "";
    description: string;
    icons: Array<Icone> = [];
  
    constructor(kit: KitIcones = null){
        if(kit){
            this.uuid = kit.uuid;
            this.name = kit.name;
            this.default = kit.default;
            this.description = kit.description;
            this.icons = kit.icons;
        }
    }
}

export class KitIconesSearch {
    name: string = "";
    description: string = "";
    standard: string = "";
    page: number = 0;
    pageSize: number = 20;

    constructor(kit: KitIconesSearch = null){
        if(kit){
            this.name = kit.name;
            this.description = kit.description;
            this.standard  = kit.standard;
        }
    }
    
    resetPage(){
        this.page = 0;
    }
    incrementPage(){
        this.page = this.page + 1;
    }

}

export const defaultOptions = [
    {
        value: "", label: "Todas"
    },
    {
        value: "true", label: "Sim"
    },
    {
        value: "false", label: "Não"
    },
]