import { Theme } from './theme';

export class Video{
    uuid: string;
    title: string;
    name: string;
    code: string;
    url: string;
    presentation: string;
    description: string;
    theme: Theme;
    active: boolean;

    constructor(v: Video = null){
        if(v){
            this.uuid = v.uuid;
            this.name = v.name;
            this.code = v.code;
            this.url = v.url;
            this.presentation = v.presentation;
            this.description = v.description;
            this.theme = v.theme;
            this.active = v.active;
            this.title = v.title;
        }else{
            this.theme = new Theme();
        }
    }

    checked(){
        if(this.active){
            return "checked";
        }else{
            return "";
        }
    }

    toJSON(){
        let json = {
           'uuid': this.uuid,
           'name': this.name,
           'code': this.code,
           'url': this.url,
           'presentation': this.presentation,
           'description': this.description,
           'theme': {'uuid': this.theme.uuid},
           'active': this.active != undefined ? this.active : false,
           'title': this.title
        };
        return json;
     }
}