import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { Erro400 } from 'src/app/dto/erros';
import { Interesse } from 'src/app/dto/interesse';
import { InteresseService } from 'src/app/services/interesse.service';


@Component({
  selector: 'app-interesse-list',
  templateUrl: './interesse-list.component.html',
  styleUrls: ['./interesse-list.component.css']
})
export class InteresseListComponent implements OnInit {
  interesses: Interesse[];
  nome: string;
  ativo: boolean;

  textoBuscar: string = "Buscar"
  classeBuscar: string = "button is-primary"

  constructor(private interesseService: InteresseService, private router: Router, private toasterService: ToastrService) { }

  ngOnInit(): void {
    this.nome = "";
   
  }

  search(){
    this.textoBuscar = "Buscando..."
    this.classeBuscar = "button is-light"

    this.interesseService.buscar(this.nome, this.ativo, (error: any, sucesso: Interesse[])=>{
      if(sucesso){
        this.interesses = new Array<Interesse>();
        sucesso.forEach(i =>{
          this.interesses.push(new Interesse(i));
        });
        this.textoBuscar = "Buscar"
        this.classeBuscar = "button is-primary"
      }
    }) 
  }

  edit(interesse: Interesse){
    let navigationExtras: NavigationExtras
    navigationExtras = {
      state: {
        interesse: interesse
      }
    }
    this.router.navigate(["/interesses/edit/" + interesse.uuid], navigationExtras)
  }

}
