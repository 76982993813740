import { OnInit, Component } from '@angular/core';

@Component({
    selector: 'app-biblioteca-list',
    templateUrl: './biblioteca-list.component.html',
    styleUrls: ['./biblioteca-list.component.css']
  })
export class BibliotecalistComponent implements OnInit{
    ngOnInit(): void {
        
    }

}