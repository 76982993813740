import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Erro400 } from '../dto/erros';
import { CreateParticipante, Participante } from '../dto/participante';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
  })
export class ParticipanteService{
    private apiHost: string = environment.apiHost;

    constructor(
        private http:HttpClient,
        private loginService: LoginService
    ) { }

    public buscar(nome: string, sobrenome: string, genero: string, email: string, pagina: number,
        quantidadePorPagina: number, matricula: string, code: string, callback: Function){

        let params = new HttpParams();
        if(nome){
            params = params.set('firstName', nome);
        }
        if(sobrenome){
            params = params.set('lastName', sobrenome);
        }
        if(genero){
            params = params.set('gender', genero);
        }
        if(email){
            params = params.set('email', email);
        }
        if (matricula) {
            params = params.set('enrollment', matricula);
        }
        if (code){
            params = params.set('code', code);
        }
        params = params.set('page', pagina + '');
        params = params.set('pageSize', quantidadePorPagina + '');

        this.http.get(this.apiHost + '/api/v1/participant', {headers: this.loginService.authorizationHeader(), params: params}).subscribe(
            (sucesso: Array<Participante>) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }

        );
    }

    public get(uuid: string, callback: Function){
        this.http.get(this.apiHost + '/api/v1/participant/' + uuid, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: Participante) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public editar(participante: CreateParticipante, callback: Function){
        participante.user.formataData();
        this.http.put(this.apiHost + '/api/v1/participant/' + participante.uuid, participante, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: Participante) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }
}