import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { KitDto } from 'src/app/dto/kit';
import { KitService } from 'src/app/services/kit.service';

@Component({
    selector: 'app-kist-list',
    templateUrl: './kit-list.component.html',
    styleUrls: ['./kit-list.component.css']
  })
export class KitListComponent implements OnInit{
    kits: KitDto[]
    textoBuscar: string = "Buscar"
    classeBuscar: string = "button is-primary"

    constructor(private kitService: KitService, private router: Router){

    }
    ngOnInit(): void {
        this.buscar()
    }

    edit(kitEdit: KitDto){
        let navigationExtras: NavigationExtras
        navigationExtras = {
          state: {
            kit: kitEdit
          }
        }
        this.router.navigate(["/kits/edit/" + kitEdit.uuid], navigationExtras)
      }

    buscar(){
        this.textoBuscar = "Buscando..."
        this.classeBuscar = "button is-light"
        
        this.kitService.search((error: any, result: KitDto[]) => {
          if(result){
            this.kits = result
            this.textoBuscar = "Buscar"
            this.classeBuscar = "button is-primary"
          }
        })
      }

}