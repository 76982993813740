import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Erro400 } from 'src/app/dto/erros';
import { KitDto } from 'src/app/dto/kit';
import { KitService } from 'src/app/services/kit.service';

@Component({
    selector: 'app-upload-trofeu-kit',
    templateUrl: './upload-trofeu-kit.component.html',
    styleUrls: ['./upload-trofeu-kit.component.css']
  })
export class UploadTrofeuKitComponent implements OnInit{
    @Input() kit: KitDto;
    @Input() tipo: string;

    trofeu: File;
    url: string;
    @Input() day: string;
    @Input() dynamic: string;

    @Output() updateKitEvent = new EventEmitter<KitDto>();

    constructor(
        private kitService: KitService, 
        private toastr: ToastrService,
        private spinner: NgxSpinnerService){}

    ngOnInit(): void {
        this.preencheUrl();
    }

    preencheUrl(){
        if(!this.day && !this.dynamic){
            if(this.tipo == 'ATIVO'){
                this.url = this.kit.trofeuAtivo;
            }else if(this.tipo == 'INATIVO'){
                this.url = this.kit.trofeuInativo;
            }
        }else if(this.day && !this.dynamic){
            this.kit.kitCampanhaDiaDto.forEach(kd => {
                if(kd.dia == parseInt(this.day)){
                    if(this.tipo == 'ATIVO'){
                        this.url = kd.trofeuAtivo;
                    }else if(this.tipo == 'INATIVO'){
                        this.url = kd.trofeuInativo;
                    }
                }
            });
        }else if(this.day && this.dynamic){
            this.kit.kitCampanhaDiaDto.forEach(kd => {
                if(kd.dia == parseInt(this.day)){
                    kd.kitsDinamica.forEach(kdin => {
                        if(kdin.tipo == this.dynamic){
                            if(this.tipo == 'ATIVO'){
                                this.url = kdin.trofeuAtivo;
                            }else if(this.tipo == 'INATIVO'){
                                this.url = kdin.trofeuInativo;
                            }    
                        }
                    });
                }
            });
        }
    }

    upload(){
        if(this.tipo == 'ATIVO'){
            
            this.kitService.upload(this.kit.uuid, this.day, this.dynamic, "true", this.trofeu, (erro: Erro400, sucesso: KitDto) => {
            if(sucesso){
                this.kit = new KitDto(sucesso);
                this.updateKitEvent.emit(this.kit);
                this.preencheUrl();
            }else if(erro && erro.error){
                this.toastr.error('', erro.error.message);
            }
            });
        }else if(this.tipo == 'INATIVO'){
            this.kitService.upload(this.kit.uuid, this.day, this.dynamic, "false", this.trofeu, (erro: Erro400, sucesso: KitDto) => {
            if(sucesso){
                this.kit = new KitDto(sucesso);
                this.updateKitEvent.emit(this.kit);
                this.preencheUrl();
            }else if(erro && erro.error){
                this.toastr.error('', erro.error.message);
            }
            });
        }

    }

    onSelectFile(event) {
        if (event.target.files && event.target.files[0]) {
            this.trofeu = event.target.files[0];
        }
    }

}