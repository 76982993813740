import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import DinamicaPadrao from 'src/app/dto/dinamicaPadrao';
import { FiltroBiblioteca } from 'src/app/dto/filtroBiblioteca';
import { Mural } from 'src/app/dto/mural';
import { Theme } from 'src/app/dto/theme';
import { MuralService } from 'src/app/services/mural.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
    selector: 'app-mural-list',
    templateUrl: './mural-list.component.html',
    styleUrls: ['./mural-list.component.css']
  })
export class MuralListComponent implements OnInit{
  themes: Theme[];
  filtro: FiltroBiblioteca = new FiltroBiblioteca();
  murals: Mural[];
  textoBuscar: string = "Buscar";
  classeBuscar: string = "button is-primary";

  constructor(private muralService: MuralService, private themeService: ThemeService, private router: Router){}

  ngOnInit(): void {
    this.themeService.themes(null, (erro: any, temasRetornados: Theme[]) => {
      this.themes = temasRetornados;
    })
    this.buscar()  
  }

  buscar(){
    this.textoBuscar = "Buscando..."
    this.classeBuscar = "button is-light"

    this.muralService.buscar(this.filtro).subscribe(
      data => {
        this.murals = data;
        this.textoBuscar = "Buscar"
        this.classeBuscar = "button is-primary"

      },
      error => console.log('oops', error)             
    );
  }

  edit(e: DinamicaPadrao){
    this.router.navigate(['mural/edit/' + e.uuid], {state: {data: e}});
  }

}