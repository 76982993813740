import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { activeOptions, standardOptions, Template, TemplateSearch } from 'src/app/dto/template';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.css']
})
export class TemplateListComponent implements OnInit {

  templates: Template[];
  search: TemplateSearch;
  more: boolean = true;
  activeOptions = activeOptions;
  standardOptions = standardOptions;

  textoCarregarMais: string = "Carregar mais";
  classeCarregarMais: string = "button is-primary";

  textoBuscar: string = "Buscar";
  classeBuscar: string = "button is-primary";

  constructor(
    private templateService: TemplateService,
    private router: Router,
    private toasterService: ToastrService
  ) { }

  ngOnInit() {
    this.templates = new Array<Template>();
    this.search = new TemplateSearch();
    this.pesquisar();
  }

  pesquisar(){
    this.textoBuscar = "Buscando..."
    this.classeBuscar = "button is-light"
    this.search.page = 0;
    this.templates = new Array<Template>();

    this.templateService.buscar(this.search, (erro, sucesso)=>{
      this.textoBuscar = "Buscar";
      this.classeBuscar = "button is-primary";

      if(sucesso){
        this.templates = sucesso;
        if(sucesso.length && this.search.pageSize){
          this.more = true;
        } else {
          this.more = false
        }
      }
      if(erro){
        console.log(erro)
      }
    })
  }

  carregarMais(): void{
    this.textoCarregarMais = "Carregando...";
    this.classeCarregarMais = "button is-light";
    this.search.page++;
    this.templateService.buscar(this.search, (erro, sucesso)=>{
      this.textoCarregarMais = "Carregar mais";
      this.classeCarregarMais = "button is-primary";

      if(sucesso){
        this.templates = this.templates.concat(sucesso);
        if(sucesso.length && this.search.pageSize){
          this.more = true;
        } else {
          this.more = false
        }
      }
      if(erro){
        console.log(erro)
      }
    })
  }

  edit(template: Template){
    let navigationExtras: NavigationExtras
    navigationExtras = {
      state: {
        template: template
      }
    }
    this.router.navigate(["/templates/edit/" + template.uuid], navigationExtras)
  }
 
}
