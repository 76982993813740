import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Interesse } from '../dto/interesse';
import { LoginService } from './login.service';
import { Erro400 } from '../dto/erros';

@Injectable({
  providedIn: 'root'
})
export class InteresseService {

  apiHost: string = environment.apiHost

  constructor(private http: HttpClient, private loginService: LoginService) { }

  public buscar(nome: string, ativo: boolean, callback: Function){
    let httpParams = new HttpParams()   
    if(nome){
      httpParams = httpParams.set('name', nome)
    }
    
    if(ativo){
      httpParams = httpParams.set('active', "" + ativo)
    }

    this.http.get(this.apiHost + '/api/v1/interest', {params: httpParams}).subscribe(
      (interesses: Interesse[]) =>{
        callback(null, interesses);
      },
      (erro: any)=>{
        callback(erro, null);
      }
);
  }

  public criar(interesse: Interesse, callback: Function){
   
    this.http.post(this.apiHost + '/api/v1/interest/', interesse).subscribe(
      (sucesso: Interesse)=>{
        callback(null, sucesso);
      },
      (falha: Erro400) =>{
        callback(falha, null);
      }
    );
  }

  public editar(interesse: Interesse, callback: Function){
    this.http.put(this.apiHost + '/api/v1/interest/' + interesse.uuid, interesse, {headers: this.loginService.authorizationHeader()}).subscribe(
      (sucesso: Interesse)=>{
        callback(null, sucesso);
      },
      (falha: Erro400)=>{
        callback(falha, null);
      }
    );
  }

  public remover(uuid: string, callback: Function){
    this.http.delete(this.apiHost + '/api/v1/interest/' + uuid, {headers: this.loginService.authorizationHeader()}).subscribe(
      (sucesso: Interesse)=>{
        callback(null, sucesso);
      },
      (falha: Erro400)=>{
        callback(falha, null);
      }
    )

  }
}
