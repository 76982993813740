import { Component, OnInit } from '@angular/core';
import { FatoFake, FatoFakeClone } from 'src/app/dto/fatoFake';
import { FatoFakeService } from 'src/app/services/fato-fake.service';
import { ThemeService } from 'src/app/services/theme.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Theme } from 'src/app/dto/theme';
import { Erro400 } from 'src/app/dto/erros';
import { Questao } from 'src/app/dto/questao';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeletaImagemService } from '../../services/deleta-imagem.service';

@Component({
  selector: 'app-fato-fake-form',
  templateUrl: './fato-fake-form.component.html',
  styleUrls: ['./fato-fake-form.component.css']
})
export class FatoFakeFormComponent implements OnInit {
  ff: FatoFake;
  ffClone: FatoFakeClone;
  temas: Array<Theme>;

  classeAbaFF: string;
  classeAbaQuestoes: string;

  classeAbaListaQuestoes: string;
  classeAbaEditor: string;

  questao: Questao;

  public imagem: File;

  quillConfig={
    toolbar: {
      container: [
        ['bold', 'underline', 'strike'],        // toggled buttons
        [{ 'size': [] }]
      ],
    }
  }

  constructor(
    private temaService: ThemeService,
    private ffService: FatoFakeService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private deleteImagemService: DeletaImagemService,
    ) { }

  ngOnInit() {
    this.resetaTela('rota');
  }

  resetaTela(origem: string){
    this.mudaAbaPrincipal('ff')
    this.classeAbaListaQuestoes = "is-active";
    this.classeAbaEditor = "";
    this.ff = new FatoFake();
    this.questao = new Questao();

    this.temaService.themes(undefined, (erro: Erro400, sucesso: Array<Theme>) => {
      if(sucesso){
        this.temas = sucesso;
      }else if(erro && erro.error){
        this.toastr.error('', erro.error.message);
      }
    })

    if(origem && origem == 'rota'){
      var uuid = this.route.snapshot.paramMap.get("uuid");
      if(uuid){
        this.ff = new FatoFake(history.state.fatoFake);
        this.ffClone = new FatoFakeClone(this.ff);
      }
    }
  }

  novaQuestao() {
    this.questao = new Questao()
    this.mudaAba('editor')
  }

  cadastrarFatoFake(bt: string, origem: string){
    if (this.validatePresentation()) {
      this.spinner.show();
      if(this.ff.uuid){
        this.ffService.editar(this.ff, (erro: Erro400, sucesso: FatoFake) => {
          if(sucesso){
            this.ff = new FatoFake(sucesso);
            if(origem == 'FF'){
              this.questao = new Questao();
              this.toastr.success('', 'O fato ou fake foi atualizado com sucesso');
            }else{
              this.questao = new Questao(this.ff.questions[(this.ff.questions.length - 1)]);
              this.toastr.success('', 'A questão foi atualizada com sucesso');
            }
            if(bt){
              if(bt == 'NOVO'){
                this.resetaTela('cadastro');
              }else{
                this.router.navigate(['factFakes'])
              }
            }
          }else if(erro && erro.error){
            if(origem == 'FF'){
              this.toastr.error('', erro.error.message);
            }else{
              this.toastr.error('', erro.error.message);
            }
          }
          this.spinner.hide();
        });
      }else{
        this.ffService.criar(this.ff, (erro: Erro400, sucesso: FatoFake) => {
          if(sucesso){
            this.ff = new FatoFake(sucesso);
            this.mudaAbaPrincipal('qu');
            this.mudaAba('editor');
            this.toastr.success('', 'O fato ou fake foi cadastrado com sucesso');
            if(bt){
              if(bt == 'NOVO'){
                this.resetaTela('cadastro');
              }else{
                this.router.navigate(['factFakes'])
              }
            }
          }else if(erro && erro.error){
            this.toastr.error('', erro.error.message);
          }
          this.spinner.hide();
        });
      }
    }
  }

  mudaAbaPrincipal(aba: string){
    if(aba == 'ff'){
      this.classeAbaFF = "is-active";
      this.classeAbaQuestoes = "";
    }else if(aba == 'qu'){
      this.classeAbaFF = "";
      this.classeAbaQuestoes = "is-active";
    }
  }

  mudaAba(aba: string){
    if(aba == 'lista'){
      this.classeAbaListaQuestoes = "is-active";
      this.classeAbaEditor = "";
    }else if(aba == 'editor'){
      this.classeAbaListaQuestoes = "";
      this.classeAbaEditor = "is-active";
    }
  }
  cadastrarQuestao(){
    if(this.questao.enunciation && this.questao.answer){
      if(this.questao.uuid){
        for(let i=0; i<this.ff.questions.length; i++){
          if(this.ff.questions[i].uuid == this.questao.uuid){
            this.ff.questions[i] = this.questao;
          }
        }
      }else{
        this.ff.questions.push(this.questao);
      }
      this.cadastrarFatoFake(undefined, 'Q');
    }else{
      this.toastr.error('', 'Campos "Enunciado" e "Resposta" são de preenchimento obrigatório');
    }
  }

  editarQuestao(q: Questao){
    this.questao = new Questao(q);
    this.mudaAba("editor");
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.imagem = event.target.files[0];
    }
  }

  enviarImagem(){
    this.spinner.show();
    let arquivo: File = null;
    arquivo = this.imagem;
    this.ffService.enviarImagem(this.ff.uuid, this.questao.uuid, arquivo, (erro: Erro400, sucesso: FatoFake) => {
      if(sucesso){
        this.ff = new FatoFake(sucesso);
        this.ff.questions.forEach(q => {
          if(this.questao.uuid == q.uuid){
            this.questao = new Questao(q);
          }
        })
        this.toastr.success('', 'Arquivo enviado com sucesso');
      }else if(erro && erro.error){
        this.toastr.error('', erro.error.message);
      }else{
        this.toastr.error('', 'Aconteceu um erro ao tentar enviar a imagem, por favor, contate o responsável pelo sistema');
      }
      this.spinner.hide();
    });
  }

  validatePresentation() {
    if (!this.ff.presentation) {
      this.toastr.error('', 'Campo "Apresentação" é de preenchimento obrigatório');
      return false;
    } else {
      return true;
    }
  }

  validCloneForm(){
    
    if(this.ff.title === this.ffClone.title){
      return false;
    }   
    if(this.ff.code === this.ffClone.code){
      return false;
    }
    if(this.ff.name === this.ffClone.name){
      return false;
    }
    return true;
  }
  clonarFatoOuFake(){
    this.spinner.show();

    if(!this.validCloneForm()){
      this.toastr.error('', 'Os campos: nome, código e titulo não podem ser iguais aos do Fato ou Fake original.');
      return;
    }
    this.ffService.clonar(this.ffClone, this.ff.uuid, (err, sucess)=>{
      this.spinner.hide();
      if(err){
        this.toastr.error('', 'Aconteceu um erro ao tentar clonar, por favor, contate o responsável pelo sistema');
      }else{
        this.toastr.success('', 'O Fato ou Fake foi clonado com sucesso');
          this.router.navigate(['factFake/edit/' + sucess.uuid])
          this.ff = new FatoFake(sucess);
          this.ffClone = new FatoFakeClone(this.ff);
        }
    })
  }

  deleteQuestion(){
    if(!confirm("Tem certeza que deseja excluir esta questão?")){
      return;
    }
    this.spinner.show();

    this.ffService.deleteQuestion(this.ff.uuid, this.questao.uuid, (err, sucess)=>{
      this.spinner.hide();

      if(err){
        console.log(err);
        this.toastr.error(err.message);
      }else{
        this.toastr.success("Questão removida com sucesso!");
        this.ff = new FatoFake(sucess);
        this.mudaAba('lista');
      }
    });
  }

  deleteImagemQuestion(uuid: string){
    if(!confirm("Tem certeza que deseja excluir a imagem da questão?")){
      return;
    }
    this.spinner.show();
    this.deleteImagemService.deletaImagem(uuid,"FACT_OR_FAKE_QUESTION").subscribe(
      
      (sucess) => {
        this.spinner.hide();
        this.questao.picture = null;
        
        if(this.ff.questions.find(q => q.uuid == this.questao.uuid)){
          this.ff.questions.find(q => q.uuid == this.questao.uuid).picture = null;
        }
        this.toastr.success("Imagem removida com sucesso!");
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
      }
    )
  }

}
