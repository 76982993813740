import { Component, OnInit } from '@angular/core';
import { FatoFake } from 'src/app/dto/fatoFake';
import { NavigationExtras, Router } from '@angular/router';
import { FatoFakeService } from 'src/app/services/fato-fake.service';
import { Theme } from 'src/app/dto/theme';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-fato-fake-list',
  templateUrl: './fato-fake-list.component.html',
  styleUrls: ['./fato-fake-list.component.css']
})
export class FatoFakeListComponent implements OnInit {

  fatoFakes: FatoFake[]
  themes: Theme[]
  name: string = ""
  code: string = ""
  theme: string = ""
  textoBuscar: string = "Buscar"
  classeBuscar: string = "button is-primary"
  
  constructor(private fatoFakeService: FatoFakeService, private themeService: ThemeService, private router: Router){
  }

  edit(fatoFakeEdit: FatoFake){
    let navigationExtras: NavigationExtras
    navigationExtras = {
      state: {
        fatoFake: fatoFakeEdit
      }
    }
    this.router.navigate(["/factFake/edit/" + fatoFakeEdit.uuid], navigationExtras)
  }

  buscar(){
    this.textoBuscar = "Buscando..."
    this.classeBuscar = "button is-light"
    
    this.fatoFakeService.buscar(this.name, this.theme, this.code, (erro: any, listaFatoFake: FatoFake[]) => {
      if(listaFatoFake){
        this.fatoFakes = listaFatoFake
        this.textoBuscar = "Buscar"
        this.classeBuscar = "button is-primary"
      }
    })
  }

  ngOnInit(): void {
    this.name = ""
    this.themeService.themes(null, (erro: any, temasRetornados: Theme[]) => {
      this.themes = temasRetornados
    })
    this.buscar()
  }

}
