import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Estilo } from 'src/app/dto/estilo';

@Component({
  selector: 'app-imagem-fundo',
  templateUrl: './imagem-fundo.component.html',
  styleUrls: ['./imagem-fundo.component.css']
})
export class ImagemFundoComponent {
  @Input() type = "";
  @Input() arquivo: File;

  @Output() onChange = new EventEmitter<File>();
  
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) { 
        this.onChange.emit(event.target.files[0]);
    }
}
  
}
