import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Erro400 } from 'src/app/dto/erros';
import { CreateParticipante, Participante } from 'src/app/dto/participante';
import { Unidade } from 'src/app/dto/unidade';
import { ParticipanteService } from 'src/app/services/participante.service';
import { UnidadeService } from 'src/app/services/unidade.service';

import { types } from 'src/app/views/participante-form/ParticipantTypes';


@Component({
    selector: 'app-participante-form',
    templateUrl: './participante-form.component.html',
    styleUrls: ['./participante-form.component.css']
  })
export class ParticipanteForm implements OnInit{
  participante: CreateParticipante;
  unidades: Array<Unidade>;
  types = types;
  accounted: string;
  unidadeSelecionada: Unidade = null;
  subareaSelecionada: Unidade = null;
  nivel3Selecionado: Unidade = null;
  exibirSubarea: boolean = false;
  exibirNivel3: boolean = false;
  
  constructor(
    private participanteService: ParticipanteService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private unidadeService: UnidadeService) {}

  ngOnInit(): void {
    this.participante = new CreateParticipante();
    var uuid = this.route.snapshot.paramMap.get("uuid");
    this.participanteService.get(uuid, (erro: Erro400, sucesso: Participante) => {
      if(sucesso){
        this.participante = new CreateParticipante(sucesso);
        this.accounted = this.participante.accounted == true ? "true" : "false";
        this.carregarUnidades(this.participante.clientUuid);
        console.log('participante',this.participante);
      }else if(erro && erro.error){
        this.toastr.error('', erro.error.message);
      }
    })
  }

  cadastrarParticipante(){
    this.spinner.show();
    if(this.participante.uuid){
      let finalAccounted = this.accounted === 'true' ? true : false;
      this.participante.accounted = finalAccounted
      this.participanteService.editar(this.participante, (erro: Erro400, sucesso: Participante) => {
        if(sucesso){
          this.toastr.success('', 'Participante editado com sucesso');
          this.router.navigate(['/participants']);
        }else if(erro && erro.error){
          this.toastr.error('', erro.error.message);
        }
        this.spinner.hide();
      });
    }
  }

  carregarUnidades(uuid: string){
    this.unidadeService.buscar(uuid,null, null, true, (erro: Erro400, sucesso: Array<Unidade>) => {
      if(sucesso){
        this.unidades = sucesso;
        this.setUnidadeSelecionada(this.participante.unit,false);
        this.setSubareaSelecionada(this.participante.subUnit, false)
        this.setNivel3Selecionado(this.participante.level3)
      }
      else if(erro && erro.error){
        this.toastr.error('', erro.error.message);
      }
    });
  }
  setUnidadeSelecionada(uuidUnidade: string, isSelect){
      if(isSelect){
        this.participante.subUnit = null;
        this.participante.level3 = null;
        this.subareaSelecionada = null;
        this.nivel3Selecionado = null;
      }
      this.unidadeSelecionada = this.unidades.find(unidade => unidade.uuid === uuidUnidade);
      this.exibirSubarea = this.unidadeSelecionada.subUnits.length > 0;
      this.exibirNivel3 = false
  }

  setSubareaSelecionada(uuidSubarea: string, isSelect){
    this.exibirNivel3 = false
    if(isSelect){
      this.participante.level3 = null;
      this.nivel3Selecionado = null;
    }
    this.subareaSelecionada = this.unidadeSelecionada.subUnits.find(subUnit => subUnit.uuid === uuidSubarea);
    if(this.subareaSelecionada == null) return
    this.exibirNivel3 = this.subareaSelecionada.subUnits.length > 0;
  }

  setNivel3Selecionado(uuidNivel3: string){
    if(this.subareaSelecionada == null) return
    this.nivel3Selecionado = this.subareaSelecionada.subUnits.find(nivel3 => nivel3.uuid === uuidNivel3);
  }
}
