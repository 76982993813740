import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Client } from 'src/app/dto/client';
import { Erro400 } from 'src/app/dto/erros';
import { Template, TemplateCopyDTO, TemplateCor, TemplateDTO, TemplateSearch, TemplateSombra } from 'src/app/dto/template';
import { ClientsService } from 'src/app/services/clients.service';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.css']
})
export class TemplateFormComponent implements OnInit {

  templateCopy: TemplateCopyDTO;
  templates: Template[];
  search: TemplateSearch;
  template: TemplateDTO;
  newStandard: string;
  cor: TemplateCor;
  SombraPrimaria: TemplateSombra;
  SombraSecundaria: TemplateSombra;
  clients: Array<Client>;

  templateForm: FormGroup;
  colors: FormArray;
  shadows: FormArray;
  hexToRgbFormGroup: string;

  showModal: boolean;
  showModalCopy: boolean;
  nomeNovoTemplate: string;
  templatePadraoMessage: boolean;
  templateUsadoCampanha: boolean;
  cliente: Client;

  constructor(
    private formBuilder: FormBuilder,
    private templateService: TemplateService,
    private clientService: ClientsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.templates = new Array<Template>();
    this.search = new TemplateSearch();
    this.showModal = false;
    this.templatePadraoMessage = false;
    this.templateUsadoCampanha = false;

    this.templateForm = this.formBuilder.group({
      name: '',
      description: '',
      client: null,
      standard: Boolean,
      active: Boolean,
      colors: this.formBuilder.array([ 
        this.formBuilder.group({
          code: 'fundoPrimario',
          color: ''
        }),
        this.formBuilder.group({
          code: 'fundoSecundario',
          color: ''
        }),
        this.formBuilder.group({
          code: 'fundoTres',
          color: ''
        }),
        this.formBuilder.group({
          code: 'textosCorFundoPrimario',
          color: ''
        }),
        this.formBuilder.group({
          code: 'textosCorFundoSecundario',
          color: ''
        }),
        this.formBuilder.group({
          code: 'cardLogadoFundo',
          color: ''
        }),
        this.formBuilder.group({
          code: 'cardLogadoTextoPrimeiro',
          color: ''
        }),
        this.formBuilder.group({
          code: 'cardLogadoTextoSegundo',
          color: ''
        }),
        this.formBuilder.group({
          code: 'cardLogadoTextoTerceiro',
          color: ''
        }),
       ]),
       shadows: this.formBuilder.array([ 
        this.formBuilder.group({
          code: 'sombraPrimaria',
          bgColor: '#C4C4C4',
          boxShadow: '0px 20px 40px -10px rgba(254, 0, 26, 0.4)'
        }),
        this.formBuilder.group({
          code: 'sombraSecundaria',
          bgColor: '#C4C4C4',
          boxShadow: '0px 20px 40px -10px rgba(0, 0, 0, 0.25)'
        }),

       ])
    });

    this.changeBoxShadowColorFormGroup();

    this.template = new TemplateDTO();
    this.newStandard = null;

    this.resetaTela('rota');
    this.clientService.buscar(null, null, 0, 10000000, (erro: Erro400, sucesso: Array<Client>) => {
      if(sucesso){
        this.clients = sucesso;
      }
      if(erro && erro.error){
        this.toastr.error("", erro.error.message);
      }
    })
  }

  resetaTela(origem: string){
    this.template = new TemplateDTO();
 
    if(origem && origem == 'rota'){
      var uuid = this.route.snapshot.paramMap.get("uuid");
      if(uuid){
        this.template = new TemplateDTO(history.state.template);
        this.template.client = history.state.template.client.uuid;
      }
    }
  }

  changeBoxShadowColorFormGroup(): void {
    this.templateForm.get('colors').valueChanges.subscribe(value => {
      value.map(item => {
        if(item.code !== 'fundoSecundario') {
          return
        }
        let rgb = this.hexToRGB(item.color)
        this.shadows = this.templateForm.get('shadows') as FormArray;
        this.shadows.at(0).patchValue({
          code: 'sombraPrimaria',
          bgColor: '#C4C4C4',
          boxShadow: `0px 20px 40px -10px rgba(${rgb} 0.4)`
        })

      });
    })

  }
  
  changeBoxShadowColor(code: string, hexColor: any) {
    if(code !== 'fundoSecundario') {
      return
    }

    let rgb = this.hexToRGB(hexColor)

    this.template.shadows.map(s =>{
      if(s.code !== 'sombraPrimaria'){
        return
      }
      s.boxShadow = `0px 20px 40px -10px rgba(${rgb} 0.4)`
    });

  }

  hexToRGB(hexColor){
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);

    let hexToRGB = result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;

    return hexToRGB.r + "," + hexToRGB.g + "," + hexToRGB.b + ","
  }

  criarTemplate(){
    this.spinner.show();
    this.template = this.templateForm.value;
    if(this.templateForm.value.client != null) {  
      this.cliente = new Client();
      this.cliente.uuid = this.templateForm.value.client === "" ? null :this.templateForm.value.client;
    }
    this.template.client = this.cliente
    

    this.templateService.criar(this.template, (erro: Erro400, sucesso: TemplateDTO) => {
      if(sucesso){
        this.template = new TemplateDTO(sucesso);
        this.toastr.success("", "Template cadastrado com sucesso!")
      }
      if(erro){
        if(erro.error && erro.error.message){
          this.toastr.error("", erro.error.message);
        } else {
          this.toastr.error("", "Falha ao cadastrar template, entre em contato com o suporte ou tente novamente mais tarde");
        }
      }
      this.spinner.hide();
    });

  }

  editarTemplate(string){
    // this.spinner.show();
    this.cliente = new Client();
    this.cliente.uuid = this.template.clientUuid
    this.template.client = this.cliente
    
    this.templateService.editar(this.template, (erro: Erro400, sucesso: TemplateDTO) => {
      if(sucesso){
        this.template = new TemplateDTO(sucesso);
        this.toastr.success("", "Template atualizado!")
        if(string === 'editar-voltar'){
          this.router.navigate(['templates'])
        }
      }
      if(erro){
        if(erro.error && erro.error.message){
          this.toastr.error("", erro.error.message);
        } else {
          this.toastr.error("", "Falha ao atualizar template, entre em contato com o suporte ou tente novamente mais tarde");
        }
      }
      this.spinner.hide();
    });

  }

  apagarTemplate(){
    if(!confirm("Tem certeza que deseja excluir o template " + this.template.name +"?")){
      return;
    }
    this.spinner.show();
    this.templateService.apagar(this.template.uuid, this.newStandard, (erro: Erro400, sucesso: any) => {
      if(erro){
        if(erro.error && erro.error.message) {
          if(erro.error.message === "Escolha um novo template para ser substituído nas campanhas."){
            this.templatePadraoMessage = false;
            this.templateUsadoCampanha = true;
            this.showModal = true;
            this.showAllTemplates();
          } else if(erro.error.message === "O novo template padrão não foi enviado."){
            this.templatePadraoMessage = true;
            this.templateUsadoCampanha = false;
            this.showModal = true;
            this.showAllTemplates();
          }
        }
      } else {
        this.toastr.success("", "O Template foi removido com sucesso!");
        this.router.navigate(['templates'])
      }
      this.spinner.hide();
    })
  }

  clonarTemplate() {
    if (!confirm('Ao clicar em confirmar será gerada um novo template com os mesmos dados do template selecionado.\n Deseja prosseguir?')) {
      return;
    }

    this.spinner.show();
    const clone = new TemplateCopyDTO();
    clone.name = this.nomeNovoTemplate;
    this.templateService.clonarTemplate(this.template.uuid, clone, (erro: Erro400, sucesso: TemplateDTO) => {
      if(sucesso) {
        this.template = new TemplateDTO(sucesso);
        this.toastr.success("", "Template copiado com sucesso!")
        this.fecharModalCopiarTemplate()
        this.irParaEdicaoCloneTemplate(this.template)
        // this.router.navigate(['/templates/edit/' + sucesso.uuid]);
      }
      if(erro) {
        if(erro.error && erro.error.message){
          this.toastr.error("", erro.error.message);
        } else {
          this.toastr.error("", "Falha ao copiar template, entre em contato com o suporte ou tente novamente mais tarde");
        }
      }
      this.spinner.hide();
    });
  }

  irParaEdicaoCloneTemplate(template: TemplateDTO) {
    let navigationExtras: NavigationExtras
    navigationExtras = {
      state: {
        template: template
      }
    }
    this.router.navigate(['/templates/edit/' + template.uuid], navigationExtras);
  }

  abrirModalCopiarTemplate() {
    this.showModalCopy = true
  }

  fecharModalCopiarTemplate() {
    this.showModalCopy = false
  }

  modalFechar(){
    this.showModal = false;
  }

  showAllTemplates(){
    this.search.page = 0;
    this.search.client = 'todos';
    this.templates = new Array<Template>();

    this.templateService.buscar(this.search, (erro, sucesso)=>{

      if(sucesso){
        this.templates = sucesso;
      }
      if(erro){
        console.log(erro)
      }
    })
  }

}
