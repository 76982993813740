import { Component, EventEmitter, Host, Input, OnInit, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Erro400 } from "src/app/dto/erros";
import { EventDto } from "src/app/dto/event";
import { MensagemDTO } from "src/app/dto/mensagem";
import { MensagemService } from "src/app/services/mensagem.service";
import { MensagemListComponent } from "../mensagem-list/mensagem-list.component";

@Component({
    selector: 'app-mensagem-form',
    templateUrl: './mensagem-form.component.html',
    styleUrls: ['./mensagem-form.component.css']
  })
export class MensagemFormComponent implements OnInit{
    pai: MensagemListComponent

    @Input() evento: EventDto;
    @Input() mensagens: Array<MensagemDTO>;
    @Input() mensagem: MensagemDTO;
    @Output() retornarParaListagem = new EventEmitter();


    codigos = [
                {'code': 'registro.inscrevase', 'description': 'Inscreva-se'},
                {'code': 'registro.saudacao', 'description': 'Saudação no registro'},
                {'code': 'registro.instrucaoRegistro', 'description': 'É o texto instrutivo em campanhas diferentes do tipo elegibilidade guiando o usuário para que crie sua conta na Weex.'},
                {'code': 'registro.action.registre', 'description': 'Texto da aba de registro da página de registro'},
                {'code': 'registro.alt.iconeInscricao', 'description': 'Texto a ser exposto no alt do ícone de inscrição (invisível)'},
                {'code': 'registro.action.login', 'description': 'Texto da aba de autenticação da página de registro'},
                {'code': 'registro.erro.legal.titulo', 'description': 'Título do erro quando não se aceita os termos do Weex.'},
                {'code': 'registro.erro.legal.mensagem', 'description': 'Mensagem do erro quando não se aceita os termos do Weex.'},
                {'code': 'registro.erro.nascimento.titulo', 'description': 'Título do erro quando data de nascimento é inválida.'},
                {'code': 'registro.erro.nascimento.mensagem', 'description': 'Mensagem do erro quando data de nascimento é inválida.'},
                {'code': 'registro.erro.nascimento18.mensagem', 'description': 'Mensagem do erro quando data de nascimento é inválida e menor que 18.'},
                {'code': 'registro.erro.nascimento13.mensagem', 'description': 'Mensagem do erro quando data de nascimento é inválida e menor que 13.'},
                {'code': 'registro.erro.nascimentomes.mensagem', 'description': 'Mensagem do erro quando o mês da data de nascimento é inválido (< 1 ou > 12).'},
                {'code': 'registro.erro.nascimentodia.mensagem', 'description': 'Mensagem do erro quando o dia do nascimento é inválido.'},
                {'code': 'registro.erro.idade.titulo', 'description': 'Título do erro no caso da idade inválida.'},
                {'code': 'registro.erro.idade.menor13', 'description': 'Mensagem para quando menor que 13 anos.'},
                {'code': 'registro.erro.idade.menor18', 'description': 'Mensagem para quando menor que 18 anos.'},
                {'code': 'registro.erro.senhas.titulo', 'description': 'Título do erro no caso de senhas que não conferem.'},
                {'code': 'registro.erro.senhas.mensagem', 'description': 'Mensagem do erro no caso de senhas que não conferem.'},
                {'code': 'registro.erro.inscricao.titulo', 'description':'Título da mensagem de erro caso ocorra erro durante a inscrição'},
                {'code': 'geral.erro.network.mensagem', 'description':'Mensagem de erro caso ocorra problemas de rede durante a inscrição'},
                {'code': 'geral.erro.network.titulo', 'description':'Título da mensagem de erro em caso de erro na rede.'},
                {'code': 'registro.erro.aposInscricao.titulo', 'description':'Título caso a inscrição do participante requeira validação posterior.'},
                {'code': 'registro.erro.recaptcha.titulo', 'description':'Título do erro de validação por recaptcha.'},
                {'code': 'registro.erro.recaptcha.mensagem', 'description':'Mensagem do erro de validação por recaptcha.'},
                {'code': 'registro.campo.nome', 'description':'Label do campo Nome.'},
                {'code': 'registro.campo.sobrenome', 'description':'Label do campo Sobrenome.'},
                {'code': 'registro.campo.email', 'description':'Label do campo E-mail.'},
                {'code': 'registro.campo.nascimento', 'description':'Label do campo Nascimento.'},
                {'code': 'registro.campo.idade', 'description':'Label do campo Idade.'},
                {'code': 'registro.campo.area', 'description':'Label do campo Área.'},
                {'code': 'registro.campo.senha', 'description':'Label do campo Senha.'},
                {'code': 'registro.campo.senha2', 'description':'Label do campo Confirmar senha.'},
                {'code': 'registro.legal.liaceito', 'description':'Label do (li e aceito)'},
                {'code': 'registro.legal.termos', 'description': 'Nome do termos e condições'},
                {'code': 'registro.legal.daweex', 'description': 'Pocessivo (da Weex)'},
                {'code': 'registro.criar', 'description': 'Texto do botão (Criar conta)'},
                {'code': 'geral.aguarde', 'description': "Texto de (Aguarde...)"},
                {'code': 'registro.resetsenha', 'description': "Texto do reset de senha (Esqueci minha senha)"},
                {'code': 'login.erro.denied.titulo', 'description': "Título da mensagem de erro para acesso negado"},
                {'code': 'login.erro.denied.mensagem', 'description': "Mensagem de erro quando o acesso for negado na autenticação"},
                {'code': 'login.entrar', 'description': "Texto do botão de login (Entrar)"},
                {'code': 'aceiteTermos.condicao', 'description': "Condição para o aceite dos termos e condições"},
                {'code': 'aceiteTermos.acao', 'description': "Texto do botão de aceite de termos e condições"},
                {'code': 'ativarParticipante.erro.prefixo', 'description': "Prefixo do erro ao ativar participante"},
                {'code': 'atividades.erro.video.prefixo', 'description': "Prefixo do erro ao buscar um vídeo na página de atividades"},
                {'code': 'feedback.acao', 'description': 'Texto do botão que leva à página de feedback'},
                {'code': 'dinamica.video.nome', 'description': 'Nome dado às dinâmicas do tipo VIDEO'},
                {'code': 'dinamica.game.nome', 'description': 'Nome dado às dinâmicas do tipo GAME'},
                {'code': 'dinamica.fatofake.nome', 'description': 'Nome dado às dinâmicas do tipo FATO OU FAKE'},
                {'code': 'dinamica.novoshabitos.nome', 'description': 'Nome dado às dinâmicas do tipo NOVOS HÁBITOS'},
                {'code': 'dinamica.video.ajuda', 'description': 'Ajuda às dinâmicas do tipo VIDEO'},
                {'code': 'dinamica.game.ajuda', 'description': 'Ajuda às dinâmicas do tipo GAME'},
                {'code': 'dinamica.fatofake.ajuda', 'description': 'Ajuda às dinâmicas do tipo FATO OU FAKE'},
                {'code': 'dinamica.novoshabitos.ajuda', 'description': 'Ajuda às dinâmicas do tipo NOVOS HÁBITOS'},
                {'code': 'senha.alterada.sucesso', 'description':'Mensagem quando a senha é alterada com sucesso'},
                {'code': 'senha.alterar.titulo', 'description':"Título da página de troca de senha"},
                {'code': 'senha.alterar.instrucoes', 'description':"Instruções para a troca de senha"},
                {'code': 'senha.alterar.voltar', 'description':"Texto para o botão voltar da troca de senha"},
                {'code': 'geral.label.cliqueaqui', 'description':"Texto padrão para (clique aqui)"},
                {'code': 'senha.alterar.erro.titulo', 'description':"Título do erro de troca de senha"},
                {'code': 'senha.alterar.recuperar', 'description':"O texto do link de redirecionamento da senha (Se você esqueceu...)"},
                {'code': 'dinamica.erro.finalizar.titulo', 'description':"Título da mensagem de erro ao tentar finalizar atividade"},
                {'code': 'dinamica.erro.finalizar.mensagem', 'description':"Mensagem genérica informando que houve um erro ao finalizar atividade"},
                {'code': 'dinamica.erro.iniciar.titulo', 'description':"Título da mensagem de erro ao tentar iniciar atividade"},
                {'code': 'dinamica.erro.iniciar.mensagem', 'description':"Mensagem genérica informando que houve um erro ao iniciar atividade"},
                {'code': 'dinamica.erro.jaexecutada.titulo', 'description':"Título da mensagem de erro ao abrir atividade já executada"},
                {'code': 'dinamica.erro.jaexecutada.mensagem', 'description':"Mensagem genérica informando que houve um erro ao abrir atividade já executada"},
                {'code': 'geral.mes.1', 'description':"Janeiro"},
                {'code': 'geral.mes.2', 'description':"Fevereiro"},
                {'code': 'geral.mes.3', 'description':"Março"},
                {'code': 'geral.mes.4', 'description':"Abril"},
                {'code': 'geral.mes.5', 'description':"Maio"},
                {'code': 'geral.mes.6', 'description':"Junho"},
                {'code': 'geral.mes.7', 'description':"Julho"},
                {'code': 'geral.mes.8', 'description':"Agosto"},
                {'code': 'geral.mes.9', 'description':"Setembro"},
                {'code': 'geral.mes.10', 'description':"Outubro"},
                {'code': 'geral.mes.11', 'description':"Novembro"},
                {'code': 'geral.mes.12', 'description':"Dezembro"},
                {'code': 'geral.diasemana.DOM', 'description':"Domingo"},
                {'code': 'geral.diasemana.SEG', 'description':"Segunda"},
                {'code': 'geral.diasemana.TER', 'description':"Terça"},
                {'code': 'geral.diasemana.QUA', 'description':"Quarta"},
                {'code': 'geral.diasemana.QUI', 'description':"Quinta"},
                {'code': 'geral.diasemana.SEX', 'description':"Sexta"},
                {'code': 'geral.diasemana.SAB', 'description':"Sábado"},
                {'code': 'home.interesse.definir', 'description':"DEFINA SEUS INTERESSES (botão)"},
                {'code': 'home.datainicio', 'description':'Data de início da campanha'},
                {'code': 'home.instrucoes', 'description':'Instruções sobre o Weex'},
                {'code': 'missaoCumprida.pontuacao.perguntas.inicio', 'description':'O texto antes de mostrar questões acertadas'},
                {'code': 'missaoCumprida.pontuacao.perguntas.de', 'description':'Você acertou ( de ) perguntas'},
                {'code': 'missaoCumprida.pontuacao.perguntas.final', 'description':'Você acertou 34 (das perguntas)'},
                {'code': 'missaoCumprida.fim', 'description':'Aviso de missão cumprida'},
                {'code': 'missaoCumprida.parabens', 'description':'Mensagem de parabéns por ter cumprido ação'},
                {'code': 'missaoCumprida.trofeus', 'description':'Texto para sala de troféus'},
                {'code': 'missaoCumprida.repetir', 'description':'Texto para repetir ação'},
                {'code': 'resetsenha.envioemail', 'description':"Texto informando o envio do e-mail para restauro da senha"},
                {'code': 'resetsenha.erro', 'description':"Mensagem de erro caso ocorra algo de errado no restauro da senha"},
                {'code': 'resetsenha.titulo', 'description':"Título da página de restauro de senha"},
                {'code': 'resetsenha.instrucoes', 'description':"Instruções para o restauro de senha"},
                {'code': 'resetsenha.email', 'description':'Texto para o placeholder (email) no restauro de senha'},
                {'code': 'geral.cancelar', 'description':'Tradução para (Cancelar)'},
                {'code': 'geral.continuar', 'description':'Tradução para (Continuar)'},
                {'code': 'mensagemvideo.erro.iniciar.titulo', 'description':'Título da mensagem de erro ao iniciar mensagem de vídeo'},
                {'code': 'mensagemvideo.erro.iniciar.mensagem', 'description':'Mensagem de erro ao iniciar mensagem de vídeo'},
                {'code': 'mensagemvideo.erro.marcar.titulo', 'description':'Título da mensagem de erro ao marcar como assistida mensagem de vídeo'},
                {'code': 'mensagemvideo.erro.marcar.mensagem', 'description':'Mensagem de erro ao marcar como assistida mensagem de vídeo'},
                {'code': 'video.carregando', 'description':'Texto de loading do vídeo'},
                {'code': 'video.voltar', 'description':'Texto do botão (Voltar) do vídeo'},
                {'code': 'video.entendi', 'description':'Texto do botão (Concluir Atividade) do vídeo'},
                {'code': 'mensagemvideo.assistir', 'description':'O texto do botão (Assistir) da Mensagem de Vídeo'},
                {'code': 'dinamica.fatofake.fato', 'description':'O texto para (FATO!) no Fato ou Fake'},
                {'code': 'dinamica.fatofake.fake', 'description':'O texto para (FAKE!) no Fato ou Fake'},
                {'code': 'dinamica.fatofake.concluir', 'description':'O texto do botão (Concluir Atividade) do Fato ou Fake'},
                {'code': 'dinamica.fatofake.proxima', 'description':'O texto para (Avançar) no Fato ou Fake'},
                {'code': 'feedback.titulo1', 'description': 'Primeira linha de apresentação no feedback'},
                {'code': 'feedback.titulo2', 'description': 'Segunda linha de apresentação no feedback'},
                {'code': 'feedback.questao1', 'description': 'O conteúdo foi relevante?'},
                {'code': 'feedback.questao2', 'description': 'Você recomendaria para algum colega? '},
                {'code': 'feedback.questao3', 'description': 'Você participaria novamente?'},
                {'code': 'feedback.comentarios', 'description': 'Escreva seus comentários (opcional)'},
                {'code': 'geral.enviar', 'description': 'Tradução para (Enviar)'},
                {'code': 'dinamica.novoshabitos.farei', 'description': 'Tradução para (FAREI ISSO!)'},
                {'code': 'dinamica.novoshabitos.proximo', 'description': 'Tradução para (PRÓXIMO HÁBITO)'},
                {'code': 'dinamica.novoshabitos.comecar', 'description': 'Tradução para (COMEÇAR)'},
                {'code': 'dinamica.game.semjogo', 'description': 'Mensagem de erro caso o jogo não seja encontrado'},
                {'code': 'dinamica.game.jogar', 'description': 'Tradução para (JOGAR)'},
                {'code': 'interesse.selecioneInteresses.titulo', 'description': 'Tradução para (SELECIONE SEUS INTERESSES)'},
                {'code': 'interesse.selecioneAbaixo.subtitulo', 'description': 'Tradução para (Selecione abaixo até)'},
                {'code': 'interesse.sucesso.interesseAdicionado.titulo', 'description': 'Tradução para (Interesses adicionados)'},
                {'code': 'interesse.sucesso.interessesSalvos.mensagem', 'description': 'Tradução para (Os interesses foram salvos com sucesso)'},
                {'code': 'interesse.assuntosQueVoceMaisQuerSaber.subtitulo', 'description': 'Tradução para (temas de interesse para personalizar a sua experiência.)'},
                {'code': 'interesse.salvar', 'description': 'Tradução para o botão (SALVAR)'},
                {'code': 'interesse.salvando', 'description': 'Tradução para o botão (SALVANDO...)'},
                {'code': 'interesse.erro.aoAdicionarInteresse.titulo', 'description': 'Tradução para o botão (Erro ao adicionar interesse)'},
                {'code': 'interesse.erro.quantidadeDeInteresses.mensagem', 'description': 'Tradução para o botão (Você deve selecionar no máximo " (numero de interesses) " temas de interesse para continuar.)'},
                {'code': 'dinamica.extra.nome', 'description': 'Tradução para EXTRA'},
                {'code': 'dinamica.extra.ajuda', 'description': 'Tradução para a mensagem explicando o que é a dinamica extra'},
                {'code': 'dinamica.extra.iniciar', 'description': 'Tradução para INICIAR'},
                {'code': 'dinamica.extra.semConteudo', 'description': 'Tradução para (sem conteúdo)'},
                {'code': 'interesse.selecaoObrigatoria.mensagem', 'description': 'Tradução para (Seleção de interesses é obrigatória. Por favor, os selecione para continuar.)'},
                {'code': 'interesse.selecaoObrigatoria.mensagemConfirmaTitulo', 'description': 'Tradução para (Confirma seleção de interesses?)'},
                {'code': 'interesse.selecaoObrigatoria.mensagemConfirmaConteudo', 'description': 'Tradução para (Você não poderá alterar mais tarde seus interesses. Deseja Continuar?)'},
                {'code': 'geral.redirecionando', 'description': 'Tradução para (Redirecionando para o conteúdo... ou Aguarde...)'},
                {'code': 'geral.entendi', 'description': 'Tradução para o botão (ENTENDI!)'},
                {'code': 'registro.posseuiCadastro', 'description':'Já possuo cadastro'},
                {'code': 'registro.campo.dadosPessoais', 'description':'Dados Pessoais'},
                {'code': 'atividades.titulo', 'description':'Atividades'},
                {'code': 'atividades.subtitulo', 'description':'Clique no dia abaixo e realize as atividades disponíveis'},
                {'code': 'geral.agendaDiaBtnFechar', 'description':'Fechar'},
                {'code': 'dinamica.fatofake.acertou', 'description':'ACERTOU!'},
                {'code': 'dinamica.fatofake.errou', 'description':'OPS!'},
                {'code': 'redirecionamento.empresa', 'description':'Em qual empresa você trabalha?'},
                {'code': 'redirecionamento.empresaSelecao', 'description':'Selecione a sua empresa'},
                {'code': 'redirecionamento.campanha', 'description':'De qual campanha esta participando ?'},
                {'code': 'redirecionamento.campanhaSelecao', 'description':'Selecione uma campanha'},
                {'code': 'video.feedback.questao', 'description':'O que você achou desse vídeo?'},
                {'code': 'trofeus.titulo', 'description':'TROFÉUS'},
                {'code': 'trofeus.subtitulo', 'description':'Aqui você encontra todas as suas conquistas!'},
                {'code': 'feedback.obrigado', 'description':'OBRIGADO!'},
                {'code': 'feedback.opiniaoEnviada', 'description':'Sua opinião foi enviada com sucesso.'},
                {'code': 'geral.porcentagem.concluido', 'description':'Concluído'},
                {'code': 'video.aviso.botaodesbloquear', 'description':'Veja o vídeo até o final'},
                {'code': 'video.feedback.comentario', 'description':'Escreva sua dúvida ou comentário'},
                {'code': 'geral.menu.inicio', 'description':'Inicio'},
                {'code': 'geral.menu.comoFunciona', 'description':'Como funciona'},
                {'code': 'geral.menu.sair', 'description':"Sair"},
                {'code': 'geral.carregando.atividades', 'description':'Carregando atividades...'},
                {'code': 'dinamica.quiz.concluirAtividade', 'description':'CONCLUIR ATIVIDADE'},
                {'code': 'dinamica.quiz.proximaPergunta', 'description':'PRÓXIMA PERGUNTA'},
                {'code': 'dinamica.quiz.acertou', 'description':'ACERTOU!'},
                {'code': 'dinamica.quiz.errou', 'description':'OPS!'},
                {'code': 'dinamica.quiz.responderPergunta', 'description':'RESPONDER'},
                {'code': 'dinamica.mural.publicar', 'description':'Texto do botão publicar'},
                {'code': 'atividades.mural.acao.btn', 'description':'Texto do botão de acesso ao mural'},
                {'code': 'dinamica.mural.nome', 'description':'Defini o nome da dinâmica mural'},
                {'code': 'dinamica.mural.publicar.titulo', 'description':'Defini o titulo na pagina de publicação'},
                {'code': 'dinamica.mural.publica.upload', 'description':'Defini o nome do botão de upload da imagem mural'},
                {'code': 'dinamica.mural.comentar', 'description':'Defini o nome do botão em que clica para criar novo comentario'},
                {'code': 'dinamica.mural.comentar.titulo', 'description':'Defini o titulo da parte de publicar comentário'},
                {'code': 'dinamica.mural.publica.placeholder', 'description':'Defini o placeholder da parte de escrever uma publicação'},
                {'code': 'dinamica.mural.publicacao.excluir ', 'description':'Defini a mensagem para exclusão da publicação'},
                {'code': 'dinamica.mural.comentar.placeholder', 'description':'Defini o placeholder da parte de escrever um comentário'},
                {'code': 'dinamica.mural.comentario.excluir', 'description':'Defini a mensagem para exclusão do comentario'},
                {'code': 'dinamica.mural.comentario.dropdown', 'description':'Defini a mensagem referente ao dropdown de exclusão do comentario'},
                {'code': 'dinamica.mural.publicacao.dropdown', 'description':'Defini a mensagem referente ao dropdown de exclusão da publicação'},
                {'code': 'dinamica.mural.titulo.feed', 'description':'Defini o titulo para o feed do mural'},
                {'code': 'dinamica.mural.busca.placeholder', 'description':'Defini o placeholder do input de busca de publicações'},
                {'code': 'atividades.video.acao.btn', 'description':'ASSISTIR!'},
                {'code': 'atividades.fatoFale.acao.btn', 'description':'"COMEÇAR!'},
                {'code': 'atividades.newHabit.acao.btn', 'description':'"COMEÇAR!'},
                {'code': 'atividades.game.acao.btn', 'description':'JOGAR!'},
                {'code': 'atividades.extra.acao.btn', 'description':'ACESSAR!'},
                {'code': 'dinamica.mural.publicar.alert.texto', 'description':'Defini a mensagem de alerta caso texto esteja vazio'},
                {'code': 'atividades.atividadeDefault.acao.btn', 'description':'CONTINUAR!'},
                {'code': 'dinamica.mural.publicar.alert.imagem', 'description':'Defini a mensagem de alerta caso imagem seja maior que o tamanho máximoo'},
                {'code': 'dinamica.mural.publicacao.imagem.erro', 'description':'Defini a mensagem caso de erro ao tratar da imagem do mural'},
                {'code': 'dinamica.mural.publicacao.imagem.processando', 'description':'Defini a mensagem esteja processando'},
                {'code': 'atividades.atividadeDefault.acao.btn', 'description':'CONTINUAR!'},
                {'code': 'home.dataFim', 'description':'Defini a mensagem de pre-campanha: PARA COMEÇAR!'},
                {'code': 'interesse.escolha', 'description': 'Você escolheu:'},
                {'code': 'interesse.titulo.modal.jaEscolhido', 'description': 'Interesse já foi escolhido!'},
                {'code': 'select.unidade', 'description':'option para selecionar unidade'},
                {'code': 'select.subárea', 'description':'option para selecionar subunidade'},
                {'code': 'registro.campo.subarea', 'description':'label do select de subarea'},
                {'code': 'ranking.title', 'description': 'RANKING'},
                {'code': 'ranking.lideranca', 'description': 'Liderança'},
                {'code': 'trofeu.ranking.game', 'description': 'VER RANKING DO GAME'},
                {'code': 'geral.voltar', 'description': 'Botão de voltar'},
                {'code': 'mensagemvideo.campanha', 'description': 'Mensagem sem video'},
                {'code': 'ranking.game.naoParticipouUm', 'description': 'primeira frase para os participantes que ainda não participaram do game'},
                {'code': 'ranking.game.naoParticipouDois', 'description': 'segunda frase para os participantes que ainda não participaram do game'},
                {'code': 'geral.menu.usuario', 'description': 'Usuário'},
                {'code': 'info.pontuacao', 'description': 'Mensagem caso o usuario não esteja no ranking'},
                {'code': 'selecione.game', 'description': 'Label do select do game'},
                {'code': 'registro.erro.campo.invalido', 'description': 'Mensagem de erro para campos inválidos'},
                {'code': 'registro.erro.unidade', 'description': 'Mensagem de erro para unidade obrigatoria'},
                {'code': 'registro.erro.subunidade', 'description': 'Mensagem de erro para subunidade obrigatoria'},
                {'code': 'missaoCumprida.game.ranking', 'description': 'Texto para o ranking do game' },
                {'code': 'game.jogar.desabilitado', 'description': 'Texto quando game desabilitado' },
                {'code': 'game.jogar.habilitado', 'description': 'Texto quando game habilitado' },
                {'code': 'arial.mural.publicar.video.icone', 'description': 'Ícone de um vídeo' },
                {'code': 'dinamica.mural.publica.video', 'description': 'Selecione uma url para anexar um vídeo' },
                {'code': 'label.mural.publicar.remover', 'description': 'Remover imagem ou video' },
                {'code':'dinamica.mural.publicar.video.link', 'description' : 'Cole aqui o link para o vídeo (YouTube/Vimeo)'},
                {'code':'dinamica.mural.publicar.video.linkInvalido', 'description' : 'Somente links do YouTube ou Vimeo são aceitos na plataforma'},
                {'code':'dinamica.mural.publicar.video.titleAnexar', 'description' : 'Anexar vídeo'},
                {'code':'aria.label.mural.videoPublicado', 'description' : 'Vídeo publicado'},
                {'code': 'aria.redirecionamento.empresaDescricao', 'description': 'Descrição da empresa que será exibida no redirecionamento.'},
                {'code': 'aria.redirecionamento.campanhaSelecaoDescricao', 'description': 'Descrição da campanha que será exibida no redirecionamento.'},
                {'code': 'aria.label.campo.first.name', 'description': 'Campo de entrada para o primeiro nome do usuário (aria-label)'},
                {'code': 'aria.label.campo.last.name', 'description': 'Campo de entrada para o sobrenome do usuário (aria-label)'},
                {'code': 'aria.label.campo.email', 'description': 'Campo de entrada para o email do usuário (aria-label)'},
                {'code': 'aria.label.campo.password', 'description': 'Campo de entrada para a senha do usuário (aria-label)'},
                {'code': 'aria.label.campo.password.confirm', 'description': 'Campo de confirmação de senha do usuário (aria-label)'},
                {'code': 'aria.label.campo.bithday', 'description': 'Campo de entrada para a data de nascimento do usuário (aria-label)'},
                {'code': 'aria.label.campo.idade', 'description': 'Campo de entrada para a idade do usuário (aria-label)'},
                {'code': 'aria.label.campo.enrollment', 'description': 'Campo de entrada para a matrícula do usuário (aria-label)'},
                {'code': 'aria.label.campo.registro.legal.termos', 'description': 'Campo de aceite dos termos e condições (aria-label)'},
                {'code': 'aria.label.campo.subunidade', 'description': 'Campo de escolha da subUnidade (aria-label)'},
                {'code': 'aria.label.campo.unidade', 'description': 'Campo de escolha da unidade (aria-label)'},
                {'code': 'alt.button.imagem.fechar', 'description': 'ícone em formato de um X indicando o encerramento de uma ação. (alt)'},
                {'code': 'alt.imagem.apresentacao.atividade', 'description': 'Descrição alternativa da imagem de apresentação da atividade (alt)'},
                {'code': 'alt.trofeu.campaign', 'description': 'Descrição alternativa da imagem da taça de conclusão da campanha (alt)'},
                {'code': 'alt.trofeu.day.able', 'description': 'Descrição alternativa da imagem do troféu diário da campanha concluído (alt)'},
                {'code': 'alt.trofeu.day.disable', 'description': 'Descrição alternativa da imagem do troféu diário da campanha não concluído (alt)'},
                {'code': 'alt.trofeu.atividade.able', 'description': 'Descrição alternativa da imagem de troféu da atividade concluída (alt)'},
                {'code': 'alt.trofeu.atividade.disable', 'description': 'Descrição alternativa da imagem de troféu da atividade não concluída (alt)'},
                {'code': 'aria.label.video.opcoes.feedback', 'description': 'Opções de feedback sobre o vídeo (aria-label)'},
                {'code': 'arial.label.video.comentario', 'description': 'Campo de entrada para comentário sobre o vídeo (aria-label)'},
                {'code': 'alt.novoHabito.card', 'description': 'Imagem card novo hábito (alt)' },
                {'code': 'alt.modal.apresentacao', 'description': 'Imagem de apresentação da campanha (alt)' },
                {'code': 'alt.modal.apresentacao.thumbnail', 'description': 'Thumbnail da vídeo mensagem (alt)' },
                {'code': 'alt.logo.principal.desktop', 'description': 'Logo principal da empresa (alt)' },
                {'code': 'alt.logo.principal.mobile', 'description': 'Logo principal da empresa (alt)' },
                {'code': 'alt.logo.secundario.footer.mobile', 'description': 'Logo secundário da empresa (alt)' },
                {'code': 'alt.logo.secundario.footer.desktop', 'description': 'Logo secundário da empresa (alt)' },
                {'code': 'alt.menu.burguer.mobile', 'description': 'Menu suspenso de navegação (alt)' },
                {'code': 'alt.menu.sala.de.trofeus', 'description': 'Ícone com acesso para a sala de troféus (alt)' },
                {'code': 'aria.label.game.informacoes', 'description': 'Informações do jogo (aria-label)' },
                {'code': 'alt.redirecionamento.campanha.weex.logo', 'description': 'Logo da empresa fornecedora do serviço Weex (alt)' },
                {'code': 'alt.jogar.concluido', 'description': 'Jogo concluído (alt)' },
                {'code': 'alt.atividade.completou', 'description': 'Atividade completada (alt)' },
                {'code': 'alt.abrir.dinamicas', 'description': 'Abrir dinâmicas (alt)' },
                {'code': 'alt.fechar.dinamicas', 'description': 'Fechar dinâmicas (alt)' },
                {'code': 'alt.icone.atividades.todasAtividadesExecutadas.titulo', 'description': 'Título do ícone de todas as atividades executadas (alt)' },
                {'code': 'alt.icone.atividades.todasAtividadesExecutadas.descricao', 'description': 'Descrição do ícone de todas as atividades executadas (alt)' },
                {'code': 'aria.label.fechar.modal', 'description': 'Fecha a janela e volta para a página atividades. (alt)' },
                {'code': 'aria.labe.geral.mes.1', 'description': 'JANEIRO (aria-label)'},
                {'code': 'aria.labe.geral.mes.2', 'description': 'FEVEREIRO (aria-label)'},
                {'code': 'aria.labe.geral.mes.3', 'description': 'MARÇO (aria-label)'},
                {'code': 'aria.labe.geral.mes.4', 'description': 'ABRIL (aria-label)'},
                {'code': 'aria.labe.geral.mes.5', 'description': 'MAIO (aria-label)'},
                {'code': 'aria.labe.geral.mes.6', 'description': 'JUNHO (aria-label)'},
                {'code': 'aria.labe.geral.mes.7', 'description': 'JULHO (aria-label)'},
                {'code': 'aria.labe.geral.mes.8', 'description': 'AGOSTO (aria-label)'},
                {'code': 'aria.labe.geral.mes.9', 'description': 'SETEMBRO (aria-label)'},
                {'code': 'aria.labe.geral.mes.10', 'description': 'OUTUBRO (aria-label)'},
                {'code': 'aria.labe.geral.mes.11', 'description': 'NOVEMBRO (aria-label)'},
                {'code': 'aria.labe.geral.mes.12', 'description': 'DEZEMBRO (aria-label)'},
                {'code': 'aria.label.icone.busca.publicacao', 'description': 'Icone de buscar publicação (aria-label)'},
                {'code': 'aria.label.mural.button.comentario', 'description': 'Ao clicar abre o modal de comentários (aria-label)'},
                {'code': 'alt.dinamica.fatofake.fatoButtonImage', 'description': 'Imagem do botão fato (alt)'},
                {'code': 'alt.dinamica.fatofake.fakeButtonImage', 'description': 'Imagem do botão fake (alt)'},
                {'code': 'alt.feedback.positivo', 'description': 'Ícone de joinha positivo (alt)'},
                {'code': 'alt.feedback.negativo', 'description': 'Ícone de joinha negativo (alt)'},
                {'code': 'aria.label.feedback.comentario', 'description': 'campo para escrever um comentário (opcional).'},
                {'code': 'alt.feedback.imagem.obrigado', 'description': 'Imagem de fogos de artificio agradecendo por ter respondido ao feedback (alt)'},
                {'code': 'aria.label.mural.fixar.publicacao', 'description': 'Fixar publicação (aria-label)'},
                {'code': 'alt.mural.publicar.imagem.erro', 'description': 'Formato não suportado (alt)'},
                {'code': 'alt.dinamica.imagem.fatofake', 'description': 'Imagem ilustrativa das questões de fato ou fake (alt)'},
                {'code': 'alt.dinamica.icone.fatofake', 'description': 'Ícone de uma mão escolhendo entre o certo e o errado. (alt)'},
                {'code': 'alt.dinamica.novoshabitos', 'description': 'Ícone de uma cabeça com uma engrenagem dentro dando a entender que está pensando. (alt)'},
                {'code': 'alt.dinamica.video', 'description': 'Ícone de um player de vídeo (alt)'},
                {'code': 'alt.dinamica.extra', 'description': 'Ícone de uma mão clicando em um ponto especifíco da página (alt)'},
                {'code': 'alt.dinamica.game', 'description': 'Ícone de um controle de videogame (alt)'},
                {'code': 'alt.dinamica.mural', 'description': 'Ícon de balões de conversao estilo gibi (alt)'},
                {'code': 'arial.label.video.formulario', 'description': 'Formulário de feedback sobre o vídeo (aria-label)'},
                {'code': 'alt.menu.burguer.mobile.open', 'description': 'Menu suspenso, aberto. (alt)'},
                {'code': 'alt.menu.burguer.mobile.close', 'description': 'Menu suspenso, fechado. (alt)'},
                {'code': 'aria.hidden.feedback.selecionado.singular', 'description': 'estrela selecionada! (acessibilidade: diz ao usuário quantas estrelas ele selecionou no singular)'},
                {'code': 'aria.hidden.feedback.selecionado.plural', 'description': 'estrelas selecionadas! (acessibilidade: diz ao usuário quantas estrelas ele selecionou no plural. Ex: 3 estrelas selecionadas!)'},
                {'code': 'alt.feedback.estrela', 'description': 'Ícone de estrela (acessibilidade: faz parte da leitura de quantas estrelas tem para selecionar. Ex: Ícone de estrela 2 de 5.)'},
                {'code': 'alt.feedback.estrela.quantidade.total', 'description': 'de 5. (acessibilidade: complemento da frase acima).'},
                {'code': 'aria.hidden.feedback.positivo.selecionado', 'description': 'Ícone de joinha positivo selecionado! (acessibilidade: usuário consegue saber qual joinha foi selecionado)'},
                {'code': 'aria.hidden.feedback.negativo.selecionado', 'description': 'Ícone de joinha negativo selecionado! (acessibilidade: usuário consegue saber qual joinha foi selecionado)'},
                {'code': 'alt.feedback.positivo', 'description': 'Ícone de joinha positivo (acessibilidade: usuário consegue saber qual joinha tem para selecionar)'},
                {'code': 'alt.feedback.negativo', 'description': 'Ícone de joinha negativo (acessibilidade: usuário consegue saber qual joinha tem para selecionar)'},
                {'code': 'aria.label.feedback.escala', 'description': 'Em uma escala de 1 a 5. (acessibilidade: complemento das perguntas de feedback)'},
                {'code': 'aria.label.feedback.simNao', 'description': 'Clique em sim ou não. (acessibilidade: complemento das perguntas de feedback)'},
                {'code': 'alt.video.feedbackPositivo', 'description': 'feedback positivo'},
                {'code': 'alt.video.feedbackNegativo', 'description': 'feedback negativo'},
                {'code': 'alt.missaoCumprida.trofeu', 'description': 'Troféu '},
                {'code': 'aria.hidden.video.feedbackPositivo.selecionado', 'description': 'feedback positivo selecionado!'},
                {'code': 'aria.hidden.video.feedbackNegativo.selecionado', 'description': 'feedback negativo selecionado!'},
                {'code': 'aria.hidden.publicacao.mural.curtido', 'description': 'curtiu publicação!'},
                {'code': 'aria.hidden.publicacao.mural.descurtido', 'description': 'descurtiu publicação!'},
                {'code': 'aria.status.mensagemreutilizavel.botaosalvarclicado', 'description': 'Botão salvar clicado'},
                {'code': 'aria.status.cardnovoshabitos.finalizando', 'description': 'Botão farei isso clicado'},
                {'code': 'aria.status.cardrespostafatofake.concluir', 'description': 'Botão concluir atividade clicado'},
                {'code': 'aria.status.escreverpublicacao.publicar', 'description': 'Botão publicar clicado.'},
                {'code': 'aria.status.mensagemreutilizavel.botaovoltarclicado', 'description': 'Botão voltar clicado.'},
                {'code': 'aria.status.interessesconfirmacaomodal.confirmar', 'description': 'Botão confirmar clicado.'},
                {'code': 'aria.status.mensagemvideomodal.botaoassistir', 'description': 'Botão assistir clicado.'},
                {'code': 'aria.status.missaocompridamodal.botaomissaocumprida', 'description': 'Botão missao cumprida clicado.'},
                {'code': 'aria.status.modalcomentarios.botaocomentar', 'description': 'Botão comentar clicado.'},
                {'code': 'aria.status.modalexclusao.botaonao', 'description': 'Botão não clicado.'},
                {'code': 'aria.status.weexmodal.botaook', 'description': 'Botão ok clicado.'},
                {'code': 'aria.status.weexmodalatividades.botaoiniciarativida', 'description': 'Botão para iniciar atividade clicado.'},
                {'code': 'aria.status.acceptlegalterm.botaoaceitartermos', 'description': 'Botão aceitar termos clicado.'},
                {'code': 'aria.status.resetpassword.botaocontinuar', 'description': 'Botão continuar clicado'},
                {'code': 'aria.status.seletorcampanha.botaoredirecionar', 'description': 'Botão redirecionar clicado.'},
                {'code': 'aria.status.feedback.botaoenviar', 'description': 'Botão enviar clicado.'},
                {'code': 'aria.status.home.botaoconfiracomofunciona', 'description': 'Botao confira como funciona clicado.'},
                {'code': 'aria.status.mural.botaoescreverpublicacao', 'description': 'Botão escrever publicação clicado.'},
                {'code': 'aria.status.trofeus.botaoverrankinggame', 'description': 'Botão ver ranking game clicado.'},
                {'code': 'aria.status.weexmodalComRedirect.botao', 'description': 'Botão tentar novamente clicado.'},
                {'code': 'alt.dinamica.fatofake.icone.quaseLa', 'description': 'ícone em vermelho com um x no centro e raios ao redores para indicar atenção'},
                {'code': 'aria.label.mural.fechar.modal', 'description': 'Fecha a janela e volta para a página mural social.'},
                {'code': 'aria.label.mural.anexo.video.fechar.modal', 'description': 'Fecha a janela de publicar vídeo e volta para escrever publicação.'},
                {'code': 'aria.label.modal.generico.de.sucesso.erro.fechar.modal', 'description': 'Fecha a janela e volta para a página anterior.'},
                {'code': 'aria.label.geral.mensagem.voltar.atividades', 'description': 'Volta para a página de atividades.'},
                {'code': 'aria.label.geral.mensagem.voltar.espera', 'description': 'Volta para a página de espera da campanha.'},
                {'code': 'aria.label.geral.mensagem.voltar.trofeus', 'description': 'Volta para a página de troféus.'},
                {'code': 'aria.label.video.informacoes.gerais', 'description': 'Informações gerais sobre o vídeo com formulário para dizer se curtiu ou não o conteúdo e para escrever um comentário. Para desbloquear o botão de concluir atividade é necessário assistir o vídeo até o final. O player de vídeo encontra-se depois do botão de concluir atividade.'},
                {'code': 'aria.label.video.alert.botao.desbloqueado', 'description': 'Botão concluir atividade desbloqueado!'},
                {'code': 'extra.pdf.pagina', 'description': 'extra arquivo pdf "página"'},
                {'code': 'extra.pdf.pagina.de', 'description': 'extra arquivo pdf "de"'},
                {'code': 'extra.pagina.anterior', 'description': 'extra arquivo pdf pagina "Anterior"'},
                {'code': 'extra.pagina.proxima', 'description': 'extra arquivo pdf pagina "Próxima"'},
                {'code': 'extra.pdf.erro.carregar', 'description': 'extra arquivo pdf "Erro ao carregar o PDF"'},
                {'code': 'extra.pdf.botao.download', 'description': 'extra arquivo pdf "Baixar arquivo"'},
                {'code': 'aria.label.video.informacoes.gerais', 'description': 'Para desbloquear o botão de concluir atividade é necessário assistir o vídeo até o final.'},
                {'code': 'aria.label.video.alert.botao.desbloqueado', 'description': 'Botão concluir atividade desbloqueado!'},
                {'code': 'weexmodalatividadedia.botao.diaanterior', 'description': 'Dia Anterior'},
                {'code': 'arialabel.botao.weexmodalatividadedia.diaanterior', 'description': 'ir para o dia anterior'},
                {'code': 'weexmodalatividadedia.botao.proximodia', 'description': 'Próximo dia'},
                {'code': 'arialabel.botao.weexmodalatividadedia.proximodia', 'description': 'ir para o proximo dia'},
                {'code': 'extra.pdf.carregando', 'description': 'texto de carregamento do pdf'},
                {'code': 'dinamica.mural.anterior', 'description': ' Botão de páginação para exibir a página anterior do mural'},
                {'code': 'dinamica.mural.proximo', 'description': ' Botão de páginação para exibir a próxima página do mural'},
              ]


    constructor(private mensagemService: MensagemService, private toastr: ToastrService, @Host() parent: MensagemListComponent){
      this.pai = parent
    }

    ngOnInit(): void {
        this.codigos = this.codigos.sort(  (primeiro, segundo) =>  {
            if (primeiro.code > segundo.code) {
              return 1
            } else {
              return -1
            }
          }
        )
    }

    processando: boolean = false

    cadastrar(acao){
      if (this.processando) {
        return
      }

      if(!this.mensagem.code || !this.mensagem.language || !this.mensagem.message){
        this.toastr.error('', "É preciso preencher o código, idioma e mensagem");
        return
      }

      if(this.evento){
        this.mensagem.event = this.evento;
      }

      let corpoMensagem = [this.mensagem]

      this.processando = true
      this.mensagemService.save(corpoMensagem, (erro: Erro400, ms: Array<MensagemDTO>) => {
        this.processando = false
        if(ms){
          this.toastr.success('', 'A mensagem foi salva com sucesso');
          this.mensagens = ms;
          this.pai.retornarParaListagem(acao);
          if(acao === 'LISTA'){
            this.pai.formulario = false;

          }else if(acao === 'NOVO'){
            this.mensagem = new MensagemDTO();
          }
        }else if(erro && erro.error){
          this.toastr.error('', erro.error.message);
        }
      })
    }

}
