import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FatoFake, FatoFakeClone } from '../dto/fatoFake';
import { LoginService } from './login.service';
import { Erro400 } from '../dto/erros';

@Injectable({
  providedIn: 'root'
})
export class FatoFakeService {

  apiHost: string = environment.apiHost

  constructor(private http: HttpClient, private loginService: LoginService) { }

  buscar(name: string, theme: string, code: string, callback: Function) {

    let httpParams = new HttpParams()
    if (name) {
      httpParams = httpParams.set('name', name)
    }
    if (theme) {
      httpParams = httpParams.set('theme', theme)
    }
    if (code) {
      httpParams = httpParams.set('code', code)
    }

    this.http.get(this.apiHost + '/api/v1/factOrFake', { params: httpParams }).subscribe(
      (fatoFakes: FatoFake[]) => {
        callback(null, fatoFakes);
      },
      (erro: any) => {
        callback(erro, null);
      }

    );
  }

  public criar(ff: FatoFake, callback: Function){
    this.http.post(this.apiHost + '/api/v1/factOrFake', ff, {headers: this.loginService.authorizationHeader()}).subscribe(
        (sucesso: FatoFake) => {
            callback(null, sucesso);
        },
        (falha: Erro400) => {
            callback(falha, null);
        }
    );
  }

  public editar(ff: FatoFake, callback: Function){
    this.http.put(this.apiHost + '/api/v1/factOrFake/' + ff.uuid, ff, {headers: this.loginService.authorizationHeader()}).subscribe(
        (sucesso: FatoFake) => {
            callback(null, sucesso);
        },
        (falha: Erro400) => {
            callback(falha, null);
        }
    );
  }

  public enviarImagem(ffuuid: string, questionuuid: string, file: File, callback: Function){
    var formdata: FormData = new FormData();
    formdata.append('file', file);

    this.http.put(this.apiHost + '/api/v1/factOrFake/' + ffuuid + '/question/' + questionuuid + '/image', formdata, {headers: this.loginService.authorizationHeader()}).subscribe(
        (sucesso: Event) => {
            callback(null, sucesso);
        },
        (falha: Erro400) => {
            callback(falha, null);
        }
    );
  }
  public clonar(ffClone: FatoFakeClone, ffOriginalUuid: string, callback: Function){
    this.http.post(this.apiHost + '/api/v1/factOrFake/' + ffOriginalUuid+ '/clone', ffClone,
    {headers: this.loginService.authorizationHeader()})
    .subscribe(
      (sucesso: FatoFake) => {
        callback(null, sucesso);
      },
      (falha: Erro400) => {
          callback(falha, null);
      }
    );
  }
  public deleteQuestion(ffUuid: string, questionUuid: string, callback: Function){
    this.http.delete(`${this.apiHost}/api/v1/factOrFake/${ffUuid}/question/${questionUuid}`, {headers: this.loginService.authorizationHeader()} )
    .subscribe(
      (sucesso: FatoFake) => {
        callback(null, sucesso);
      },
      (falha: Erro400) => {
          callback(falha, null);
      }
    );
  }
}
