import { Component, OnInit } from '@angular/core';
import { Theme } from 'src/app/dto/theme';
import { ThemeService } from 'src/app/services/theme.service';
import { Router, NavigationExtras } from '@angular/router';
import { Erro } from 'src/app/dto/erros';

@Component({
  selector: 'app-themes-list',
  templateUrl: './themes-list.component.html',
  styleUrls: ['./themes-list.component.css']
})
export class ThemesListComponent implements OnInit {

  themes: Theme[]
  name: string
  textoBuscar: string = "Buscar"
  classeBuscar: string = "button is-primary"
  
  constructor(private themeService: ThemeService, private router: Router){
  }

  edit(themeEdit: Theme){
    let navigationExtras: NavigationExtras
    navigationExtras = {
      state: {
        theme: themeEdit
      }
    }
    this.router.navigate(["/themes/edit/" + themeEdit.uuid], navigationExtras)
  }

  buscar(){
    this.textoBuscar = "Buscando..."
    this.classeBuscar = "button is-light"
    
    this.themeService.themes(this.name, (error: any, themesList: Theme[]) => {
      if(themesList){
        this.themes = themesList
        this.textoBuscar = "Buscar"
        this.classeBuscar = "button is-primary"
      }
    })
  }

  ngOnInit(): void {
    this.name = ""
    this.buscar()
  }

}
