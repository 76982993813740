import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Erro400 } from 'src/app/dto/erros';
import { EventDto,Event } from 'src/app/dto/event';
import { TipoImagem } from 'src/app/dto/evento/tipoImagem';
import { EventService } from 'src/app/services/evento.service';

@Component({
  selector: 'app-upload-imagem',
  templateUrl: './upload-imagem.component.html',
  styleUrls: ['./upload-imagem.component.css']
})
export class UploadImagemComponent implements OnInit {
  public arquivoUpload: File;

  nomeArquivo: string;

  evento: EventDto;

  @Input()
  eventoRecebido: EventDto;

  @Input()
  titulo: string;

  @Input()
  tipo: string;

  @Input()
  hasImagem: boolean = false;

  @Input()
  imagem: string;

  @Output()
  onImagemAlterada = new EventEmitter<EventDto>();



  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private eventoService: EventService,

  ) { }

  ngOnInit() {
  }

  enviarImagem() {
    let arquivo: File = null;
    if (this.arquivoUpload) {
      arquivo = this.arquivoUpload;
    }
    this.evento = this.eventoRecebido;
    this.spinner.show();
    if (Object.values(TipoImagem).includes(this.tipo as any) ) {
      this.eventoService.enviarImagem(this.evento.uuid, this.tipo, arquivo, (erro: Erro400, sucesso: Event) => {
        if (sucesso) {
          this.evento = new EventDto(sucesso);
          this.toastr.success('', 'Arquivo enviado com sucesso');
          this.onImagemAlterada.emit(this.evento);
        } else if (erro && erro.error) {
          this.toastr.error('', erro.error.message);
        } else {
          this.toastr.error('', 'Aconteceu um erro ao tentar remover a imagem, por favor, contate o responsável pelo sistema');
        }
        this.spinner.hide();
      });
    } else if (this.tipo == 'trophyEnabled') {
      this.eventoService.enviarTrofeu(this.evento.uuid, 'EVENT', arquivo, (erro: Erro400, sucesso: Event) => {
        if (sucesso) {
          this.evento = new EventDto(sucesso);
          this.toastr.success('', 'Arquivo enviado com sucesso');
          this.onImagemAlterada.emit(this.evento);
        } else if (erro && erro.error) {
          this.toastr.error('', erro.error.message);
        } else {
          this.toastr.error('', 'Aconteceu um erro ao tentar remover a imagem, por favor, contate o responsável pelo sistema');
        }
        this.spinner.hide();
      });
    } else if (this.tipo == 'trophyDisabled') {
      this.eventoService.enviarTrofeu(this.evento.uuid, 'EVENT_DISABLED', arquivo, (erro: Erro400, sucesso: Event) => {
        if (sucesso) {
          this.evento = new EventDto(sucesso);
          this.toastr.success('', 'Arquivo enviado com sucesso');
          this.onImagemAlterada.emit(this.evento);
        } else if (erro && erro.error) {
          this.toastr.error('', erro.error.message);
        } else {
          this.toastr.error('', 'Aconteceu um erro ao tentar remover a imagem, por favor, contate o responsável pelo sistema');
        }
        this.spinner.hide();
      });
    }
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.arquivoUpload = event.target.files[0];
      this.nomeArquivo = this.arquivoUpload.name;
    }
  }

  remover() {
    this.spinner.show();
    this.evento = this.eventoRecebido;
    this.eventoService.removerImagem(this.evento.uuid, this.tipo, (erro: Erro400, sucesso: Event) => {
      if (sucesso) {
        this.evento = new EventDto(sucesso);
        this.toastr.success('', 'Arquivo removido com sucesso');
        this.onImagemAlterada.emit(this.evento);
      } else if (erro && erro.error) {
        this.toastr.error('', erro.error.message);
      } else {
        this.toastr.error('', 'Aconteceu um erro ao tentar remover a imagem, por favor, contate o responsável pelo sistema');
      }
      this.spinner.hide();
    });
  }

}
