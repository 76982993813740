import { Theme } from 'src/app/dto/theme';
export class Mural {
    uuid: string;
    name: string;
    code: string;
    presentation: string;
    description: string;
    title: string;
    active: boolean;
    picture: string;
    theme: Theme;
    constructor(mural: Mural = null) {
        if (mural){
            this.uuid = mural.uuid;
            this.name = mural.name;
            this.code = mural.code;
            this.presentation = mural.presentation;
            this.description = mural.description;
            this.title = mural.title;
            this.active = mural.active;
            this.theme = mural.theme;
            this.picture = mural.picture;

        }else{
            this.theme = new Theme();
            this.active = false;
            this.picture = "";
        }
    }
    checked(){
      if(this.active){
          return "checked";
      }else{
          return "";
      }
  }
}
