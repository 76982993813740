import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Frontend } from '../dto/frontend';
import { Erro400 } from '../dto/erros';
import { LoginService } from './login.service';


@Injectable({
  providedIn: 'root'
})
export class FrontendService {
  apiHost: string = environment.apiHost

  constructor(private http: HttpClient, private loginService: LoginService) { }

  public buscarVersaoFrontend(callback: Function) {
    this.http.get(this.apiHost + '/api/v1/event/frontend', {headers: this.loginService.authorizationHeader()}).subscribe(
      (sucesso: Frontend) => {
        callback(null, sucesso);
      },
      (falha: Erro400) => {
        callback(falha, null);
      }
    );
  }
}
