import { Client } from './client';

export class Unidade{
    uuid: string;
    name: string;
    active: boolean;
    client: Client;
    accounted: boolean;
    parent: string;
    subUnits: Array<Unidade>;

    constructor(unidade: Unidade = null){
        if(unidade){
            this.uuid = unidade.uuid;
            this.name = unidade.name;
            this.active = unidade.active;
            this.client = unidade.client;
            this.accounted = unidade.accounted;
            this.subUnits = unidade.subUnits;
            this.parent = unidade.parent
        }else{
            this.active = false;
        }
    }

    checked(){
        if(this.active){
            return "checked";
        }else{
            return "";
        }
    }
}
