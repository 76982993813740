import { Component, OnInit } from '@angular/core';
import { KitIcones, KitIconesSearch, defaultOptions } from 'src/app/dto/kitIcones';
import { NavigationExtras, Router } from '@angular/router';
import { KitIconesService } from 'src/app/services/kit-icones.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-kit-icones-list',
  templateUrl: './kit-icones-list.component.html',
  styleUrls: ['./kit-icones-list.component.css']
})
export class KitIconesListComponent implements OnInit {
  search: KitIconesSearch;
  kitIcones: KitIcones[] = [];
  defaultOptions = defaultOptions;
  isLoading: boolean = false;

  textoBuscar: string = "Buscar"
  classeBuscar: string = "button is-primary"

  constructor(private kitIconesService: KitIconesService, private router: Router, private toastr: ToastrService) { }
  
  edit(kitIcones: KitIcones) :void{
    let navigationExtras: NavigationExtras
    navigationExtras = {
      state: {
        kitIcones: kitIcones
      }
    }
    this.router.navigate(["/kiticones/edit/" + kitIcones.uuid], navigationExtras) 
  }

  buscar(reset: boolean = false): void{
    if(this.isLoading) return;

    this.isLoading = true;
    this.classeBuscar = "button is-light";

    if(reset){
      this.search.resetPage();
    }
    this.kitIconesService.buscar(this.search, (err, sucess)=>{
      this.isLoading = false;
      this.classeBuscar = "button is-primary";

      if(err){
        console.log(err);
      }else{
        if(reset){
          this.kitIcones = sucess;
        }else{
          this.kitIcones = this.kitIcones.concat(sucess);
        }

      }
    });
  }
  carregarMais(): void{
    this.search.incrementPage();
    this.buscar(false);
  }
  ngOnInit() {
    this.search = new KitIconesSearch();
  }

}
