import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Unidade } from "src/app/dto/unidade";

@Component({
  selector: "app-unidade-item",
  templateUrl: "./unidade-item.component.html",
  styleUrls: ["./unidade-item.component.css"],
})
export class UnidadeItemComponent {
  @Input() unidade: Unidade;
  @Input() unidadesSelecionadas: Unidade[] = [];
  @Output() unidadesSelecionadasChange = new EventEmitter<Unidade[]>();
  @Output() checkedChange = new EventEmitter<{
    unidade: Unidade;
    isChecked: boolean;
  }>();

  isUnidadeChecked(unidade: Unidade) {
    return this.unidadesSelecionadas.find((u) => u.uuid === unidade.uuid);
  }
  onChangeBox(event, unidade: Unidade) {
    let isChecked = event.target.checked;
    this.checkedChange.emit({ unidade, isChecked });
  }

  

  
}
