import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Erro400 } from "src/app/dto/erros";
import { EventDto } from "src/app/dto/event";
import { MensagemVideo, MensagemVideoDTO } from "src/app/dto/mensagem-video";
import { Theme } from "src/app/dto/theme";
import { Video } from "src/app/dto/video";
import { MensagemVideoService } from "src/app/services/mensagem-video.service";
import { ThemeService } from "src/app/services/theme.service";
import { VideoService } from "src/app/services/video.service";
import { Type } from "./enum/MensagemType";

@Component({
	selector: "app-mensagem-video-form",
	templateUrl: "./mensagem-video-form.component.html",
	styleUrls: ["./mensagem-video-form.component.css"],
})
export class MensagemVideoFormComponent implements OnInit {
	@Input() mensagemVideo: MensagemVideoDTO;
	temas: Array<Theme>;
	videos: Array<Video>;
	eventoUuid: string = "";
	@Input() evento: EventDto;
	@Output() retornarParaListagem = new EventEmitter();

	

	public image: File;

	constructor(
		private toastr: ToastrService,
		private spinner: NgxSpinnerService,
		private mensagemVideoService: MensagemVideoService,
		private temaService: ThemeService,
		private videoService: VideoService
	) {}

	ngOnInit(): void {
		this.image = null;
		this.temaService.themes(null, (erro: Erro400, sucesso: Array<Theme>) => {
			if (sucesso) {
				this.temas = sucesso;
				this.buscarVideosBaseadoNoTema();
			} else if (erro && erro.error) {
				this.toastr.error("", erro.error.message);
			}
		});
		if(this.mensagemVideo.video){
			this.mensagemVideo.isVideo = true;
			this.mensagemVideo.type = Type.Video;
		}
		
	}

	persistirMensagemVideo(aba) {
		if (this.mensagemVideo.uuid) {
			this.editarMensagemDeVideo(aba);
		} else {
			this.criarNovaMensagemDeVideo(aba);
		}
	}

	obterTemaParaPesquisa() {
		if (this.mensagemVideo.theme !== null) {
			this.buscarVideosBaseadoNoTema();
		}
	}

	buscarVideosBaseadoNoTema() {
		this.videoService.buscar(
			null,
			this.mensagemVideo.theme,
			null,
			(erro: Erro400, sucesso: Array<Video>) => {
				if (sucesso) {
					this.videos = sucesso;
				} else if (erro && erro.error) {
					this.toastr.error("", erro.error.message);
				}
			}
		);
	}

	criarNovaMensagemDeVideo(aba) {
		this.spinner.show();
		this.mensagemVideoService.criar(
			this.mensagemVideo,
			this.evento.uuid,
			(erro: any, sucesso: MensagemVideo) => {
				if (sucesso) {
					this.mensagemVideo = new MensagemVideoDTO(sucesso);
					this.toastr.success("", "Mensagem de vídeo cadastrada com sucesso!");
					this.retornarParaListagem.emit(aba);
				}
				if (erro) {
					if (erro.error && erro.error.message) {
						this.toastr.error("", erro.error.message);
					} else {
						this.toastr.error(
							"",
							"Falha ao cadastrar mensagem de vídeo, entre em contato com o suporte ou tente novamente mais tarde"
						);
					}
				}
				this.spinner.hide();
			}
		);
	}

	editarMensagemDeVideo(aba) {
		this.spinner.show();

		this.mensagemVideoService.editar(
			this.mensagemVideo,
			this.evento.uuid,
			(erro: any, sucesso: MensagemVideo) => {
				if (sucesso) {
					this.mensagemVideo = new MensagemVideoDTO(sucesso);
					this.toastr.success("", "Mensagem de vídeo atualizada!");
					this.retornarParaListagem.emit(aba);
				}
				if (erro) {
					if (erro.error && erro.error.message) {
						this.toastr.error("", erro.error.message);
					} else {
						this.toastr.error(
							"",
							"Falha ao atualizar mensagem de vídeo, entre em contato com o suporte ou tente novamente mais tarde"
						);
					}
				}
				this.spinner.hide();
			}
		);
	}

	apagarMensagemDeVideo() {
		if (
			!confirm(
				"Tem certeza que deseja excluir a mensagem " +
					this.mensagemVideo.title +
					"?"
			)
		) {
			return;
		}
		this.spinner.show();
		this.mensagemVideoService.apagar(
			this.mensagemVideo,
			this.evento.uuid,
			false,
			(erro: any, sucesso: any) => {
				if (erro) {
					if (erro.error && erro.error.message) {
						if (erro.error.message === "A mensagem de vídeo já foi assistida na campanha") {
							if (confirm("A mensagem de vídeo já foi assistida na campanha. Tem certeza de que deseja apagar?")) {
								this.mensagemVideoService.apagar(this.mensagemVideo, this.evento.uuid, true, (erroConfirma:any, sucessoConfirma:any) => {
									if (erroConfirma) {
										this.toastr.error(
											"",
											"Falha ao excluir mensagem de vídeo, entre em contato com o suporte ou tente novamente mais tarde"
										);
									} else {
										this.toastr.success(
											"",
											"A mensagem de vídeo foi removida com sucesso"
										);
										this.retornarParaLista();
									} 
								})
							} 
						} else {
							this.toastr.error("", erro.error.message);
						}
					} else {
						this.toastr.error(
							"",
							"Falha ao excluir mensagem de vídeo, entre em contato com o suporte ou tente novamente mais tarde"
						);
					}
				} else {
					this.toastr.success(
						"",
						"A mensagem de vídeo foi removida com sucesso"
					);
					this.retornarParaLista();
				}
				this.spinner.hide();
			}
		);
	}

	uploadImagem(tipo: string) {
		let arquivo: File = null;
		arquivo = this.image;

		this.spinner.show();
		this.mensagemVideoService.adicionarImagem(
			this.mensagemVideo,
			this.evento.uuid,
			tipo,
			arquivo,
			(erro: Erro400, sucesso: MensagemVideo) => {
				this.spinner.show();
				if (tipo === "image") {
					if (sucesso) {
						this.mensagemVideo = new MensagemVideoDTO(sucesso);
						this.toastr.success("", "Imagem enviada com sucesso");
					}
					if (erro) {
						if (erro.error && erro.error.message) {
							this.toastr.error("", erro.error.message);
						} else {
							this.toastr.error(
								"",
								"Aconteceu um erro ao tentar substituir a imagem, entre em contato com o suporte ou tente novamente mais tarde"
							);
						}
					}
					this.spinner.hide();
				}
			}
		);
	}

	onSelectFile(event, tipo: string) {
		if (event.target.files && event.target.files[0]) {
			if (tipo === "image") {
				this.image = event.target.files[0];
			}
		}
	}

	retornarParaLista() {
		this.retornarParaListagem.emit("lista");
	}
	onMensagemVideoTypeChange(){

		if(this.mensagemVideo.isVideo){
			this.mensagemVideo.type = Type.Video;
			
			
		}
		else{
			this.mensagemVideo.type = Type.Mensagem;
			this.mensagemVideo.video = null;
		}
		
	}
}
