import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Client } from 'src/app/dto/client';
import { ComoFunciona } from 'src/app/dto/comoFunciona';
import { Erro400 } from 'src/app/dto/erros';
import { Event, EventCopiaDto, EventDto } from 'src/app/dto/event';
import { Frontend } from 'src/app/dto/frontend';
import { KitDto } from 'src/app/dto/kit';
import { KitIcones, KitIconesSearch } from 'src/app/dto/kitIcones';
import { Template, TemplateSearch } from 'src/app/dto/template';
import { Theme } from 'src/app/dto/theme';
import { ClientsService } from 'src/app/services/clients.service';
import { ComoFuncionaService } from 'src/app/services/como-funciona.service';
import { EventService } from 'src/app/services/evento.service';
import { FrontendService } from 'src/app/services/frontend.service';
import { KitIconesService } from 'src/app/services/kit-icones.service';
import { KitService } from 'src/app/services/kit.service';
import { TemplateService } from 'src/app/services/template.service';
import { ThemeService } from 'src/app/services/theme.service';
import { AgendaService } from '../../services/agenda.service';

@Component({
  selector: "app-evento-form",
  templateUrl: "./evento-form.component.html",
  styleUrls: ["./evento-form.component.css"],
})
export class EventoformComponent implements OnInit {
  evento: EventDto;
  eventoCompleto: Event;
  substituir: boolean;
  scheduleTypeSave: String = "";
  clientes: Array<Client>;
  temas: Array<Theme>;
  kits: Array<KitDto>;
  disabledElegibilidade: boolean;
  comoFunciona: Array<ComoFunciona>;
  frontend: Array<Frontend>;

  kitIcons: KitIcones[] = [];
  searchKitIcones: KitIconesSearch;
  templates: Template[] = [];
  templatesSemCliente: Template[] = [];
  search: TemplateSearch;
  searchSemCliente: TemplateSearch;

  classeAbaDados: string;
  classeAbaAgenda: string;
  classeModalCopiarCampanha: string;
  codigoNovoEvento: string;
  classeAbaElegibilidade: string;
  classeAbaVideoMessage: string;
  classeAbaMessage: string;
  classeAbaEstilos: string;
  classeAbaRelatorios: string;
  classeAbaCampos: string;
  classeAbaGestores: string;

  abaDatasLancamentoFeedBack: string;
  abaDatasCampanhaExecucao: string;
  abaDatasPreCampanha: string;

  public arquivoLogoEsquerdo: File;
  public arquivoLogoDireito: File;
  public arquivoLogoApresentacao: File;
  public arquivoTrofeu: File;
  public arquivoTrofeuDesabilitado: File;

  public tipoInscricaoLagado = {
    name:'',
    value:''
  };

  public tiposInscricao: Array<any> = [
    { value: "CUSTOMIZADO", name: "Customizada" },
    { value: "ELEGIBILIDADE", name: "Nenhuma" },
  ];

  public tiposAgenda: Array<any> = [
    { value: "CHRONOLOGIC", name: "Cronológica" },
    { value: "INTEREST", name: "Interesses" },
  ];
  tipoCampanhaModificado: boolean = false;
  ativarMensagem: boolean;
  mensagemDeAviso: string =
    "A o altera o tipo da campanha para 'Cronológica' todas as agendas e ações vinculadas serão excluídas!";
  mensagemDeConfirmacao: string =
    "Tem certeza que deseja confirma a mudança do tipo da campanha para 'Cronológica'? Todas as ações realizadas na campanha serão excluídas!";
  tituloMensagem = "Aviso!";

  constructor(
    private clienteService: ClientsService,
    private eventoService: EventService,
    private router: Router,
    private route: ActivatedRoute,
    private temaService: ThemeService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private kitService: KitService,
    private kitIconesService: KitIconesService,
    private templateService: TemplateService,
    private comoFuncionaService: ComoFuncionaService,
    private agendaService: AgendaService,
    private frontendService: FrontendService
  ) {}
  ngOnInit(): void {
    this.fecharModalCopiarCampanha();
    this.arquivoLogoEsquerdo = null;
    this.arquivoLogoDireito = null;
    this.arquivoLogoApresentacao = null;
    this.arquivoTrofeu = null;
    this.arquivoTrofeuDesabilitado = null;
    this.classeAbaDados = "is-active";
    this.classeAbaAgenda = "";
    this.classeAbaVideoMessage = "";
    this.classeAbaMessage = "";
    this.classeAbaEstilos = "";
    this.classeAbaRelatorios = "";
    this.classeAbaCampos = "";
    this.classeAbaGestores = "";
    this.substituir = false;

    this.abaDatasLancamentoFeedBack = "is-active";
    this.abaDatasCampanhaExecucao = "";
    this.abaDatasPreCampanha = "";

    this.kitService.search((erro: Erro400, sucesso: Array<KitDto>) => {
      if (sucesso) {
        this.kits = sucesso;
      } else if (erro && erro.error) {
        this.toastr.error("", erro.error.message);
      }
    });

    this.searchKitIcones = new KitIconesSearch();
    this.searchKitIcones.pageSize = 500;
    this.kitIconesService.buscar(this.searchKitIcones, (erro, sucess) => {
      if (erro) {
        this.toastr.error("", erro.error.message);
      } else {
        this.kitIcons = this.kitIcons.concat(sucess);
      }
    });

    this.clienteService.buscar(
      undefined,
      undefined,
      0,
      1000,
      (erro: Erro400, sucesso: Array<Client>) => {
        if (sucesso) {
          this.clientes = sucesso;
        } else if (erro && erro.error) {
          this.toastr.error("", erro.error.message);
        }
      }
    );

    this.temaService.themes(
      undefined,
      (erro: Erro400, sucesso: Array<Theme>) => {
        if (sucesso) {
          this.temas = sucesso;
        } else if (erro && erro.error) {
          this.toastr.error("", erro.error.message);
        }
      }
    );

    this.comoFuncionaService.buscarComoFunciona(
      null,
      0,
      1000,
      (erro: any, sucesso: Array<ComoFunciona>) => {
        if (sucesso) {
          this.comoFunciona = sucesso;
        } else if (erro && erro.error) {
          this.toastr.error("", erro.error.message);
        }
      }
    );

    this.frontendService.buscarVersaoFrontend(
      (erro: any, sucesso: Array<Frontend>) => {
        if (sucesso) {
          this.frontend = sucesso;
        } else if (erro && erro.error) {
          this.toastr.error("", erro.error.message);
        }
      }
    );

    var uuid = this.route.snapshot.paramMap.get("uuid");
    if (uuid) {
      this.eventoService.get(uuid, (erro: Erro400, sucesso: Event) => {
        if (sucesso) {
          this.eventoCompleto = sucesso;
          this.evento = new EventDto(sucesso);
          this.disabledElegibilidade = this.evento.restrictEligibility;
          if( this.evento.tipoMatricula !== 'ELEGIBILIDADE' && this.evento.tipoMatricula !== 'CUSTOMIZADO'){
            this.tipoInscricaoLagado = {
              value: this.evento.tipoMatricula,
              name: this.evento.descricaoTipoInscricao};


          }
          this.scheduleTypeSave = this.evento.scheduleType
          this.alterarElegibilidade();
          if (this.evento.client != null) {
            this.search = new TemplateSearch();
            this.search.pageSize = 1000;
            this.search.client = this.eventoCompleto.client.nome;
            this.templateService.buscar(this.search, (erro, sucess) => {
              if (erro) {
                this.toastr.error("", erro.error.message);
              } else {
                this.templates = this.templates.concat(sucess);
                for (var i = 0; i < this.templates.length; i++) {
                  this.templates[i].name =
                    "Template do cliente: " + this.templates[i].name;
                }
              }
            });
          }

          this.searchSemCliente = new TemplateSearch();
          this.searchSemCliente.pageSize = 1000;
          this.templateService.buscar(this.searchSemCliente, (erro, sucess) => {
            if (erro) {
              this.toastr.error("", erro.error.message);
            } else {
              this.templates = this.templates.concat(sucess);
              this.templatesSemCliente = sucess;
            }
          });
        } else if (erro && erro.error) {
          this.toastr.error("", erro.error.message);
        }
      });
    } else {
      this.evento = new EventDto();
      this.evento.test = false;
      this.evento.privacyPolicy =
        "https://weex.digital/politica-de-privacidade-e-uso/";
      this.searchSemCliente = new TemplateSearch();
      this.searchSemCliente.pageSize = 1000;
      this.templateService.buscar(this.searchSemCliente, (erro, sucess) => {
        if (erro) {
          this.toastr.error("", erro.error.message);
        } else {
          this.templates = this.templates.concat(sucess);
          this.templatesSemCliente = sucess;
        }
      });
    }
  }

  buscarTemplates() {
    if (this.evento.client != null) {
      this.search = new TemplateSearch();
      this.search.pageSize = 1000;
      const clientePesquisar = this.clientes.find(
        (cliente) => cliente.uuid === this.evento.client
      );
      this.search.client = clientePesquisar.nome;
      this.templateService.buscar(this.search, (erro, sucess) => {
        if (erro) {
          this.toastr.error("", erro.error.message);
        } else {
          this.templates = sucess;
          for (var i = 0; i < this.templates.length; i++) {
            this.templates[i].name =
              "Template do cliente: " + this.templates[i].name;
          }
          this.templates = this.templates.concat(this.templatesSemCliente);
        }
      });
    }
  }

  alterarElegibilidade() {
    if (this.evento.restrictEligibility) {
      this.evento.workers = null;
    }
    if (!this.evento.restrictEligibility) {
      this.evento.tipoMatricula = this.tipoInscricaoLagado.name? this.tipoInscricaoLagado.value : "CUSTOMIZADO";
      for (var i = 0; i < this.tiposInscricao.length; i++) {
        if (this.tiposInscricao[i].value === "ELEGIBILIDADE") {
          this.tiposInscricao.splice(i, 1);
          i--;
        }
      }
      if (this.evento.tipoMatricula === "ELEGIBILIDADE") {
        this.evento.tipoMatricula = "";
      }
    } else {
      //verificar se já existe o tipo de inscrição elegibilidade no tiposInscricao
      if (!this.tiposInscricao.some(tipo => tipo.value === 'ELEGIBILIDADE')) {
        this.tiposInscricao.push({ value: "ELEGIBILIDADE", name: "Nenhuma" });
      }

    }
  }

  async cadastrarEvento() {
    if (this.evento.uuid) {
      this.validarMudancaNoTipoCampanha();
    } else {
      this.spinner.show();
      if (!this.evento.legalTerm) {
        this.evento.privacyPolicy = "";
      }
      this.eventoService.criar(this.evento, (erro: Erro400, sucesso: Event) => {
        if (sucesso) {
          this.evento = new EventDto(sucesso);
          this.disabledElegibilidade = this.evento.restrictEligibility;
          this.toastr.success("", "O evento foi cadastrado com sucesso");
        } else if (erro && erro.error) {
          this.toastr.error("", erro.error.message);
        }
        this.spinner.hide();
      });
    }
  }

  enviarImagem(tipo: string) {
    let arquivo: File = null;
    if (tipo == "top_left") {
      arquivo = this.arquivoLogoEsquerdo;
    } else if (tipo == "top_right") {
      arquivo = this.arquivoLogoDireito;
    } else if (tipo == "presentation") {
      arquivo = this.arquivoLogoApresentacao;
    } else if (tipo == "trophyEnabled") {
      arquivo = this.arquivoTrofeu;
    } else if (tipo == "trophyDisabled") {
      arquivo = this.arquivoTrofeuDesabilitado;
    }
    this.spinner.show();
    if (tipo == "top_left" || tipo == "top_right" || tipo == "presentation") {
      this.eventoService.enviarImagem(
        this.evento.uuid,
        tipo,
        arquivo,
        (erro: Erro400, sucesso: Event) => {
          if (sucesso) {
            this.evento = new EventDto(sucesso);
            this.toastr.success("", "Arquivo enviado com sucesso");
          } else if (erro && erro.error) {
            this.toastr.error("", erro.error.message);
          } else {
            this.toastr.error(
              "",
              "Aconteceu um erro ao tentar remover a imagem, por favor, contate o responsável pelo sistema"
            );
          }
          this.spinner.hide();
        }
      );
    } else if (tipo == "trophyEnabled") {
      this.eventoService.enviarTrofeu(
        this.evento.uuid,
        "EVENT",
        arquivo,
        (erro: Erro400, sucesso: Event) => {
          if (sucesso) {
            this.evento = new EventDto(sucesso);
            this.toastr.success("", "Arquivo enviado com sucesso");
          } else if (erro && erro.error) {
            this.toastr.error("", erro.error.message);
          } else {
            this.toastr.error(
              "",
              "Aconteceu um erro ao tentar remover a imagem, por favor, contate o responsável pelo sistema"
            );
          }
          this.spinner.hide();
        }
      );
    } else if (tipo == "trophyDisabled") {
      this.eventoService.enviarTrofeu(
        this.evento.uuid,
        "EVENT_DISABLED",
        arquivo,
        (erro: Erro400, sucesso: Event) => {
          if (sucesso) {
            this.evento = new EventDto(sucesso);
            this.toastr.success("", "Arquivo enviado com sucesso");
          } else if (erro && erro.error) {
            this.toastr.error("", erro.error.message);
          } else {
            this.toastr.error(
              "",
              "Aconteceu um erro ao tentar remover a imagem, por favor, contate o responsável pelo sistema"
            );
          }
          this.spinner.hide();
        }
      );
    }
  }

  onSelectFile(event, tipo: string) {
    if (event.target.files && event.target.files[0]) {
      if (tipo == "top_left") {
        this.arquivoLogoEsquerdo = event.target.files[0];
      } else if (tipo == "top_right") {
        this.arquivoLogoDireito = event.target.files[0];
      } else if (tipo == "presentation") {
        this.arquivoLogoApresentacao = event.target.files[0];
      } else if (tipo == "trophyEnabled") {
        this.arquivoTrofeu = event.target.files[0];
      } else if (tipo == "trophyDisabled") {
        this.arquivoTrofeuDesabilitado = event.target.files[0];
      }
    }
  }

  remover(tipo: string) {
    this.spinner.show();
    this.eventoService.removerImagem(
      this.evento.uuid,
      tipo,
      (erro: Erro400, sucesso: Event) => {
        if (sucesso) {
          this.evento = new EventDto(sucesso);
          this.toastr.success("", "Arquivo enviado com sucesso");
        } else if (erro && erro.error) {
          this.toastr.error("", erro.error.message);
        } else {
          this.toastr.error(
            "",
            "Aconteceu um erro ao tentar remover a imagem, por favor, contate o responsável pelo sistema"
          );
        }
        this.spinner.hide();
      }
    );
  }

  alterarAba(aba: string) {
    if (aba === "dados") {
      this.classeAbaDados = "is-active";
      this.classeAbaAgenda = "";
      this.classeAbaElegibilidade = "";
      this.classeAbaVideoMessage = "";
      this.classeAbaMessage = "";
      this.classeAbaEstilos = "";
      this.classeAbaRelatorios = "";
      this.classeAbaCampos = "";
      this.classeAbaGestores = "";
    } else if (aba === "agenda") {
      this.classeAbaDados = "";
      this.classeAbaAgenda = "is-active";
      this.classeAbaVideoMessage = "";
      this.classeAbaElegibilidade = "";
      this.classeAbaMessage = "";
      this.classeAbaEstilos = "";
      this.classeAbaRelatorios = "";
      this.classeAbaCampos = "";
      this.classeAbaGestores = "";
    } else if (aba == "elegibilidade") {
      this.classeAbaDados = "";
      this.classeAbaAgenda = "";
      this.classeAbaMessage = "";
      this.classeAbaEstilos = "";
      this.classeAbaVideoMessage = "";
      this.classeAbaRelatorios = "";
      this.classeAbaElegibilidade = "is-active";
      this.classeAbaCampos = "";
      this.classeAbaGestores = "";
    } else if (aba == "videoMessage") {
      this.classeAbaDados = "";
      this.classeAbaAgenda = "";
      this.classeAbaElegibilidade = "";
      this.classeAbaMessage = "";
      this.classeAbaVideoMessage = "is-active";
      this.classeAbaEstilos = "";
      this.classeAbaRelatorios = "";
      this.classeAbaCampos = "";
      this.classeAbaGestores = "";
    } else if (aba == "message") {
      this.classeAbaDados = "";
      this.classeAbaAgenda = "";
      this.classeAbaElegibilidade = "";
      this.classeAbaVideoMessage = "";
      this.classeAbaMessage = "is-active";
      this.classeAbaEstilos = "";
      this.classeAbaCampos = "";
      this.classeAbaRelatorios = "";
      this.classeAbaGestores = "";
    } else if (aba == "style") {
      this.classeAbaDados = "";
      this.classeAbaAgenda = "";
      this.classeAbaElegibilidade = "";
      this.classeAbaVideoMessage = "";
      this.classeAbaMessage = "";
      this.classeAbaEstilos = "is-active";
      this.classeAbaCampos = "";
      this.classeAbaRelatorios = "";
      this.classeAbaGestores = "";
    } else if (aba == "campos") {
      this.classeAbaDados = "";
      this.classeAbaAgenda = "";
      this.classeAbaElegibilidade = "";
      this.classeAbaVideoMessage = "";
      this.classeAbaMessage = "";
      this.classeAbaEstilos = "";
      this.classeAbaGestores = "";
      this.classeAbaCampos = "is-active";
      this.classeAbaRelatorios = "";
    } else if (aba == "report") {
      this.classeAbaDados = "";
      this.classeAbaAgenda = "";
      this.classeAbaElegibilidade = "";
      this.classeAbaVideoMessage = "";
      this.classeAbaMessage = "";
      this.classeAbaEstilos = "";
      this.classeAbaCampos = "";
      this.classeAbaGestores = "";
      this.classeAbaRelatorios = "is-active";
    } else if (aba == "gestores") {
      this.classeAbaDados = "";
      this.classeAbaAgenda = "";
      this.classeAbaElegibilidade = "";
      this.classeAbaVideoMessage = "";
      this.classeAbaMessage = "";
      this.classeAbaEstilos = "";
      this.classeAbaCampos = "";
      this.classeAbaRelatorios = "";
      this.classeAbaGestores = "is-active";
    }
  }

  alterarAbaDatas(abaDatas: string) {
    if (abaDatas === "lancamento") {
      this.abaDatasLancamentoFeedBack = "is-active";
      this.abaDatasCampanhaExecucao = "";
      this.abaDatasPreCampanha = "";
    } else if (abaDatas === "execucao") {
      this.abaDatasLancamentoFeedBack = "";
      this.abaDatasCampanhaExecucao = "is-active";
      this.abaDatasPreCampanha = "";
    } else if (abaDatas == "preCampanha") {
      this.abaDatasLancamentoFeedBack = "";
      this.abaDatasCampanhaExecucao = "";
      this.abaDatasPreCampanha = "is-active";
    }
  }

  checked() {
    if (this.substituir) {
      return "checked";
    } else {
      return "";
    }
  }

  aplicarKit() {
    if (this.substituir) {
      if (
        !confirm(
          "Deseja realmente aplicar o kit de imagens substituindo as existentes?"
        )
      ) {
        return null;
      }
    }
    this.kitService.apply(
      this.evento.kit,
      this.evento.uuid,
      this.substituir,
      (erro: Erro400, sucesso: Event) => {
        if (sucesso) {
          this.evento = new EventDto(sucesso);
          this.toastr.success("", "O kit de imagens foi aplicado com sucesso");
        } else if (erro && erro.error) {
          this.toastr.error("", erro.error.message);
        }
      }
    );
  }

  aplicarIcons() {
    if (this.evento.uuid) {
      this.eventoService.editar(
        this.evento,
        (erro: Erro400, sucesso: Event) => {
          if (sucesso) {
            this.evento = new EventDto(sucesso);
            this.toastr.success(
              "",
              "O kit de icones foi atualizado com sucesso"
            );
          } else if (erro && erro.error) {
            this.toastr.error("", erro.error.message);
          }
        }
      );
    }
  }

  abrirModalCopiarCampanha() {
    this.classeModalCopiarCampanha = "modal is-active";
  }

  fecharModalCopiarCampanha() {
    this.classeModalCopiarCampanha = "modal";
  }

  copiarEvento() {
    if (
      !confirm(
        "Ao confirmar a ação será gerada uma nova campanha com os mesmos dados da campanha selecionada.\n Deseja prosseguir?"
      )
    ) {
      return;
    }
    this.spinner.show();
    const copia = new EventCopiaDto();
    copia.code = this.codigoNovoEvento;
    this.eventoService.copiar(
      this.evento,
      copia,
      (erro: Erro400, sucesso: Event) => {
        if (sucesso) {
          this.evento = new EventDto(sucesso);
          this.toastr.success("", "O evento foi copiado com sucesso");
          this.fecharModalCopiarCampanha();
          this.router.navigate(["/events/edit/" + sucesso.uuid]);
        } else if (erro && erro.error) {
          this.toastr.error("", erro.error.message);
        }
        this.spinner.hide();
      }
    );
  }
  showNomeMatricula() {
    if (this.evento && this.evento.tipoMatricula) {
      return this.evento.tipoMatricula !== "EMAIL";
    }
    return false;
  }

  onImagemAlterada(evento: any) {
    this.evento = evento;
  }

  editar() {
    this.eventoService.editar(this.evento, (erro: Erro400, sucesso: Event) => {
      if (sucesso) {
        this.evento = new EventDto(sucesso);
        this.disabledElegibilidade = this.evento.restrictEligibility;
        this.toastr.success("", "O evento foi atualizado com sucesso");
      } else if (erro && erro.error) {
        this.toastr.error("", erro.error.message);
      }
      this.spinner.hide();
    });
  }

  changeScheduleType(tipoCampanha: any) {

    if (tipoCampanha === "CHRONOLOGIC" && this.scheduleTypeSave ==="INTEREST" && this.evento.uuid) {
      this.tipoCampanhaModificado = true;
    }

    if (tipoCampanha === this.scheduleTypeSave) {
      this.tipoCampanhaModificado = false;
    }
    if (tipoCampanha === "CHRONOLOGIC") {
      this.evento.requiredInterests = false;
    }

    if (tipoCampanha === "INTEREST") {
      this.evento.requiredInterests = true;
    }
  }

  atualizarAcao(acao: boolean) {
    console.log("acao", acao);
    if (this.tipoCampanhaModificado && acao) {
      this.agendaService.deleteByEvento(this.evento.uuid).subscribe(
        () => {
          this.toastr.success("", "Agenda excluida com sucesso");
          this.ativarMensagem = false;
          this.tipoCampanhaModificado = false;
          this.editar();
        },
        (erro) => {
          this.toastr.error("", erro.error.message);
        }
      );
    } else {
      this.tipoCampanhaModificado = false;
    }
    if (acao && this.evento.uuid) {
      this.ativarMensagem = false;
      this.tipoCampanhaModificado = true;
    } else {
      this.ativarMensagem = false;
      this.evento.scheduleType = "INTEREST";
    }
  }

  validarMudancaNoTipoCampanha() {
    if (this.evento.uuid && this.tipoCampanhaModificado) {
      this.ativarMensagem = true;
      this.tituloMensagem = "Confirmação";
      this.mensagemDeAviso = this.mensagemDeConfirmacao;
    } else {
      this.spinner.show();
      this.editar();
    }
  }

novaDataObtencaoFeedback(event) {
if (!event || !event.value || event.value == "Tue Dec 30 1969 21:00:00 GMT-0300 (Horário Padrão de Brasília)") {
return
}
let date = new Date(event.value);
date.setDate(date.getDate()-1)
this.evento.feedback = date;
}

onRegistrationUnitMandatoryChange(event) {
  console.log("event", event);
}


}
