import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Erro400 } from "../dto/erros";
import { MensagemDTO } from "../dto/mensagem";
import { LoginService } from "./login.service";

@Injectable({
	providedIn: "root",
})
export class MensagemService{
    private apiHost: string = environment.apiHost;

	constructor(private http: HttpClient, private loginService: LoginService) {}

	public find(eventoUuid: string, codigo: string, idioma: string, texto: string, page: number, pageSize: number, callback: Function) {
		let httpParams: HttpParams = new HttpParams()
		if (eventoUuid) {
		  httpParams = httpParams.append("event", eventoUuid)
		}
		if(codigo){
			httpParams = httpParams.append("code", codigo)
		}
		if(idioma){
			httpParams = httpParams.append("language", idioma)
		}
		if(texto){
			httpParams = httpParams.append("text", texto)
		}
		httpParams = httpParams.set('page', page + '');
        httpParams = httpParams.set('pageSize', pageSize + '');
		this.http.get(this.apiHost + '/api/v1/message', { params: httpParams }).subscribe(
		  (mensagens: MensagemDTO[]) => {
			callback(null, mensagens);
		  },
		  (error: any) => {
			callback(error, null);
		  }
		);
	}

	save(mensagens: Array<MensagemDTO>, callback: Function){
		let json = [];
		mensagens.forEach(m => {
			json.push({
				'code': m.code,
				'language': m.language,
				'message': m.message,
				'event': m.event ? m.event.uuid : null
			 })
		})
		this.http.post(this.apiHost + '/api/v1/message', json, {headers: this.loginService.authorizationHeader()}).subscribe(
			(sucesso: Array<MensagemDTO>) => {
				callback(null, sucesso);
			},
			(falha: Erro400) => {
				callback(falha, null);
			}
		);
	}

	delete(mensagem: MensagemDTO, callback: Function){
		this.http.delete(this.apiHost + "/api/v1/message/" + mensagem.uuid, { headers: this.loginService.authorizationHeader() })
		.subscribe(
		(sucesso: MensagemDTO) =>{
			callback(null, sucesso);
		},
		(falha: Erro400)=>{
			callback(falha, null);
		})
	}
    
}