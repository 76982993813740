import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeletaImagemService {
  apiHost: string = environment.apiHost;

  constructor( private http : HttpClient ) { }

  deletaImagem(uuid: string, type: string){
    return this.http.delete(`${this.apiHost}/api/v1/image/${type}/${uuid}`);
  }

}
