import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AgendaDinamica } from 'src/app/dto/agenda';
import { Bloco } from 'src/app/dto/bloco-dinamica/bloco';
import { DINAMICS_BLOCK } from 'src/app/dto/bloco-dinamica/dynamicsBlock';
import { Erro400 } from 'src/app/dto/erros';
import { Theme } from 'src/app/dto/theme';
import { TipoDinamica } from 'src/app/dto/tipoDinamica';
import { DinamicaService } from 'src/app/services/dinamica.service';
import { Dinamica } from '../../../dto/dinamica';
import { BlocoDinamica } from '../../../dto/bloco-dinamica/blocoDinamica';

@Component({
  selector: 'app-dinamicas-form',
  templateUrl: './dinamicas-form.component.html',
  styleUrls: ['./dinamicas-form.component.css']
})
export class DinamicasFormComponent implements OnInit,OnChanges {
  @Input()
  themes: Theme[];
  @Input()
  bloco:Bloco;
  @Input()
  salva:boolean = false;

  @Output()
  onChangeBloco: EventEmitter<Bloco> = new EventEmitter<Bloco>();


  teste:string = 'teste';
  dynamics = DINAMICS_BLOCK;
  dinamica: Dinamica = new Dinamica();
  dinamicas: Dinamica[];
  dinamicasSelecionadas: Dinamica[] = [];
  dinamicaEscolhida: Dinamica = new Dinamica();
  tipoDinamicaEscolhida: string;
  confirmaRemocaoComAtividadeJaFeita: string;
  dinamicaEscolhidaUuid: string = '';

  constructor(
    private dinamicaService:DinamicaService,
    private toastr: ToastrService
    ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.salva &&  changes.salva.currentValue){
      this.passarDinamicasParaBloco();
    }
  }

  ngOnInit() {
    this.setDinamicasSelecionadas();
  }

  obterDinamicas(edit: boolean = false) {
    if (this.dinamica.theme && this.tipoDinamicaEscolhida) {
      this.dinamicaService.buscar(this.dinamica.theme, this.tipoDinamicaEscolhida, (erro: Erro400, sucesso: Dinamica []) => {
        if (sucesso) {
          this.dinamicas = sucesso;
          if(sucesso.length > 0 && !edit){
            this.dinamica.uuid = sucesso[0].uuid;
            this.dinamica.name = sucesso[0].name;
            this.dinamicaEscolhidaUuid = sucesso[0].uuid;
            this.dinamicaEscolhida = sucesso[0];
          }
        } else if (erro && erro.error) {
          this.toastr.error('', erro.error.message);
        }
      });
    }else{
      this.dinamicas = [];
    }
  }

  imagemDinamica(dinamica:Dinamica) {
    switch (dinamica.type) {
      case TipoDinamica.TIPO_FATO_FAKE:
        return '../../../assets/images/fatooufake.png';
      case TipoDinamica.TIPO_GAME:
        return '../../../assets/images/jogos.png';
      case TipoDinamica.TIPO_VIDEO:
        return '../../../assets/images/videos.png';
      case TipoDinamica.TIPO_NOVO_HABITO:
        return '../../../assets/images/novos-habitos.png';
      case TipoDinamica.TIPO_EXTRA:
        return '../../../assets/images/extra.png';
      case TipoDinamica.TIPO_MURAL:
        return '../../../assets/images/mural.png';
      default:
        return '';
    }
  }
  excluirDinamica(index: number) {

    if (! confirm('Tem certeza que deseja excluir a dinâmica ' + this.dinamicasSelecionadas[index].name + '?')) {
      return;
    }
    this.dinamicasSelecionadas.splice(index, 1);
  }
  adicionarDinamica(){
    if(this.dinamicasSelecionadas.find(d => d.uuid === this.dinamicaEscolhida.uuid)){
      this.toastr.error('', 'Dinâmica já adicionada');
      return;
    }
    if(this.dinamicasSelecionadas.find(d => d.uuid === this.dinamicaEscolhida.uuid)){
      this.toastr.success('', 'Dinâmica adicionada');
      return;
    }
    this.setDinamica(this.dinamicaEscolhida.uuid);
    if(this.dinamicaEscolhida != null && this.dinamicaEscolhida.uuid){
      this.dinamicaEscolhida = new Dinamica(this.dinamicaEscolhida);
      this.dinamicaEscolhida.setType(this.tipoDinamicaEscolhida);
      this.dinamicasSelecionadas = [...this.dinamicasSelecionadas, this.dinamicaEscolhida];
      this.dinamicaEscolhida = new Dinamica();
      this.dinamicas = [];
      this.tipoDinamicaEscolhida = '';
      this.dinamica.uuid = '';
      this.dinamica.name = '';
      this.dinamicaEscolhidaUuid = '';
      this.toastr.success('', 'Dinâmica adicionada');

    }else{
      this.toastr.error('', 'Selecione uma dinâmica');
    }

  }

  editarDinamica(index: number){
    this.dinamica = new Dinamica(this.dinamicasSelecionadas[index]);
    this.dinamicaEscolhidaUuid = this.dinamica.uuid;
    this.tipoDinamicaEscolhida = this.dinamica.type;
    this.obterDinamicas(true);

  }

  ableToUp(index: number){
    if(index ===0 ){
      return false;
    }
    return true;
  }

  ableToDown(index: number){
    if(this.dinamicasSelecionadas.length === index+1){
      return false;
    }
    return true;
  }

  up(index: number){
    let aux = this.dinamicasSelecionadas[index-1];
    this.dinamicasSelecionadas[index-1] = this.dinamicasSelecionadas[index];
    this.dinamicasSelecionadas[index] = aux;
    this.dinamicasSelecionadas = [...this.dinamicasSelecionadas];
  }

  down(index: number){
    let aux = this.dinamicasSelecionadas[index+1];
    this.dinamicasSelecionadas[index+1] = this.dinamicasSelecionadas[index];
    this.dinamicasSelecionadas[index] = aux;
    this.dinamicasSelecionadas = [...this.dinamicasSelecionadas];
  }

  setDinamica(event:string){
    this.dinamicaEscolhidaUuid = event;
    this.dinamicaEscolhida = this.dinamicas.find(d => d.uuid === this.dinamicaEscolhidaUuid);
  }

  passarDinamicasParaBloco(){
    this.bloco.dynamics = Array<BlocoDinamica>();
    this.dinamicasSelecionadas.forEach(d => {
      let blocoDinamica:BlocoDinamica  = new BlocoDinamica();
      blocoDinamica.uuid = d.uuid;
      this.bloco.dynamics.push(blocoDinamica);
    })
    this.onChangeBloco.emit(this.bloco);
  }
  setDinamicasSelecionadas(){
    this.bloco.dynamics.forEach(d => {
      let dinamica:Dinamica = new Dinamica();
      dinamica.uuid = d.uuid;
      dinamica.name = d.nome;
      dinamica.theme = d.theme;
      dinamica.setType(d.tipo);
      this.dinamicasSelecionadas = [...this.dinamicasSelecionadas, dinamica];
    })
  }

}
