import { Theme } from './theme';

export default class DinamicaPadrao{
    uuid: string;
    title: string;
    name: string;
    code: string;
    url: string = "";
    presentation: string;
    description: string;
    theme: Theme;
    active: boolean;

    picture: string;

    constructor(g: DinamicaPadrao = null){
        if(g){
            this.uuid = g.uuid;
            this.name = g.name;
            this.code = g.code;
            this.url = g.url;
            this.presentation = g.presentation;
            this.description = g.description;
            this.theme = g.theme;
            this.active = g.active;
            this.title = g.title;
            this.picture = g.picture;
        }else{
            this.theme = new Theme();
        }
    }

    checked(){
        if(this.active){
            return "checked";
        }else{
            return "";
        }
    }

    toJSON(){
        return {
           'uuid': this.uuid,
           'name': this.name,
           'code': this.code,
           'url': this.url,
           'presentation': this.presentation,
           'description': this.description,
           'theme': {'uuid': this.theme.uuid},
           'active': this.active != undefined ? this.active : false,
           'title': this.title,
           'picture': this.picture
        }
     }
}
