import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Erro400 } from 'src/app/dto/erros';
import { Theme } from 'src/app/dto/theme';
import { ExtraService } from 'src/app/services/extra.service';
import { ThemeService } from 'src/app/services/theme.service';
import { DeletaImagemService } from 'src/app/services/deleta-imagem.service';
import Extra from 'src/app/dto/extra/extra';
import { TipoApresentacao } from 'src/app/dto/extra/tipoApresentacao';
import { ExtraArquivoDTO } from 'src/app/dto/extra/extraArquivo';
import { MimeTypeEntry } from 'src/app/dto/extra/mimeType';

@Component({
  selector: 'app-extra-form',
  templateUrl: './extra-form.component.html',
  styleUrls: ['./extra-form.component.css']
})
export class ExtraFormComponent implements OnInit {
  extra: Extra;
  temas: Array<Theme> = [];
  isInvalidUrl: boolean;
  TipoApresentacao = TipoApresentacao;
  mimeType = null;
  mimeTypeInput = '';
  mimeTypes: MimeTypeEntry[] = [
    { key: 'PDF', value: 'application/pdf' },
    { key: 'JPEG', value: 'image/jpeg' },
    { key: 'PNG', value: 'image/png' },
    { key: 'CUSTOMIZADO', value: 'CUSTOMIZADO' },
  ];

  fileExtra: File | null = null;
  public image: File;

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'underline', 'strike'],        // toggled buttons
        [{ 'size': [] }]
      ],
    }
  }

  constructor(
    private temaService: ThemeService,
    private extraService: ExtraService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private deletaImagemService: DeletaImagemService,
  ) { }

  ngOnInit(): void {
    this.image = null;
    this.resetaTela('rota');
    this.invalidUrl();
  }

  resetaTela(origem: string) {
    this.extra = new Extra();
    this.temaService.themes(undefined, (erro: Erro400, sucesso: Array<Theme>) => {
      if (sucesso) {
        this.temas = sucesso;
      } else if (erro && erro.error) {
        this.toastr.error('', erro.error.message);
      }
    })

    if (origem && origem == 'rota') {
      var uuid = this.route.snapshot.paramMap.get("uuid");
      if (uuid) {
        this.extra = new Extra(history.state.data);
        this.fileExtra = new File([], this.extra.arquivoExtra);
        this.mimeType = this.isMimeTypeCustom(this.extra.mimeType) ? 'CUSTOMIZADO' : this.extra.mimeType;
        this.mimeTypeInput = this.extra.mimeType;
      }
    }
  }

  isMimeTypeCustom(mimeType: string) {
    return mimeType !== 'application/pdf' && mimeType !== 'image/jpeg' && mimeType !== 'image/png';
  }

  cadastrarExtra(bt: string) {
    if(this.mimeType === 'CUSTOMIZADO'){
      this.mimeType = this.mimeTypeInput;
    }
    this.extra.mimeType = this.mimeType;

    if (this.validatePresentation()) {
      this.spinner.show();
      if (this.extra.uuid) {
        this.extraService.editar(this.extra, (erro: Erro400, sucesso: Extra) => {
          if (sucesso) {
            this.extra = new Extra(sucesso);
            this.fileExtra = new File([], this.extra.arquivoExtra);
            this.mimeType = this.isMimeTypeCustom(this.extra.mimeType) ? 'CUSTOMIZADO' : this.extra.mimeType;
            this.mimeTypeInput = this.extra.mimeType;
            this.toastr.success('', 'O conteúdo extra foi atualizado com sucesso');
            if (bt) {
              if (bt == 'NOVO') {
                this.resetaTela('cadastro');
              } else {
                this.router.navigate(['extra'])
              }
            }
          } else if (erro && erro.error) {
            this.toastr.error('', erro.error.message);
          }
          this.spinner.hide();
        });
      } else {
        console.log('extra', this.extra);
        this.extraService.criar(this.extra, (erro: Erro400, sucesso: Extra) => {
          if (sucesso) {
            this.extra = new Extra(sucesso);
            this.fileExtra = new File([], this.extra.arquivoExtra);
            this.mimeType = this.isMimeTypeCustom(this.extra.mimeType) ? 'CUSTOMIZADO' : this.extra.mimeType;
            this.mimeTypeInput = this.extra.mimeType;
            this.toastr.success('', 'O conteúdo extra foi cadastrado com sucesso');
            if (bt) {
              if (bt == 'NOVO') {
                this.resetaTela('cadastro');
              } else {
                this.router.navigate(['extra'])
              }
            }
          } else if (erro && erro.error) {
            this.toastr.error('', erro.error.message);
          }
          this.spinner.hide();
        });
      }
    }
  }

  validatePresentation() {
    if (!this.extra.presentation) {
      this.toastr.error('', 'Campo "Apresentação" é de preenchimento obrigatório');
      return false;
    } else {
      return true;
    }
  }

  uploadImagem(tipo: string) {
    let arquivo: File = null;
    arquivo = this.image;

    this.spinner.show();
    this.extraService.adicionarImagem(
      this.extra,
      tipo,
      arquivo,
      (erro: Erro400, sucesso: Extra) => {
        this.spinner.show();
        if (tipo === "image") {
          if (sucesso) {
            this.extra = new Extra(sucesso);
            this.mimeType = this.extra.mimeType;
            this.mimeTypeInput = this.extra.mimeType;
            this.toastr.success("", "Imagem enviada com sucesso");
          }
          if (erro) {
            if (erro.error && erro.error.message) {
              this.toastr.error("", erro.error.message);
            } else {
              this.toastr.error(
                "",
                "Aconteceu um erro ao tentar substituir a imagem, entre em contato com o suporte ou tente novamente mais tarde"
              );
            }
          }
          this.spinner.hide();
        }
      }
    );
  }

  onSelectFile(event, tipo: string) {
    if (event.target.files && event.target.files[0]) {
      if (tipo === "image") {
        this.image = event.target.files[0];
      }
    }
  }


  onDeletaImagem(uuid: string) {
    this.spinner.show();
    this.spinner.show();
    this.deletaImagemService.deletaImagem(uuid, "EXTRA").subscribe(

      (sucess) => {
        this.spinner.hide();
        this.extra.picture = null;
        this.toastr.success("Imagem removida com sucesso!");
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
      }
    )
  }

  invalidUrl() {
    if (this.extra.url) {
      if (this.extra.url.includes('http')) {
        this.isInvalidUrl = !this.extra.url.includes('https')

      } else {
        this.isInvalidUrl = false;
      }
    }
    return false;
  }

  isEnableArquivo() {

    if (this.extra.url != null && this.extra.url != undefined) {
      return this.extra.uuid && this.extra.url.trim().length < 1 && this.extra.typeApresentation === 'AMBIENTE_INTERNO';
    }
    return false;
  }

  isMimeTypeCustomizado() {
    return this.mimeType === 'CUSTOMIZADO';
  }

  onFileSelected(event: any) {
    this.fileExtra = event.target.files[0] || null;
  }

  enviarAquivoExtra() {
    if (this.fileExtra.name !== this.extra.arquivoExtra) {
      this.extraService.enviaArquivoExtra(this.extra.uuid, this.fileExtra).subscribe(
        (sucess: ExtraArquivoDTO) => {
          this.fileExtra = new File([],sucess.arquivoExtra);
          this.toastr.success("Arquivo enviado com sucesso!");
          this.extra.arquivoExtra = sucess.arquivoExtra;
        },
        (err) => {
          this.toastr.error(err.message);
        }
      )
    }
  }
  removerArquivoExtra(){
    if(this.extra.uuid && this.extra.arquivoExtra){
      this.extraService.removerArquivoExtra(this.extra.uuid).subscribe(
        () => {
          this.toastr.success("Arquivo removido com sucesso!");
          this.fileExtra = new File([], "");
        },
        () => {
          this.toastr.error("Erro ao remover arquivo!");
        }
      );
    }
  }
}
