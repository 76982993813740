import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UnidadeService } from 'src/app/services/unidade.service';
import { Unidade } from '../../../dto/unidade';

enum AbaPrincipal{
  AREA = "area",
  SUBUNIDADE = "subuni",
  NIVEL3="nivel3"
}

@Component({
  selector: "app-area-form",
  templateUrl: "./area-form.component.html",
  styleUrls: ["./area-form.component.css"],
})
export class AreaFormComponent implements OnInit {
  @Input() unidade: Unidade;
  @Output() onCreateUnidadeSave = new EventEmitter<Unidade>();
  @Output() onCreateUnidadeSaveAndNew = new EventEmitter<Unidade>();
  @Output() onCreateUnidadeSaveAndList = new EventEmitter<Unidade>();
  @Output() onRemoveSubunidade = new EventEmitter<Unidade>();
  subareaSelect: Unidade;

  AbaPrincipal = AbaPrincipal;
  abaSelecionada: AbaPrincipal = AbaPrincipal.AREA;
  constructor(private unidadeService: UnidadeService) {}

  ngOnInit() {}

  cadastrarUnidade() {
    this.onCreateUnidadeSave.emit(this.unidade);
  }
  isChecked(unidade: Unidade) {
    if (unidade.active) {
      return "checked";
    } else {
      return "";
    }
  }

  mudaAbaPrincipal(aba: AbaPrincipal) {
    this.abaSelecionada = aba;
  }

  deleteSubunidade(unidade: Unidade) {
    this.onRemoveSubunidade.emit(unidade);
  }

  createUnidadeAndNew() {
    this.onCreateUnidadeSaveAndNew.emit(this.unidade);
  }

  createUnidadeAndList() {
    this.onCreateUnidadeSaveAndList.emit(this.unidade);
  }

  selectSubUnidade(unidade: Unidade) {
    this.subareaSelect = unidade;
  }
}
