import { TipoDinamica } from './tipoDinamica';
export class Dinamica {
  uuid: string;
  name: string;
  theme: string;
  type: TipoDinamica;

  constructor(dinamica: Dinamica = null) {
    if (dinamica) {
      this.uuid = dinamica.uuid;
      this.name = dinamica.name;
      this.theme = dinamica.theme;
      this.type = dinamica.type;
    }

  }
  setType(typo: string){
    switch (typo) {
      case 'FACT_OR_FAKE':
        this.type = TipoDinamica.TIPO_FATO_FAKE;
        break;
      case 'GAME':
        this.type = TipoDinamica.TIPO_GAME;
        break;
      case 'VIDEO':
        this.type = TipoDinamica.TIPO_VIDEO;
        break;
      case 'NEW_HABIT':
        this.type = TipoDinamica.TIPO_NOVO_HABITO;
        break;
      case 'EXTRA':
        this.type = TipoDinamica.TIPO_EXTRA;
        break;
      case 'MURAL':
        this.type = TipoDinamica.TIPO_MURAL;
    }
  }
}
