import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Erro400 } from 'src/app/dto/erros';
import { Theme } from 'src/app/dto/theme';
import { Video } from 'src/app/dto/video';
import { ThemeService } from 'src/app/services/theme.service';
import { VideoService } from 'src/app/services/video.service';

@Component({
    selector: 'app-video-form',
    templateUrl: './video-form.component.html',
    styleUrls: ['./video-form.component.css']
  })
export class VideoFormComponent implements OnInit{
  video: Video;
  temas: Array<Theme>;

  

  quillConfig={
    toolbar: {
      container: [
        ['bold', 'underline', 'strike'],        // toggled buttons
        [{ 'size': [] }]
      ],
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private temaService: ThemeService,
    private videoService: VideoService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    
    this.resetaTela('rota');
  }

  resetaTela(origem: string){
    this.video = new Video();
    this.temaService.themes(undefined, (erro: Erro400, sucesso: Array<Theme>) => {
      if(sucesso){
        this.temas = sucesso;
      }else if(erro && erro.error){
        this.toastr.error('', erro.error.message);
      }
    })

    if(origem && origem == 'rota'){
      var uuid = this.route.snapshot.paramMap.get("uuid");
      if(uuid){
        this.video = new Video(history.state.data);
      }
    }
  }

 

  cadastrarVideo(bt: string){
    if(this.validatePresentation()){
      this.spinner.show();
      if(this.video.uuid){
        this.videoService.editar(this.video, (erro: Erro400, sucesso: Video) => {
          if(sucesso){
            this.video = new Video(sucesso);
            this.toastr.success('', 'O video foi atualizado com sucesso');
            if(bt){
              if(bt == 'NOVO'){
                this.resetaTela('cadastro');
              }else{
                this.router.navigate(['video'])
              }
            }
          }else if(erro && erro.error){
            this.toastr.error('', erro.error.message);
          }
          this.spinner.hide();
        });
      }else{
        this.videoService.criar(this.video, (erro: Erro400, sucesso: Video) => {
          if(sucesso){
            this.video = new Video(sucesso);
            this.toastr.success('', 'O video foi cadastrado com sucesso');
            if(bt){
              if(bt == 'NOVO'){
                this.resetaTela('cadastro');
              }else{
                this.router.navigate(['video'])
              }
            }
          }else if(erro && erro.error){
            this.toastr.error('', erro.error.message);
          }
          this.spinner.hide();
        });
      }
    }
  }



  validatePresentation(){
    if(!this.video.presentation){
      this.toastr.error('', 'Campo "Apresentação" é de preenchimento obrigatório');
      return false;
    }else{
      return true;
    }
  }

 

}