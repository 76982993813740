interface ISubtitle {
  uuid: string;
  subtitle: string;
  ordem: number;
}

export class Subtitle {
  uuid: string;
  subtitle: string;
  ordem: number;

  constructor(subtille: ISubtitle ) {
    if (subtille) {
      this.uuid = subtille.uuid;
      this.subtitle = subtille.subtitle;
      this.ordem = subtille.ordem;
    }
  }


}
