import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import ArchiveStatus from 'src/app/dto/elegiblidade-async/ArchiveStatus';
import EligibilityStatusPage from '../../../dto/elegiblidade-async/EligibilityStatusPage';

@Component({
  selector: 'app-arquivos',
  templateUrl: './arquivos.component.html',
  styleUrls: ['./arquivos.component.css']
})
export class ArquivosComponent implements OnInit {

  @Input()
  eligibilityStatusPage: EligibilityStatusPage = new EligibilityStatusPage();

  @Input()
  lockLoadMore = false;

  @Input()
  lockUpdate = false;

  @Output()
  loadMore = new EventEmitter<void>();

  @Output()
  update = new EventEmitter<void>();

  @Output()
  selectArchiveEligibility = new EventEmitter<ArchiveStatus>();

  constructor() { }

  ngOnInit() {
  }

  onLoadMore() {
    this.loadMore.emit();
  }

  onUpdate() {
    this.update.emit();
  }

  onSelectArchive(arquivoElegibilidade: ArchiveStatus) {

    if(arquivoElegibilidade.status === 'Erro'){
      this.selectArchiveEligibility.emit(arquivoElegibilidade);
    }

    

  }
}
