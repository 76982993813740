import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ComoFunciona } from 'src/app/dto/comoFunciona';
import { ComoFuncionaService } from 'src/app/services/como-funciona.service';

@Component({
  selector: 'app-como-funciona-list-form',
  templateUrl: './como-funciona-list-form.component.html',
  styleUrls: ['./como-funciona-list-form.component.css']
})
export class ComoFuncionaListFormComponent implements OnInit{
  comoFunciona: ComoFunciona;

  name = 'Angular';
  quillConfig={
    toolbar: {
      container: [
        ['bold', 'underline', 'strike'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'align': [] }],
        // ['image'],
        // ['video']
      ],
    }
  }

  constructor(
    private comoFuncionaService:ComoFuncionaService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.resetaTela('rota');
  }

  resetaTela(origem: string){
    this.comoFunciona = new ComoFunciona();
 
    if(origem && origem == 'rota'){
      var uuid = this.route.snapshot.paramMap.get("uuid");
      if(uuid){
        this.comoFunciona = new ComoFunciona(history.state.comoFunciona);
      }
    }
  }

  cadastrarComoFunciona(str: string){
    this.spinner.show();
    this.comoFuncionaService.criar(this.comoFunciona, (error: any, sucesso: ComoFunciona) =>{
      if (sucesso){
        this.comoFunciona = new ComoFunciona(sucesso);
        this.toastr.success('', 'A mensagem foi editado com sucesso');
        if(str){
          if(str == 'NOVO'){
            this.resetaTela('cadastro');
          }else{
            this.router.navigate(['comoFunciona'])
          }
        }
      }else{
        if(error){
          if(error.error && error.error.message){
            this.toastr.error('', error.error.message);
          } else{
            this.toastr.error('', 'Falha ao editar mensagem');
          }
        }
      }
      this.spinner.hide();
    });
  }
}



