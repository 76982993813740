import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Erro400 } from 'src/app/dto/erros';
import { Gestor } from 'src/app/dto/gestor';
import { GestorAction } from 'src/app/dto/gestorAction';
import { User } from 'src/app/dto/user';
import { UsuarioFiltros } from 'src/app/dto/usuarioFiltros';
import { EventService } from 'src/app/services/evento.service';

@Component({
  selector: 'app-gestores',
  templateUrl: './gestores.component.html',
  styleUrls: ['./gestores.component.css']
})
export class GestoresComponent implements OnInit {

  @Input() codigoEvento: string;
  users: User[] = [];
  filters: UsuarioFiltros = new UsuarioFiltros();
  search: string = '';
  user: User = new User();
  userCampanha: User[] = [];
  gestorAction: GestorAction;
  constructor(
    private eventoService: EventService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {

  }

  ngOnInit() {
    if(this.codigoEvento){
      this.eventoService.getGestoresCampanha(this.codigoEvento, (erro: Erro400, sucesso: Gestor[]) => {
        if (sucesso) {
          
          this.userCampanha = sucesso.map(x =>{
          var user =  new User();
          user.uuid = x.user;
          user.name = x.name;
          user.email = x.email;
          return user;
          });

        } else if (erro && erro.error) {
          this.toastr.error('', erro.error.message);
        }
      })
    }
  }

  searchUsers() {
    if (this.search.length >= 3) {
      this.filters.firstName = this.search;
      this.filters.profileName = "Gestor";
      var uuid = this.route.snapshot.paramMap.get("uuid");
      if (uuid) {
        this.eventoService.getGestores(uuid, this.filters, (erro: Erro400, sucesso: User[]) => {
          if (sucesso) {
            this.users = sucesso;

          } else if (erro && erro.error) {
            this.toastr.error('', erro.error.message);
          }
        })
      }

    }else{
      this.users = [];
    }
  }

  chosenUser(evento: any) {
    this.user = this.users.find(x => x.uuid == evento.target.value);
    this.search = this.user.name;
    this.users = [];

  }

  adicionarGestor() {
    if (this.userCampanha.find(x => x.uuid === this.user.uuid)===undefined && this.user.uuid) {
      this.gestorAction = new GestorAction();
      this.gestorAction.action = "ADD";
      this.gestorAction.user = this.user.uuid;
      this.actionGestor();
      this.userCampanha.push(this.user);
    }
    this.search = '';
  }

  removerUsuario(user: User) {
    const index: number = this.userCampanha.findIndex(x => x.uuid === user.uuid);
    if (index !== -1) {
      console.log(index);
      this.gestorAction = new GestorAction();
      this.gestorAction.action = "REMOVE";
      this.gestorAction.user = this.userCampanha[index].uuid;
      this.actionGestor();
      this.userCampanha.splice(index, 1);
    }
  }

  actionGestor(){
    var codigo = this.codigoEvento;   
      if (codigo) {
        this.eventoService.updateGestor(codigo, this.gestorAction, (erro: Erro400,) => {
          if (erro && erro.error) {
            this.toastr.error('', erro.error.message);
          }
        })
      }
  }

}
