import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Unidade } from 'src/app/dto/unidade';
import { UnidadeService } from 'src/app/services/unidade.service';

@Component({
  selector: "app-subarea-form",
  templateUrl: "./subarea-form.component.html",
  styleUrls: ["./subarea-form.component.css"],
})
export class SubareaFormComponent implements OnInit {
  @Output() onRemoveSubunidade = new EventEmitter<Unidade>();
  @Output() selectSubUnidade = new EventEmitter<Unidade>();
  @Input() unidadeMae: Unidade;
  @Input() unidadeAvo: Unidade;
  @Input() nivel: string;

  selectUnit = new Unidade();
  unidade = new Unidade();

  constructor(
    private unidadeService: UnidadeService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  persistirSubunidade() {
    this.unidade.parent = this.unidadeMae.uuid;
    this.unidadeService.criar(
      this.unidadeMae.client.uuid,
      this.unidade,
      (erro, unidade: Unidade) => {
        if (erro) {
          if (erro.error) {
            this.toastr.error("", erro.error.message);
          }
        } else {
          let index = this.unidadeMae.subUnits.findIndex(
            (subUnidade) => subUnidade.uuid == unidade.uuid
          );
          if (index != -1) {
            this.unidadeMae.subUnits[index] = unidade;
          } else {
            this.unidadeMae.subUnits.push(unidade);
          }

          this.unidade = new Unidade();
        }
      }
    );
  }

  deleteSubunidade(unidade: Unidade) {
    this.unidadeMae.subUnits = this.unidadeMae.subUnits.filter(
      (subUnidade) => subUnidade.uuid != unidade.uuid
    );
    this.onRemoveSubunidade.emit(unidade);
  }

  editSubunidade(unidade: Unidade) {
    this.selectUnit = unidade;
    this.unidade = new Unidade(unidade);
    if (this.nivel === "subarea") {
      this.selectSubUnidade.emit(unidade);
    }
  }

  get isChecked(): boolean {
    return this.unidade.active;
  }
}
