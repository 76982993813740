import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import DinamicaPadrao from 'src/app/dto/dinamicaPadrao';
import { Theme } from 'src/app/dto/theme';
import { ExtraService } from 'src/app/services/extra.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
    selector: 'app-extra-list',
    templateUrl: './extra-list.component.html',
    styleUrls: ['./extra-list.component.css']
  })
export class ExtraListComponent implements OnInit{
  themes: Theme[]
  name: string = ""
  code: string = ""
  theme: string = ""
  extras: DinamicaPadrao[]
  textoBuscar: string = "Buscar"
  classeBuscar: string = "button is-primary"

  constructor(private extraService: ExtraService, private themeService: ThemeService, private router: Router){}

  ngOnInit(): void {
    this.name = ""
    this.themeService.themes(null, (erro: any, temasRetornados: Theme[]) => {
      this.themes = temasRetornados
    })
    this.buscar()  
  }

  buscar(){
    this.textoBuscar = "Buscando..."
    this.classeBuscar = "button is-light"

    this.extraService.buscar(this.name, this.theme, this.code, (erro: any, resultado: DinamicaPadrao[]) => {
      if(resultado){
          this.extras = resultado
          this.textoBuscar = "Buscar"
          this.classeBuscar = "button is-primary"
      }
    })
  }

  edit(e: DinamicaPadrao){
    this.router.navigate(['extra/edit/' + e.uuid], {state: {data: e}});
  }

}