import { Component, Input, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Erro400 } from "src/app/dto/erros";
import { EventDto } from "src/app/dto/event";
import { FieldDto } from "src/app/dto/field";
import { EventService } from "src/app/services/evento.service";

@Component({
    selector: 'app-campos-form',
    templateUrl: './campos-form.component.html',
    styleUrls: ['./campos-form.component.css']
  })
export class CamposFormComponent implements OnInit{
  @Input() evento: EventDto;
  
  campo: FieldDto;
  modoInsercao: Boolean = true;
  existeCampoAutenticacao: Boolean = true;

  public listaCampos: Array<FieldDto>;

  public tiposCampo: Array<any> = [
    {value:'NOME', name:'Nome'}, 
    {value:'SOBRENOME', name:'Sobrenome'}, 
    {value:'EMAIL', name:'E-mail'},
    {value:'SENHA', name:'Senha'}, 
    {value:'IDADE', name:'Idade'}, 
    {value:'MATRICULA', name:'Matrícula'} 
  ]

  constructor(
    private eventoService: EventService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ){}

  ngOnInit(): void {
      this.existeCampoAutenticacao = false;
      this.campo = new FieldDto();
      this.listaCampos = [];
      if (this.evento.fields) {
        for (var i = 0; i < this.evento.fields.length; i++) {
          if (this.evento.fields[i].field === 'MATRICULA' || this.evento.fields[i].field === 'EMAIL') {
            this.existeCampoAutenticacao = true;
          }
          this.listaCampos.push(this.evento.fields[i])
        }
    }
  }

  cadastrar(){
    this.spinner.show()
    this.eventoService.salvarCampos(this.evento.uuid, this.campo, (erro: Erro400, sucesso: FieldDto) => {
        if(sucesso){
            let edicao: boolean = false;
            for(let i=0; i<this.listaCampos.length; i++){
                if(this.listaCampos[i].field === sucesso.field){
                  this.listaCampos[i] = sucesso;
                  edicao = true;
                }
            }
            if(!edicao){
              this.listaCampos.push(sucesso)
            }
            
            this.ordenaCampos();
            this.validaCampoAutenticacao();
            this.evento.fields = this.listaCampos;
            
            this.campo = new FieldDto();
            this.toastr.success('', 'O campo foi cadastrado com sucesso');
            this.modoInsercao = true;
        }else if(erro && erro.error){
            this.toastr.error('', erro.error.message);
        }
        this.spinner.hide()
    });
  }

  editar(campo: FieldDto){
    this.campo = new FieldDto(campo);
    this.modoInsercao = false;
  }

  changeField() {
    if (this.campo.field) {
      let ordem = 0;
      let novo = true;
      this.listaCampos.forEach(campo => {
        if (campo.field === this.campo.field) {
          novo = false
          this.editar(campo);
          return;
        }
        if (ordem < campo.order) {
          ordem = campo.order;
        }
      })
      
      if (!novo) {
        return;
      } else {
        this.modoInsercao = true;
        this.campo.order = ordem+1
      }

      this.tiposCampo.forEach(campo => {
        if (campo.value === this.campo.field) {
          this.campo.label = campo.name
          return;
        }
      })
    }
  }

  remover(campo: FieldDto){
    if(!confirm("Deseja realmente remover o campo '" + campo.label + "'?")){
        return;
    }
    this.spinner.show()
    this.eventoService.removerCampo(this.evento.uuid, campo.field, (erro: Erro400, sucesso: any) => {
        if(erro){
            this.toastr.error('', erro.error.message);
        }else{
            for(let i=0; i<this.listaCampos.length; i++){
                if(this.listaCampos[i].field === campo.field){
                    this.listaCampos.splice(i, 1)
                    
                }
            }
            this.campo = new FieldDto();
            this.ordenaCampos();
            this.validaCampoAutenticacao();
            this.evento.fields = this.listaCampos
            this.modoInsercao = true;
            this.toastr.success('', 'O campo foi removido com sucesso');
            
        }
        this.spinner.hide()
    })
  }

  cancelar() {
    this.campo = new FieldDto();
    this.modoInsercao = true;
  }

  ordenaCampos() {
    if (this.listaCampos.length > 0){
      this.listaCampos = this.listaCampos.sort(function compare(campo1: FieldDto, campo2: FieldDto){
        if (campo1.order > campo2.order) {
          return 1
        }
        if (campo1.order < campo2.order) {
          return -1
        }
        return 0
      });
    }
  }

  validaCampoAutenticacao(){
    this.existeCampoAutenticacao = false;
    if (this.listaCampos) {
      this.listaCampos.forEach(campo => {
        if (campo.field == 'MATRICULA' || campo.field == 'EMAIL') {
          this.existeCampoAutenticacao = true;
          return;
        }
      })
    }
  }
}