import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Agenda, AgendaAtividade, AgendaDia, AgendaDinamica, AgendaItemRequest, AgendaRequest } from 'src/app/dto/agenda';
import { Erro400 } from 'src/app/dto/erros';
import { EventDto } from 'src/app/dto/event';
import { TipoDinamica } from 'src/app/dto/tipoDinamica';
import { AgendaService } from 'src/app/services/agenda.service';
import { DinamicaService } from 'src/app/services/dinamica.service';
import { Subtitle } from '../../dto/Subtitle';

@Component({
  selector: 'app-agenda-dinamica',
  templateUrl: './agenda-dinamica.component.html',
  styleUrls: ['./agenda-dinamica.component.css']
})
export class AgendaDinamicaComponent implements OnInit {

  arquivo: File;
  tipo: string;
  agenda: Agenda;
  request: AgendaRequest;
  confirmaRemocaoComAtividadeJaFeita: string;
  testeClick: boolean;
  modalAviso: boolean;
  confirm: boolean;
  activities: AgendaItemRequest[] = [];

  @Input() evento: EventDto;
  @Input() dia: AgendaDia;
  @Input() dinamica: AgendaAtividade;
  @Input() subtitleCurrent: Subtitle;

  @Output() agendaEditada = new EventEmitter();
  @Output() receberAgendaAoExcluirDinamica = new EventEmitter();
  @Output() dinamicaEdicao = new EventEmitter();
  @Output() respondeComAtivacaoDoModal = new EventEmitter();
  @Output() mandandoAtividadeASerExcluida = new EventEmitter();


  constructor(
    private agendaService: AgendaService,
    private dinamicaService: DinamicaService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    let atividades = this.dia.activities.filter(a => a.order === 0);
    if (atividades.length === this.dia.activities.length) {
      let order = 0;
      this.dia.activities.forEach ( a => {
        a.order = order;
        order++;
      });
    }else{
      if(this.dia.subtitles && this.dia.subtitles.length > 0){
        this.dia.subtitles.forEach(s => {
          let atividades = this.dia.activities.filter(a => a.subtitle && a.subtitle !== null && a.subtitle.uuid && a.subtitle.uuid !== null && a.subtitle.uuid === s.uuid);
          atividades.sort((a, b) => a.order - b.order);
          let order = 0;
          atividades.forEach ( a => {
            a.order = order;
            order++;
          });

        });
      }else{
        this.dia.activities.sort((a, b) => a.order - b.order);
        let order = 0;
        this.dia.activities.forEach ( a => {
          a.order = order;
          order++;
        });
      }
    }
  }

  ableToUp(){
    let able: boolean = true;
    if(this.subtitleCurrent && this.subtitleCurrent.uuid && this.subtitleCurrent.uuid !== null){
      const atividadeSubunidades = this.dia.activities.filter(a => a.subtitle && a.subtitle !== null && a.subtitle.uuid && a.subtitle.uuid !== null && a.subtitle.uuid === this.subtitleCurrent.uuid);
      for(var i=0; i< atividadeSubunidades.length; i++){
        if(atividadeSubunidades[i].uuid === this.dinamica.uuid){
          if(i === 0 ){
            able = false;
          }
        }
      }
    }else{
      for(var i=0; i<this.dia.activities.length; i++){
        if(this.dia.activities[i].uuid === this.dinamica.uuid){
          if(i === 0 ){
            able = false;
          }
        }
      }

    }
    return able
  }

  ableToDown(){
    let able: boolean = true;

    if(this.subtitleCurrent && this.subtitleCurrent.uuid && this.subtitleCurrent.uuid !== null){
      const atividadesSubtitulos = this.dia.activities.filter(a => a.subtitle && a.subtitle !== null &&  a.subtitle.uuid && a.subtitle.uuid !== null && a.subtitle.uuid === this.subtitleCurrent.uuid);
      for(var i=0; i<atividadesSubtitulos.length; i++){
        if(atividadesSubtitulos[i].uuid === this.dinamica.uuid){
          if(i === (atividadesSubtitulos.length - 1)){
            able = false;
          }
        }

      }
    }else{
      for(var i=0; i<this.dia.activities.length; i++){
        if(this.dia.activities[i].uuid === this.dinamica.uuid){
          if(i === (this.dia.activities.length - 1)){
            able = false;
          }
        }
      }
    }

    return able
  }

  up() {
    let subtitleUuid =
      this.subtitleCurrent && this.subtitleCurrent.uuid
        ? this.subtitleCurrent.uuid
        : null;

    const atividadesSubtitulos = subtitleUuid
      ? this.dia.activities.filter(
          (a) => a.subtitle != null && a.subtitle.uuid === subtitleUuid
        )
      : this.dia.activities;

    const dinamicaUuid = this.dinamica.uuid;
    atividadesSubtitulos.forEach((atual, i) => {
      if (atual.uuid === dinamicaUuid) {
        const anterior = atividadesSubtitulos[i - 1];
        if (anterior && anterior.order === atual.order - 1) {
          const ordemAnterior = anterior.order;
          const ordemAtual = atual.order;
          atual.order = ordemAnterior;
          anterior.order = ordemAtual;
        }
      }
    });

    this.save();
  }

  down() {
    let subtitleUuid =
      this.subtitleCurrent && this.subtitleCurrent.uuid
        ? this.subtitleCurrent.uuid
        : null;

    const atividadesSubtitulos = subtitleUuid
      ? this.dia.activities.filter(
          (a) => a.subtitle != null && a.subtitle.uuid === subtitleUuid
        )
      : this.dia.activities;

    const dinamicaUuid = this.dinamica.uuid;

    atividadesSubtitulos.forEach((atual, i) => {
      if (atual.uuid === dinamicaUuid) {
        const posterior = atividadesSubtitulos[i + 1];

        if (posterior && posterior.order === atual.order + 1) {
          const ordemPosterior = posterior.order;
          const ordemAtual = atual.order;
          atual.order = ordemPosterior;
          posterior.order = ordemAtual;
        }
      }
    });

    this.save();
  }

  save(){
    this.request = new AgendaRequest();
    this.request.uuid = this.dia.uuid;
    this.request.day = this.dia.day;
    this.request.required = this.dia.required;
    this.request.order = this.dia.order;
    this.validarSubtitulos();
    this.dia.activities.forEach(atividade => {
      let addAtividade = new AgendaItemRequest(atividade);

      this.activities.push({...addAtividade});
    });

    this.request.activities = this.activities;
    this.request.subtitles = this.dia.subtitles;
    this.spinner.show();
    this.agendaService.editar(this.evento.uuid, this.request, (erro: Erro400, sucesso: Agenda) => {
      if (sucesso) {
        this.agenda = sucesso;
        this.retornarAgenda();
      } else if (erro && erro.error) {
        this.toastr.error('', erro.error.message);
      }
      this.spinner.hide();
    });
  }
  validarSubtitulos() {
    if (this.dia.subtitles && this.dia.subtitles.length > 0) {
      let atividadesComSubtitulo = this.dia.activities.filter(a => a.subtitle && a.subtitle !== null)
      this.dia.activities = atividadesComSubtitulo;
    }
  }

  upload() {
    this.spinner.show();
    let tipo = '';
    if (this.tipo === 'HABILITADA') {
      switch (this.dinamica.type) {
        case TipoDinamica.TIPO_FATO_FAKE:
          tipo = 'FACT_OR_FAKE';
          break;
        case TipoDinamica.TIPO_GAME:
          tipo = 'GAME';
          break;
        case TipoDinamica.TIPO_VIDEO:
          tipo = 'VIDEO';
          break;
        case TipoDinamica.TIPO_NOVO_HABITO:
            tipo = "NEW_HABIT";
            break;
        case TipoDinamica.TIPO_EXTRA:
            tipo = "EXTRA";
            break;
        case TipoDinamica.TIPO_MURAL:
            tipo = "MURAL";
            break;
        default:
          break;
      }
    } else {
      switch (this.dinamica.type) {
        case TipoDinamica.TIPO_FATO_FAKE:
          tipo = 'FACT_OR_FAKE_DISABLED';
          break;
        case TipoDinamica.TIPO_GAME:
          tipo = 'GAME_DISABLED';
          break;
        case TipoDinamica.TIPO_VIDEO:
          tipo = 'VIDEO_DISABLED';
          break;

        case TipoDinamica.TIPO_NOVO_HABITO:
          tipo = "NEW_HABIT_DISABLED";
          break;
        case TipoDinamica.TIPO_EXTRA:
          tipo = "EXTRA_DISABLED";
          break;
          case TipoDinamica.TIPO_MURAL:
            tipo = "MURAL_DISABLED";
            break;
        default:
          break;
      }
    }

    this.agendaService.upload(this.evento.uuid, this.dia.uuid, tipo, this.arquivo, this.dinamica.uuidDynamic, (erro: Erro400, sucesso: Agenda) => {
      if (sucesso) {
        this.toastr.success('', 'Arquivo enviado com sucesso');
        this.agenda = sucesso;
        this.retornarAgenda();
      } else if ( erro && erro.error) {
        this.toastr.error('', erro.error.message);
      } else {
        this.toastr.error('', 'Aconteceu um erro ao tentar enviar a imagem, por favor, contate o responsável pelo sistema');
      }
      this.spinner.hide();
    });
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.arquivo = event.target.files[0];
    }
  }

  retornarAgenda() {
    this.agendaEditada.emit(this.agenda);
  }

  receberExcluirDinamica(){
    this.receberAgendaAoExcluirDinamica.emit(this.request)
  }

  editarDinamica() {
    const dinamica = new AgendaDinamica();
    dinamica.dynamic = this.dinamica.uuidDynamic;
    dinamica.theme = this.dinamica.theme.uuid;
    dinamica.type = this.dinamica.type;
    dinamica.uuid = this.dinamica.uuid;
    dinamica.order = this.dinamica.order;
    this.dinamicaEdicao.emit(dinamica);
  }

   retornarAviso(){
    this.mandandoAtividadeASerExcluida.emit(this.dinamica)
    this.respondeComAtivacaoDoModal.emit(this.modalAviso = true)
  }

  handleExclusao(confirmacao: string){
    this.spinner.show();
    this.request = new AgendaRequest();
    this.request.uuid = this.dia.uuid;
    this.request.day = this.dia.day;
    let activityUuid;
    let activity;
    this.dia.activities.forEach(atividade => {
      if (atividade.uuid === this.dinamica.uuid) {
        activityUuid = atividade.uuid;
        activity = atividade
      }
    });



    this.dinamicaService.deletar(this.evento.uuid, this.request, activityUuid, confirmacao, (erro: any, sucesso: Agenda) => {
      if(erro){
        if(erro.error){
          if (erro.status === 400 && confirmacao !== 'true') {
            return this.retornarAviso();
          } else {
            this.toastr.error('', erro.error.message ? erro.error.message : 'Ocorreu um erro ao tentar excluir a atividade. Entre em contato com o suporte.');
          }
        }
      } else{
        this.agenda = sucesso;
        this.toastr.success('', 'Dinâmica excluída com sucesso');
        this.receberExcluirDinamica();
        for(var i=0; i<this.dia.activities.length; i++){
          var dinamicaExcluir: AgendaAtividade = this.dia.activities[i];
          if(dinamicaExcluir.uuid === this.dinamica.uuid){
            this.dia.activities.splice(i, 1);
          }
        }
      }
      this.spinner.hide();
    });
  }

  excluirDinamicaDeAtividadeJaRealizada(uuid: AgendaAtividade){
    this.confirmaRemocaoComAtividadeJaFeita = "true";
    this.dinamica = uuid;
    this.handleExclusao(this.confirmaRemocaoComAtividadeJaFeita)
  }

  excluirDinamica() {
    if (! confirm('Tem certeza que deseja excluir a dinâmica ' + this.dinamica.name + '?')) {
      return;
    }
    this.confirmaRemocaoComAtividadeJaFeita = "false";
    this.handleExclusao(this.confirmaRemocaoComAtividadeJaFeita)
  }

  imagemDinamica() {
    switch (this.dinamica.type) {
      case TipoDinamica.TIPO_FATO_FAKE:
        return '../../../assets/images/fatooufake.png';
      case TipoDinamica.TIPO_GAME:
        return '../../../assets/images/jogos.png';
      case TipoDinamica.TIPO_VIDEO:
        return '../../../assets/images/videos.png';
      case TipoDinamica.TIPO_NOVO_HABITO:
        return '../../../assets/images/novos-habitos.png';
      case TipoDinamica.TIPO_EXTRA:
        return '../../../assets/images/extra.png';
      case TipoDinamica.TIPO_MURAL:
          return '../../../assets/images/mural.png';
      default:
        return '';
    }
  }
  isAtividadeSubtitulo(){

    if(this.subtitleCurrent != null){
    if((this.dinamica != null && this.dinamica.subtitle != null) && this.subtitleCurrent.uuid != null){
      return this.dinamica.subtitle.uuid === this.subtitleCurrent.uuid;

    }else if(this.dinamica.subtitle == null && this.subtitleCurrent.uuid == null){
      return true;
    }else if((this.dinamica.subtitle != null && this.dinamica.subtitle.uuid == null) && this.subtitleCurrent.subtitle != null && this.subtitleCurrent.uuid == null){
      return true;
    }
    return false;
  }else{
    return true;
  }

  }
}
