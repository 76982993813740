import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LoginService } from "./login.service";
import { MensagemVideo, MensagemVideoDTO } from "../dto/mensagem-video";
import { Event, EventDto } from "../dto/event";
import { Erro400 } from "../dto/erros";

@Injectable({
	providedIn: "root",
})
export class MensagemVideoService {
	private apiHost: string = environment.apiHost;

	constructor(private http: HttpClient, private loginService: LoginService) {}

	public listarTodos(event: String, callback: Function) {
		this.http
			.get(this.apiHost + "/api/v1/event/" + event + "/video-message", {
				headers: this.loginService.authorizationHeader(),
			})
			.subscribe(
				(sucesso: Array<MensagemVideo>) => {
					callback(null, sucesso);
				},
				(falha: Erro400) => {
					callback(falha, null);
				}
			);
	}

	public criar(
		mensagemVideo: MensagemVideoDTO,
		event: String,
		callback: Function
	) {
		mensagemVideo.formatarDatasParaEnvio();
		this.http
			.post(
				this.apiHost + "/api/v1/event/" + event + "/video-message",
				mensagemVideo,
				{ headers: this.loginService.authorizationHeader() }
			)
			.subscribe(
				(sucesso: MensagemVideo) => {
					callback(null, sucesso);
				},
				(falha: Erro400) => {
					callback(falha, null);
				}
			);
	}

	public editar(
		mensagemVideo: MensagemVideoDTO,
		event: String,
		callback: Function
	) {
		mensagemVideo.formatarDatasParaEnvio();
		this.http
			.put(
				this.apiHost +
					"/api/v1/event/" +
					event +
					"/video-message/" +
					mensagemVideo.uuid,
				mensagemVideo,
				{ headers: this.loginService.authorizationHeader() }
			)
			.subscribe(
				(sucesso: MensagemVideo) => {
					callback(null, sucesso);
				},
				(falha: Erro400) => {
					callback(falha, null);
				}
			);
	}

	public apagar(
		mensagemVideo: MensagemVideoDTO,
		event: String,
		confirmar: boolean = false,
		callback: Function
	) {
		this.http
			.delete(
				this.apiHost +
					"/api/v1/event/" +
					event +
					"/video-message/" +
					mensagemVideo.uuid + "?confirm=" + confirmar,
				{ headers: this.loginService.authorizationHeader() }
			)
			.subscribe(
				(sucesso: MensagemVideo) => {
					callback(null, sucesso);
				},
				(falha: Erro400) => {
					callback(falha, null);
				}
			);
	}

	public adicionarImagem(
		mensagemVideo: MensagemVideoDTO,
		event: String,
		tipo: string,
		file: File,
		callback: Function
	) {
		var formdata: FormData = new FormData();
		formdata.append("file", file);

		let params = new HttpParams();
		params = params.set("type", tipo);

		this.http
			.post(
				this.apiHost +
					"/api/v1/event/" +
					event +
					"/video-message/" +
					mensagemVideo.uuid,
				formdata,
				{ headers: this.loginService.authorizationHeader() }
			)
			.subscribe(
				(sucesso: MensagemVideo) => {
					callback(null, sucesso);
				},
				(falha: Erro400) => {
					callback(falha, null);
				}
			);
	}
}
