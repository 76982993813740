import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Erro400 } from 'src/app/dto/erros';
import { KitDiaDto, KitDinamicaDto, KitDto } from 'src/app/dto/kit';
import { KitService } from 'src/app/services/kit.service';

@Component({
    selector: 'app-kist-form',
    templateUrl: './kit-form.component.html',
    styleUrls: ['./kit-form.component.css']
  })
export class KitFormComponent implements OnInit{
    kit: KitDto;
    classeAbaDados: string;
    classeAbaImagens: string;
    diaAtivo: number;

    ultimoDiaInserido: number;

    dinamicaAtiva: string;

    constructor(
      private kitService: KitService, 
      private toastr: ToastrService,
      private spinner: NgxSpinnerService,
      private router: Router,
      private route: ActivatedRoute){}

    ngOnInit(): void {
        this.ultimoDiaInserido = 0;
        this.alterarAba('dados');
        var uuid = this.route.snapshot.paramMap.get("uuid");
        if(uuid){
          this.kitService.get(uuid, (erro: Erro400, sucesso: KitDto) => {
            if(sucesso){
              this.kit = new KitDto(sucesso);
              if(this.kit.kitCampanhaDiaDto.length > 0){
                this.ultimoDiaInserido = this.kit.kitCampanhaDiaDto[this.kit.kitCampanhaDiaDto.length - 1].dia;
                this.kit.kitCampanhaDiaDto[0].class = 'is-active';
                this.diaAtivo = this.kit.kitCampanhaDiaDto[0].dia;
              }
            }else if(erro && erro.error){
              this.toastr.error('', erro.error.message);
            }
          })
        } else {
          this.kit = new KitDto();
        }
    }

    alterarAba(aba: string) {
      if (aba === 'dados') {
        this.classeAbaDados = 'is-active';
        this.classeAbaImagens = '';
      } else if (aba === 'imagens') {
        this.classeAbaDados = '';
        this.classeAbaImagens = 'is-active';
      } 
    }

    cadastrarKit(){
      this.spinner.show();
      if(this.kit.uuid){
        this.kitService.update(this.kit.uuid, this.kit, (erro: Erro400, sucesso: KitDto) => {
          if(sucesso){
            this.kit = new KitDto(sucesso);
            this.toastr.success('', 'O kit de campanha foi atualizado com sucesso');
            this.selecionarDia(this.ultimoDiaInserido)
          }else if(erro && erro.error){
            this.toastr.error('', erro.error.message);
          }
          this.spinner.hide();
        });
      }else{
        this.kitService.create(this.kit, (erro: Erro400, sucesso: KitDto) => {
          if(sucesso){
            this.kit = new KitDto(sucesso);
            this.toastr.success('', 'O kit de campanha foi cadastrado com sucesso');
            this.router.navigate(["/kits"])
          }else if(erro && erro.error){
            this.toastr.error('', erro.error.message);
          }
          this.spinner.hide();
        });
      }
    }

    adicionarDia(){
      let kitDia: KitDiaDto = new KitDiaDto();
      kitDia.dia = (this.ultimoDiaInserido + 1);
      kitDia.kitsDinamica = new Array<KitDinamicaDto>();
      
      let kitDinamicaVideo = new KitDinamicaDto();
      kitDinamicaVideo.tipo = 'VIDEO';
      kitDia.kitsDinamica.push(kitDinamicaVideo);
      let kitDinamicaGame = new KitDinamicaDto();
      kitDinamicaGame.tipo = 'GAME';
      kitDia.kitsDinamica.push(kitDinamicaGame);
      let kitDinamicaFf = new KitDinamicaDto();
      kitDinamicaFf.tipo = 'FACT_OR_FAKE';
      kitDia.kitsDinamica.push(kitDinamicaFf);
      let kitDinamicaNh = new KitDinamicaDto();
      kitDinamicaNh.tipo = 'NEW_HABIT';
      kitDia.kitsDinamica.push(kitDinamicaNh);
      let kitDinamicaExtra = new KitDinamicaDto();
      kitDinamicaExtra.tipo = 'EXTRA';
      kitDia.kitsDinamica.push(kitDinamicaExtra);
      let kitDinamicaMural = new KitDinamicaDto();
      kitDinamicaMural.tipo = 'MURAL';
      kitDia.kitsDinamica.push(kitDinamicaMural);

      this.kit.kitCampanhaDiaDto.push(kitDia);
      this.cadastrarKit();

      this.ultimoDiaInserido++;
    }

    ativarDinamica(uuid: string){
      this.dinamicaAtiva = uuid;
    }

    cssDinamica(uuid: string){
      if(uuid == this.dinamicaAtiva){
        return "accordion is-active";
      }else{
        return "accordion";
      }
    }

    remover(){
      if (! confirm('Tem certeza que deseja excluir o kit?')) {
        return;
      }
      this.kitService.remove(this.kit.uuid, (erro: Erro400, sucesso: any) => {
        if(erro && erro.error){
          this.toastr.error('', erro.error.message);
        }else{
          this.toastr.success('', 'O kit de campanha foi removido com sucesso');
          this.router.navigate(["/kits"])
        }
      });
    }

    selecionarDia(dia: number) {
      this.kit.kitCampanhaDiaDto.forEach(day => {
        if (dia === day.dia) {
          day.class = 'is-active';
          this.diaAtivo = day.dia;
        } else {
          day.class = '';
        }
      });
    }

    updateKit(kit: KitDto){
      this.kit = kit;
    }

}