import { OnInit, Component } from '@angular/core';
import { Profile, CreateUser, User } from 'src/app/dto/user';
import { UsersService } from 'src/app/services/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Erro, Erros, Erro400 } from 'src/app/dto/erros';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.css']
  })
export class UserformComponent implements OnInit{
  usuario: CreateUser;
  profiles: Array<Profile>;
  required = true;

  constructor(
    private userService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.usuario = new CreateUser();
    this.userService.profiles((erro: any, sucesso: Array<Profile>) => {
      if(sucesso){
        this.profiles = sucesso;
      }else if(erro && erro.error){
        this.toastr.error('', erro.error.message);
      }
    });
    var uuid = this.route.snapshot.paramMap.get("uuid");
    if(uuid){
      this.userService.get(uuid, (erro: Erro400, sucesso: User) => {
        if(sucesso){
          this.usuario = new CreateUser(sucesso);
          this.required = false;
        }else if(erro && erro.error){
          this.toastr.error('', erro.error.message);
        }
      })
    }
  }

  cadastrarUsuario(){
    this.spinner.show();
    if(this.usuario.uuid){
      this.userService.editar(this.usuario, (erro: Erro400, sucesso: User) => {
        if(sucesso){
          this.toastr.success('', 'Usuário editado com sucesso');
          this.router.navigate(['/users']);
        }else if(erro && erro.error){
          this.toastr.error('', erro.error.message);
        }
        this.spinner.hide();
      });
    }else{
      this.userService.criar(this.usuario, (erro: Erro400, sucesso: User) => {
        if(sucesso){
          this.toastr.success('', 'Usuário cadastrado com sucesso');
          this.router.navigate(['/users']);
        }else if(erro && erro.error){
          this.toastr.error('', erro.error.message);
        }
        this.spinner.hide();
      });
    }
  }

  disableIt(event) {
    var which = event.which
    event.target.disabled = true
  }

}