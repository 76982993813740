import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import DinamicaPadrao from '../dto/dinamicaPadrao';
import { Erro400 } from '../dto/erros';
import { LoginService } from './login.service';
import Extra from 'src/app/dto/extra/extra';

@Injectable({
    providedIn: 'root'
  })
export class ExtraService{
    apiHost: string = environment.apiHost

    constructor(private http: HttpClient, private loginService: LoginService) { }

    buscar(name: string, theme: string, code: string, callback: Function) {

      let httpParams = new HttpParams()
      if (name) {
        httpParams = httpParams.set('name', name)
      }
      if (theme) {
        httpParams = httpParams.set('theme', theme)
      }
      if (code) {
        httpParams = httpParams.set('code', code)
      }

      this.http.get(this.apiHost + '/api/v1/extra', { params: httpParams }).subscribe(
        (coachings: DinamicaPadrao[]) => {
          callback(null, coachings);
        },
        (erro: any) => {
          callback(erro, null);
        }

      );
    }

    public criar(extra: Extra, callback: Function){
      this.http.post(this.apiHost + '/api/v1/extra', extra.toJSON(), {headers: this.loginService.authorizationHeader()}).subscribe(
          (sucesso: Extra) => {
              callback(null, sucesso);
          },
          (falha: Erro400) => {
              callback(falha, null);
          }
      );
    }

    public editar(extra: Extra, callback: Function){
      this.http.put(this.apiHost + '/api/v1/extra/' + extra.uuid, extra, {headers: this.loginService.authorizationHeader()}).subscribe(
          (sucesso: Extra) => {
              callback(null, sucesso);
          },
          (falha: Erro400) => {
              callback(falha, null);
          }
      );
    }

    public adicionarImagem(
      extra: DinamicaPadrao,
      tipo: string,
      file: File,
      callback: Function
    ) {
      var formdata: FormData = new FormData();
      formdata.append("file", file);

      let params = new HttpParams();
      params = params.set("type", tipo);

      this.http
        .post(this.apiHost + '/api/v1/extra/' + extra.uuid + '/image', formdata, {headers: this.loginService.authorizationHeader()})
        .subscribe(
          (sucesso: DinamicaPadrao) => {
            callback(null, sucesso);
          },
          (falha: Erro400) => {
            callback(falha, null);
          }
        );
    }

    enviaArquivoExtra(uuid: string, arquivo: File){
      const formdata: FormData = new FormData();
      formdata.append("file", arquivo);
      return this.http
      .post(this.apiHost + '/api/v1/extra/' + uuid + '/file', formdata, {headers: this.loginService.authorizationHeader()})

    }

    removerArquivoExtra(uuid: string){
      return this.http
      .delete(this.apiHost + '/api/v1/extra/' + uuid + '/file')
    }
}
