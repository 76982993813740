import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoginService } from './login.service';
import { User, Profile, CreateUser } from '../dto/user';
import { Erro400 } from '../dto/erros';

@Injectable({
    providedIn: 'root'
  })
export class UsersService{
    private apiHost: string = environment.apiHost;

    constructor(
        private http:HttpClient,
        private loginService: LoginService
      ) { }
    
    public profiles(callback: Function){
        this.http.get(this.apiHost + '/api/v1/profile?active=true', {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: Array<Profile>) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public criar(user: CreateUser, callback: Function){
        user.formataData();
        this.http.post(this.apiHost + '/api/v1/user', user, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: User) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public editar(user: CreateUser, callback: Function){
        user.formataData();
        this.http.put(this.apiHost + '/api/v1/user/' + user.uuid, user, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: User) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public get(uuid: string, callback: Function){
        this.http.get(this.apiHost + '/api/v1/user/' + uuid, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: User) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public buscar(nome: string, sobrenome: string, genero: string, email: string, pagina: number,
                  quantidadePorPagina: number, matricula: string, codigo: string, callback: Function){

        let params = new HttpParams();
        if(nome){
            params = params.set('firstName', nome);
        }
        if(sobrenome){
            params = params.set('lastName', sobrenome);
        }
        if(genero){
            params = params.set('gender', genero);
        }
        if(email){
            params = params.set('email', email);
        }
        if (matricula) {
           params = params.set('enrollment', matricula);
        }
        if (codigo){
            params = params.set('code', codigo);
        }
        params = params.set('page', pagina + '');
        params = params.set('pageSize', quantidadePorPagina + '');

        this.http.get(this.apiHost + '/api/v1/user', {headers: this.loginService.authorizationHeader(), params: params}).subscribe(
            (sucesso: Array<User>) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }

        );
    }
}