import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Participante } from 'src/app/dto/participante';
import { ParticipanteService } from 'src/app/services/participante.service';
import { ParticipanteBusca } from '../../dto/participanteBusca';

@Component({
    selector: 'app-participante-list',
    templateUrl: './participante-list.component.html',
    styleUrls: ['./participante-list.component.css']
  })
export class ParticipanteListComponent implements OnInit{
    participantes: Array<Participante>;

    busca: ParticipanteBusca;

    nome: string;
    sobrenome: string;
    email: string;
    matricula: string;
    code: string;

    pagina: number = 0;
    quantidadePorPagina: number = 10;
    temMais: boolean = true;

    textoCarregarMais: string = "Carregar mais";
    classeCarregarMais: string = "button is-primary";

    textoBuscar: string = "Buscar";
    classeBuscar: string = "button is-primary";

    constructor(private participanteService: ParticipanteService, private router: Router){

    }

    ngOnInit(): void {
        this.busca = new ParticipanteBusca();
        let dados:string = localStorage.getItem('parametros-busca-participante')
        if (dados != null) {
          this.busca = JSON.parse(dados)
        }

        this.pagina = 0;
        this.nome = this.busca.nome;
        this.sobrenome = this.busca.sobrenome;
        this.email = this.busca.email;
        this.matricula = this.busca.matricula;
        this.code = this.busca.code;

        localStorage.removeItem('parametros-busca-participante');

        this.participantes = new Array<Participante>();
        this.participanteService.buscar(this.nome, this.sobrenome, '', this.email, this.pagina,
            this.quantidadePorPagina, this.matricula, this.code, (erro: any, sucesso: Array<Participante>) => {
            if(sucesso){
              this.participantes = sucesso;
              if(sucesso.length == this.quantidadePorPagina){
                this.temMais = true;
              }else{
                this.temMais = false;
              }
            }
        });
    }

    buscar(){
        this.textoBuscar = "Buscando...";
        this.classeBuscar = "button is-light";
        this.pagina = 0;
        this.participantes = new Array<Participante>();
    
        this.participanteService.buscar(this.nome, this.sobrenome, '', this.email, this.pagina, this.quantidadePorPagina,
          this.matricula, this.code, (erro: any, sucesso: Array<Participante>) => {
          this.textoBuscar = "Buscar";
          this.classeBuscar = "button is-primary";
          if(sucesso){
            this.participantes = sucesso;
            if(sucesso.length == this.quantidadePorPagina){
              this.temMais = true;
            }else{
              this.temMais = false;
            }
          }
        });
    }

    carregarMais(){
        this.textoCarregarMais = "Carregando...";
        this.classeCarregarMais = "button is-light";
        this.pagina++;
        this.participanteService.buscar(this.nome, this.sobrenome, '', this.email, this.pagina, this.quantidadePorPagina,
          this.matricula, this.code, (erro: any, sucesso: Array<Participante>) => {
          this.textoCarregarMais = "Carregar mais";
          this.classeCarregarMais = "button is-primary";
          if(sucesso){
            this.participantes = this.participantes.concat(sucesso);
            if(sucesso.length == this.quantidadePorPagina){
              this.temMais = true;
            }else{
              this.temMais = false;
            }
          }
        });
    }

    acessarParticipante(participante: string) {
      this.busca = new ParticipanteBusca();
      this.busca.nome = this.nome;
      this.busca.sobrenome = this.sobrenome;
      this.busca.email = this.email;
      this.busca.matricula = this.matricula;
      this.busca.code = this.code;

      localStorage.setItem('parametros-busca-participante', JSON.stringify(this.busca));

      this.router.navigateByUrl('/participants/edit/'+participante)
    }
}