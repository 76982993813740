import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ElegibilidadeDTO } from '../dto/elegibilidade';
import EligibilityErrorPage from '../dto/elegiblidade-async/EligibilityErrorPage';
import EligibilityStatusPage from '../dto/elegiblidade-async/EligibilityStatusPage';
import { Erro400 } from '../dto/erros';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
  })
export class ElegibilidadeService{
    apiHost: string = environment.apiHost;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) { }

  buscarRegistros(uuidEvento: string, callback: Function) {
    var httpHeaders: HttpHeaders = this.loginService.authorizationHeader();
    httpHeaders.append('accept', 'application/json');
    this.http.get(this.apiHost + '/api/v1/event/' + uuidEvento + '/eligibility', {headers: httpHeaders}).subscribe(
      (els: Array<ElegibilidadeDTO>) => {
        callback(null, els);
      },
      (erro: any) => {
        callback(erro, null);
      }

    );
  }

  download(uuidEvento: string, callback: Function) {
    var httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: this.loginService.autorizacao().token,
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

/*    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.loginService.autorizacao().token,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      responseType: 'blob'
    };
  */

    this.http.get(this.apiHost + '/api/v1/event/' + uuidEvento + '/eligibility', 
    {observe: 'response', responseType: 'blob', headers: httpHeaders})
    .subscribe(
      response => {
        callback(null, response);
      },
      (erro: any) => {
        callback(erro, null);
      }

    );
  }

  importar(uuidEvento: string, tipo: string, arquivo: File){
    let params = new HttpParams();
    params = params.set('type', tipo);

    const formdata: FormData = new FormData();
    formdata.append('file', arquivo);

    return  this.http.post(this.apiHost + '/api/v1/event/' + uuidEvento + '/eligibility', formdata,
      {headers: this.loginService.authorizationHeader(), params: params});
  }

  getEligibilityStatus(uuidEvento: string, page: number){
    let params = new HttpParams();
    params = params.set('page', page.toString());
    params = params.append('pageSize', '10');
    return this.http.get<EligibilityStatusPage>(this.apiHost + '/api/v1/event/' + uuidEvento + '/eligibilityStatus',
      {headers: this.loginService.authorizationHeader(), params: params});
  }

  getEligibilityErro(uuidEvento: string, uuidArquivo:string, page: number){
    let params = new HttpParams();
    params = params.set('page', page.toString());
    params = params.append('pageSize', '50');
    return this.http.get<EligibilityErrorPage>(this.apiHost + '/api/v1/event/' + uuidEvento + '/eligibility/' + uuidArquivo + '/error',
      {headers: this.loginService.authorizationHeader(), params: params});
  }

}