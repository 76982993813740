import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoginService } from './login.service';
import { Game } from '../dto/game';
import { Erro400 } from '../dto/erros';

@Injectable({
    providedIn: 'root'
  })
export class GameService{
    apiHost: string = environment.apiHost

    constructor(private http: HttpClient, private loginService: LoginService) { }

    buscar(name: string, theme: string, code: string, callback: Function) {

      let httpParams = new HttpParams()
      if (name) {
        httpParams = httpParams.set('name', name)
      }
      if (theme) {
        httpParams = httpParams.set('theme', theme)
      }
      if (code) {
        httpParams = httpParams.set('code', code)
      }
  
      this.http.get(this.apiHost + '/api/v1/game', { params: httpParams }).subscribe(
        (coachings: Game[]) => {
          callback(null, coachings);
        },
        (erro: any) => {
          callback(erro, null);
        }
  
      );
    }

    public criar(game: Game, callback: Function){
      this.http.post(this.apiHost + '/api/v1/game', game.toJSON(), {headers: this.loginService.authorizationHeader()}).subscribe(
          (sucesso: Game) => {
              callback(null, sucesso);
          },
          (falha: Erro400) => {
              callback(falha, null);
          }
      );
    }

    public editar(game: Game, callback: Function){
      this.http.put(this.apiHost + '/api/v1/game/' + game.uuid, game, {headers: this.loginService.authorizationHeader()}).subscribe(
          (sucesso: Game) => {
              callback(null, sucesso);
          },
          (falha: Erro400) => {
              callback(falha, null);
          }
      );
    }
}