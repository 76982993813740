import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoginService } from './login.service';
import { Event, EventDto, EventCopiaDto } from '../dto/event';
import { Erro400 } from '../dto/erros';
import { Estilo, EstiloRequest } from '../dto/estilo';
import { FieldDto, FieldRequestDto } from '../dto/field';
import { UsuarioFiltros } from '../dto/usuarioFiltros';
import { User } from '../dto/user';
import { GestorAction } from '../dto/gestorAction';
import { Gestor } from '../dto/gestor';



@Injectable({
    providedIn: 'root'
  })
export class EventService{
    private apiHost: string = environment.apiHost;

    constructor(
        private http:HttpClient,
        private loginService: LoginService
    ) { }

    public salvarCampos(uuid: string, campo: FieldDto, callback: Function){
        let request:FieldRequestDto = new FieldRequestDto();
        request.field = campo.field
        request.label = campo.label
        request.order = campo.order
        request.required = campo.required
        

        this.http.post(this.apiHost + '/api/v1/event/' + uuid + '/customField', request, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: Event) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public removerCampo(uuid: string, field: string, callback: Function){
        let params = new HttpParams();
        if(field){
            params = params.set('field', field);
        }
        this.http.delete(this.apiHost + '/api/v1/event/' + uuid + '/customField', {headers: this.loginService.authorizationHeader(), params: params}).subscribe(
            (sucesso: any) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }            
        )
    }

    public salvarEstilo(uuid: string, estilo: Estilo, fileMobile: File, fileDesktop: File, callback: Function){
        let request:EstiloRequest = new EstiloRequest();
        request.uuid = estilo.uuid
        request.page = estilo.page
        request.foregroundColor = estilo.foregroundColor
        request.backgroundColor = estilo.backgroundColor
        let formdata: FormData = new FormData();
        formdata.append('mobile', fileMobile);
        formdata.append('desktop', fileDesktop);
        formdata.append('entity', JSON.stringify(request))

        this.http.post(this.apiHost + '/api/v1/event/' + uuid + '/style', formdata, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: Event) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public removerEstilo(uuid: string, guid:string, callback: Function){
        this.http.delete(this.apiHost + '/api/v1/event/' + uuid + '/style/' + guid, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: any) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }            
        )
    }

    public buscar(name: string, client: string, code: string, pagina: number, quantidadePorPagina: number, callback: Function){

        let params = new HttpParams();
        if(name){
            params = params.set('name', name);
        }
        if(client){
            params = params.set('client', client);
        }
        if(code){
            params = params.set('code', code);
        }
        
        params = params.set('page', pagina + '');
        params = params.set('pageSize', quantidadePorPagina + '');

        this.http.get(this.apiHost + '/api/v1/event', {headers: this.loginService.authorizationHeader(), params: params}).subscribe(
            (sucesso: Array<Event>) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }

        );
    }

    public criar(evento: EventDto, callback: Function){
        evento.formataData();
        this.http.post(this.apiHost + '/api/v1/event', evento, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: Event) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public copiar(evento: EventDto, copia: EventCopiaDto, callback: Function) {
        this.http.post(this.apiHost + '/api/v1/event/' + evento.uuid + '/copy',
                    copia, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: Event) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public editar(evento: EventDto, callback: Function){
        evento.formataData();
        this.http.put(this.apiHost + '/api/v1/event/' + evento.uuid, evento, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: Event) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public get(uuid: string, callback: Function){
        this.http.get(this.apiHost + '/api/v1/event/' + uuid, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: Event) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public enviarImagem(uuid: string, tipo: string, file: File, callback: Function){
        var formdata: FormData = new FormData();
        formdata.append('file', file);

        let params = new HttpParams();
        params = params.set('type', tipo);

        this.http.post(this.apiHost + '/api/v1/event/' + uuid + '/image', formdata, {headers: this.loginService.authorizationHeader(), params: params}).subscribe(
            (sucesso: Event) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public enviarTrofeu(uuid: string, tipo: string, file: File, callback: Function){
        var formdata: FormData = new FormData();
        formdata.append('file', file);

        let params = new HttpParams();
        params = params.set('type', tipo);

        this.http.post(this.apiHost + '/api/v1/event/' + uuid + '/prize', formdata, {headers: this.loginService.authorizationHeader(), params: params}).subscribe(
            (sucesso: Event) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public removerImagem(uuid: string, tipo: string, callback: Function){
        let params = new HttpParams();
        params = params.set('type', tipo);

        this.http.delete(this.apiHost + '/api/v1/event/' + uuid + '/image', {headers: this.loginService.authorizationHeader(), params: params}).subscribe(
            (sucesso: Event) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public getGestores(uuid: string, filters: UsuarioFiltros, callback: Function){
        let params = new HttpParams();
        if(filters.firstName){
            params = params.set('firstName', filters.firstName);
        }
        if(filters.profileName){
            params = params.set('profileName', filters.profileName);   
     }
        if(filters.pageSize){
            params = params.set('pageSize', filters.pageSize.toString());
        }                

        this.http.get(this.apiHost + '/api/v1/user', {headers: this.loginService.authorizationHeader(), params}).subscribe(
            (sucesso: User[]) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public updateGestor(codigo:string, gestor:GestorAction, callback: Function){
        this.http.post(this.apiHost + '/api/v1/event/' + codigo + '/gestor',gestor,{headers: this.loginService.authorizationHeader()}).subscribe(
            () => {
                
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }
    public getGestoresCampanha(codigo:string,callback:Function){
        this.http.get(this.apiHost + '/api/v1/event/' + codigo + '/gestor',{headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: Gestor[]) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

}