import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AgendaDia } from 'src/app/dto/agenda';
import { Unidade } from 'src/app/dto/unidade';
import { UnidadeService } from 'src/app/services/unidade.service';

@Component({
  selector: "app-agenda-area",
  templateUrl: "./agenda-area.component.html",
  styleUrls: ["./agenda-area.component.css"],
})
export class AgendaAreaComponent implements OnInit, OnChanges {
  MARCAR_TODOS = "Marca todos";
  DESMARCAR_TODOS = "Desmarca todos";
  dropdownClass = "dropdown is-active";
  changeAllText = this.MARCAR_TODOS;
  isMarcaTodos = false;
  isDropdownActive = true;
  unidadesSelecionadas: Unidade[] = [];
  @Input() dia: AgendaDia;
  @Input() cliente: string;
  unidades: Unidade[] = [];
  areas: Unidade[] = [];
  subAreas: Unidade[] = [];

  @Output() saveUnidades = new EventEmitter<Unidade[]>();

  constructor(
    private toastr: ToastrService,
    private unidadeService: UnidadeService
  ) {}
  ngOnChanges(): void {
    this.dropdownClass = "dropdown is-active";
    this.isDropdownActive = true;

    const unidadesPreSelecionadas = this.dia.units;

    if (unidadesPreSelecionadas && unidadesPreSelecionadas.length > 0) {
      this.unidadesSelecionadas = [];

      unidadesPreSelecionadas.forEach((up) => {
        this.adicionarUnidadesPreselecionadas(up);
      });
    } else {
      this.unidadesSelecionadas = [];
    }
  }

  ngOnInit() {
    this.dropdownClass = "dropdown is-active";
    this.isDropdownActive = true;
    this.unidadeService.buscar(
      this.cliente,
      null,
      null,
      true,
      (erro: any, sucesso: Unidade[]) => {
        if (sucesso) {
          this.unidades = sucesso.filter((u) => u.active === true);
          this.areas = this.unidades;
          const subUnidades: Unidade[] = [];
          this.unidades.forEach((u) => {
            if (u.subUnits && u.subUnits.length > 0) {
              subUnidades.concat(
                u.subUnits.filter((subUnidades) => subUnidades.active === true)
              );
            }
          });
          this.subAreas = subUnidades;
          this.unidades = this.unidades.concat(subUnidades);

          const unidadesPreSelecionadas = this.dia.units;
          if (unidadesPreSelecionadas && unidadesPreSelecionadas.length > 0) {
            this.unidadesSelecionadas = [];

            unidadesPreSelecionadas.forEach((up) =>
              this.adicionarUnidadesPreselecionadas(up)
            );
          }
        }

        if (erro) {
          if (erro.error && erro.error.message && erro.status === 400) {
            this.toastr.error(erro.error.message);
          } else {
            this.toastr.error("Erro inesperado: " + JSON.stringify(erro));
          }
        }
      }
    );
    this.updateChangeAllText();
  }

  adicionarUnidadesPreselecionadas(unidadesPreselecionadas: Unidade) {
    this.addSelectedUnit(unidadesPreselecionadas, this.unidades);
  }

  private addSelectedUnit(targetUnit: Unidade, units: Unidade[]) {
    for (const unit of units) {
      if (targetUnit.uuid === unit.uuid) {
        const exists = this.unidadesSelecionadas.find(
          (us) => us.uuid === unit.uuid
        );
        if (!exists) {
          this.unidadesSelecionadas.push(unit);
        }
        return true;
      }

      if (unit.subUnits && unit.subUnits.length > 0) {
        if (this.addSelectedUnit(targetUnit, unit.subUnits)) {
          return true;
        }
      }
    }
    return false;
  }

  updateUnidadesSelecionadas(unidadesAtualizadas: Unidade[]) {
    this.unidadesSelecionadas = unidadesAtualizadas;
  }

  onCheckedChange(event: { unidade: Unidade; isChecked: boolean }) {
    const { unidade, isChecked } = event;
    if (isChecked) {
      this.unidadesSelecionadas = this.unidadesSelecionadas.concat(unidade);
    } else {
      this.unidadesSelecionadas = this.unidadesSelecionadas.filter(
        (u) => u.uuid !== unidade.uuid
      );
    }
    this.toggleDescendants(unidade, isChecked);
    this.updateChangeAllText();
  }

  checkIfAllSelected() {
    const allUnits = this.flattenUnits(this.unidades);
    return allUnits.every((unit) =>
      this.unidadesSelecionadas.some((selected) => selected.uuid === unit.uuid)
    );
  }

  flattenUnits(units: Unidade[]): Unidade[] {
    let flatUnits: Unidade[] = [];
    units.forEach((unit) => {
      flatUnits.push(unit);
      if (unit.subUnits && unit.subUnits.length > 0) {
        flatUnits = flatUnits.concat(this.flattenUnits(unit.subUnits));
      }
    });
    return flatUnits;
  }

  private toggleDescendants(unidade: Unidade, isChecked: boolean) {
    if (unidade.subUnits !== null && unidade.subUnits.length > 0) {
      unidade.subUnits.forEach((subUnidade) => {
        if (isChecked) {
          const jaExiste: Unidade = this.unidadesSelecionadas.find(
            (unit) => unit.uuid === subUnidade.uuid
          );
          if (!jaExiste) {
            this.unidadesSelecionadas =
              this.unidadesSelecionadas.concat(subUnidade);
          }
        } else {
          this.unidadesSelecionadas = this.unidadesSelecionadas.filter(
            (unit) => unit.uuid !== subUnidade.uuid
          );
        }
        this.toggleDescendants(subUnidade, isChecked);
      });
    }
  }

  onDropdownToglle() {
    if (this.isDropdownActive) {
      this.dropdownClass = "dropdown";
      this.isDropdownActive = false;
    } else {
      this.dropdownClass = "dropdown is-active";
      this.isDropdownActive = true;
    }
  }

  private getAllUnitsWithDescendants(units: Unidade[]): Unidade[] {
    let allUnits: Unidade[] = [];

    units.forEach((unit) => {
      allUnits.push(unit);
      if (unit.subUnits && unit.subUnits.length > 0) {
        allUnits = allUnits.concat(
          this.getAllUnitsWithDescendants(unit.subUnits)
        );
      }
    });

    return allUnits;
  }

  ordenarUnidadesSelecionadas() {
    return this.unidadesSelecionadas.sort((a: Unidade, b: Unidade) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  }

  onSave() {
    this.changeAllText = this.MARCAR_TODOS;
    this.isMarcaTodos = false;

    this.saveUnidades.emit(this.unidadesSelecionadas);
  }

  onDeseleonarUnidade(unidade: Unidade) {
    if (
      !confirm(
        "Tem certeza que deseja excluir a Área/Unidade do dia: " +
          this.dia.nome +
          "?"
      )
    ) {
      return;
    }
    this.unidadesSelecionadas = this.dia.units.slice(0);
    const unidadeDeselecionada = this.unidadesSelecionadas.find(
      (us) => us.uuid === unidade.uuid
    );
    if (unidadeDeselecionada) {
      const index = this.unidadesSelecionadas.indexOf(unidadeDeselecionada);
      this.unidadesSelecionadas.splice(index, 1);
    }

    this.onSave();
  }

  isUnidadeChecked(unidade: Unidade) {
    return this.unidadesSelecionadas.find((u) => u.uuid === unidade.uuid);
  }

  onChangeAllBox() {
    if (this.checkIfAllSelected()) {
      this.unidadesSelecionadas = [];
    } else {
      this.unidadesSelecionadas = this.flattenUnits(this.unidades);
    }
    this.updateChangeAllText();
  }

  updateChangeAllText() {
    this.changeAllText = this.checkIfAllSelected()
      ? "Desmarcar todas"
      : "Marcar todas";
  }
}
