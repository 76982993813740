import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Erro400 } from '../dto/erros';
import { NewHabit, NewHabitsCopiar } from '../dto/newHabit';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
  })
export class NewHabitService{
    apiHost: string = environment.apiHost;

    constructor(private http: HttpClient, private loginService: LoginService) { }

    public newHabits(name: string, theme: string, code: string, callback: Function) {
        let httpParams: HttpParams = new HttpParams()
        if (name) {
            httpParams = httpParams.set("name", name)
        }
        if(theme){
            httpParams = httpParams.set("theme", theme)
        }
        if(code){
            httpParams = httpParams.set("code", code)
        }
        this.http.get(this.apiHost + '/api/v1/newHabits', { params: httpParams }).subscribe(
            (newHabits: NewHabit[]) => {
            callback(null, newHabits);
            },
            (error: any) => {
            callback(error, null);
            }
        );
    }

    public criar(habit: NewHabit, callback: Function){
        this.http.post(this.apiHost + '/api/v1/newHabits', habit, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: NewHabit) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }
  
    public editar(habit: NewHabit, callback: Function){
        this.http.put(this.apiHost + '/api/v1/newHabits/' + habit.uuid, habit, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: NewHabit) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public enviarImagem(uuid: string, file: File, callback: Function){
        var formdata: FormData = new FormData();
        formdata.append('file', file);

        this.http.put(this.apiHost + '/api/v1/newHabits/' + uuid + '/image', formdata, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: NewHabit) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public enviarImagemCard(uuid: string, cardUuid: string, file: File, callback: Function){
        var formdata: FormData = new FormData();
        formdata.append('file', file);

        this.http.put(this.apiHost + '/api/v1/newHabits/' + uuid + '/card/' + cardUuid + '/image', formdata, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: NewHabit) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public clonarNewHabit(habit: NewHabit, copia: NewHabitsCopiar,  callback: Function){
        this.http.post(this.apiHost + '/api/v1/newHabits/' + habit.uuid + '/clone', copia, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: NewHabit) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }
    
    public deleteCard(cardUuid: string, habitUuid: string, callback: Function){
        this.http.delete(this.apiHost + "/api/v1/newHabits/" + habitUuid + "/card/" + cardUuid,  {headers: this.loginService.authorizationHeader()})
        .subscribe( 
            (sucess: NewHabit) => {
                callback(null, sucess);
            },
            (err: Erro400 ) =>{
                callback(err, null);
            }
        )
    }
}