import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Erro400 } from 'src/app/dto/erros';
import { NewHabit } from 'src/app/dto/newHabit';
import { Theme } from 'src/app/dto/theme';
import { NewHabitService } from 'src/app/services/new-habit.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
    selector: 'app-new-habit-list',
    templateUrl: './new-habit-list.component.html',
    styleUrls: ['./new-habit-list.component.css']
  })
export class NewHabitListComponent implements OnInit{
  habits: NewHabit[];
  themes: Theme[];

  name: string;
  theme: string;
  code: string;

  textoBuscar: string = "Buscar"
  classeBuscar: string = "button is-primary"
  
  constructor(private temaService: ThemeService, private newHabitsService: NewHabitService, 
              private router: Router, private toasterService: ToastrService){
  }

  ngOnInit(): void {
    this.name = "";
    this.theme = "";
    this.code = "";
    this.temaService.themes(null, (erro: Erro400, sucesso: Theme[]) => {
      if(sucesso){
        this.themes = sucesso;
      }
    });
  }

  buscar(){
    
    this.textoBuscar = "Buscando..."
    this.classeBuscar = "button is-light"
    
    this.newHabitsService.newHabits(this.name, this.theme, this.code, (error: any, sucesso: NewHabit[]) => {
      if(sucesso){
        this.habits = new Array<NewHabit>();
        sucesso.forEach(s => {
          this.habits.push(new NewHabit(s));
        });
        this.textoBuscar = "Buscar"
        this.classeBuscar = "button is-primary"
      }
    })
  }

  edit(habit: NewHabit){
    let navigationExtras: NavigationExtras
    navigationExtras = {
      state: {
        habit: habit
      }
    }
    this.router.navigate(["/newhabits/edit/" + habit.uuid], navigationExtras)
  }

}