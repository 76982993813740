import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';
import { Theme } from '../../dto/theme';
import { Bloco } from '../../dto/bloco-dinamica/bloco';
import { Erro400 } from 'src/app/dto/erros';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { BlocoDinamicaService } from 'src/app/services/bloco-dinamica.service';
import { TypeRedirect } from 'src/app/dto/bloco-dinamica/typeRedirect';

@Component({
  selector: 'app-bloco-dinamica-form',
  templateUrl: './bloco-dinamica-form.component.html',
  styleUrls: ['./bloco-dinamica-form.component.css']
})
export class BlocoDinamicaFormComponent implements OnInit {
  themes: Theme[] = [];
  bloco: Bloco = new Bloco();
  constructor(
    private themeService: ThemeService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private blocoService: BlocoDinamicaService

  ) { }

  ngOnInit() {
    this.setTela();
  }
  setTela() {
    this.themeService.themes(undefined, (erro: Erro400, sucesso: Array<Theme>) => {
      if ( sucesso ) {
        this.themes = sucesso;
      } else if (erro && erro.error) {
        this.toastr.error('', erro.error.message);
      }
    });
    const uuid = this.route.snapshot.paramMap.get('uuid');
    if (uuid) {
      this.bloco = new Bloco(history.state.bloco);
    }

  }

  onChangeBloco(event: Bloco) {
    this.bloco = event;
    if (this.bloco.uuid){
      this.editar();
    } else {
      this.cadastar();
    }


  }
  cadastar() {
    this.blocoService.salvar(this.bloco).subscribe(
      (sucess: Bloco) => this.sucess(sucess),
      (erro: Erro400) => {
        this.toastr.error('', erro.error.message);
      }

    );
  }
  editar() {
    this.blocoService.editar(this.bloco).subscribe(
      (sucess: Bloco) => this.sucess(sucess),
      (erro: Erro400) => this.toastr.error('', erro.error.message)
    );
  }
  sucess(sucess: Bloco){
    this.toastr.success('', 'Bloco salvo com sucesso');
    switch (this.bloco.typeRedirect) {
      case TypeRedirect.ONLY_SAVE:
        this.bloco = sucess;
        break;
      case TypeRedirect.SAVE_LIST:
        this.router.navigate(['/blocoDinamica']);
        break;
      case TypeRedirect.SAVE_AND_NEW:
        this.bloco = new Bloco();
        this.router.navigate(['/bloco/new']);
    }
  }

}
