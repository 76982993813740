import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Erro400 } from "../dto/erros";
import { LoginService } from "./login.service";

@Injectable({
    providedIn: 'root'
  })
export class RelatorioService{
    private apiHost: string = environment.apiHost;

    constructor(
        private http:HttpClient,
        private loginService: LoginService
    ) { }

    public relatorio(campanha: string, tipo: string, callback: Function){
        let params = new HttpParams();
        
        params = params.set('campaign', campanha);
        params = params.set('type', tipo);

        var httpHeaders: HttpHeaders = new HttpHeaders({
            Authorization: this.loginService.autorizacao().token,
            accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
        
        this.http.get(this.apiHost + '/api/v1/report', {observe: 'response', responseType: 'blob', headers: httpHeaders, params: params}).subscribe(
            response => {
                callback(null, response);
              },
              (erro: any) => {
                callback(erro, null);
              }

        );
    }
}