import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ComoFunciona } from 'src/app/dto/comoFunciona';
import { ComoFuncionaService } from 'src/app/services/como-funciona.service';

@Component({
  selector: 'app-como-funciona-list',
  templateUrl: './como-funciona-list.component.html',
  styleUrls: ['./como-funciona-list.component.css']
})
export class ComoFuncionaListComponent implements OnInit {

  comoFunciona: ComoFunciona[];

  titulo: string;

  pagina: number = 0;
  quantidadePorPagina: number = 10;
  temMais: boolean = true;

  textoCarregarMais: string = "Carregar mais";
  classeCarregarMais: string = "button is-primary";

  textoBuscar: string = "Buscar";
  classeBuscar: string = "button is-primary";

  constructor(private comoFuncionaService:ComoFuncionaService, private router: Router) {  }

  ngOnInit() {

    this.pagina = 0;
    this.titulo = "";

    this.comoFunciona = new Array<ComoFunciona>();
    this.buscar() 
  }

  buscar(){
    this.textoBuscar = "Buscando...";
    this.classeBuscar = "button is-light";
    this.pagina = 0;
    this.comoFunciona = new Array<ComoFunciona>();
    
    this.comoFuncionaService.buscarComoFunciona(this.titulo, this.pagina, this.quantidadePorPagina, (erro: any, sucesso: Array<ComoFunciona>) => {
      this.textoBuscar = "Buscar";
      this.classeBuscar = "button is-primary";
      if(sucesso){
        this.comoFunciona = sucesso;
        if(sucesso.length == this.quantidadePorPagina){
          this.temMais = true;
        }else{
          this.temMais = false;
        }
      }
      if(erro) {
        console.log(erro)
      }

    });
  }

  carregarMais(){
    this.textoCarregarMais = "Carregando...";
    this.classeCarregarMais = "button is-light";
    this.pagina++;
    this.comoFuncionaService.buscarComoFunciona(this.titulo, this.pagina, this.quantidadePorPagina, (erro: any, sucesso: Array<ComoFunciona>) => {
      this.textoCarregarMais = "Carregar mais";
      this.classeCarregarMais = "button is-primary";
      if(sucesso){
        this.comoFunciona = this.comoFunciona.concat(sucesso);
        if(sucesso.length == this.quantidadePorPagina){
          this.temMais = true;
        }else{
          this.temMais = false;
        }
      }
    });
  }

  novo(){
    this.router.navigate(['/comoFunciona/new'])
  }

  edit(comoFuncionaEdit: ComoFunciona){
    let navigationExtras: NavigationExtras
    navigationExtras = {
      state: {
        comoFunciona: comoFuncionaEdit
      }
    }
    this.router.navigate(["/comoFunciona/edit/" + comoFuncionaEdit.uuid], navigationExtras)
  }

}
