import { Component, Input, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Erro400 } from "src/app/dto/erros";
import { Estilo } from "src/app/dto/estilo";
import { EventDto } from "src/app/dto/event";
import { EventService } from "src/app/services/evento.service";

@Component({
    selector: 'app-estilos-form',
    templateUrl: './estilos-form.component.html',
    styleUrls: ['./estilos-form.component.css']
})
export class EstilosFormComponent implements OnInit {
    @Input() evento: EventDto;

    estilo: Estilo;

    public listaEstilos: Array<Estilo> = []

    public arquivoMobile: File;
    public arquivoDesktop: File;

    constructor(private eventoService: EventService, private toastr: ToastrService) { }

    ngOnInit(): void {
        this.estilo = new Estilo();
        this.listaEstilos = []
        if (this.evento.styles) {
            this.evento.styles.forEach(estilo => {
                this.listaEstilos.push(estilo)
            });
        }
    }

    novo() {
        this.estilo = new Estilo();
    }

    cadastrar() {
        let valido = true;
        if (!this.estilo.uuid) {
            this.listaEstilos.forEach(estilo => {
                if (estilo.page === this.estilo.page) {
                    valido = false;
                }
            });
        }
        if (!valido) {
            this.toastr.error('', 'Já existe um estilo para essa página cadastrado');
            return;
        }
        this.eventoService.salvarEstilo(
            this.evento.uuid, this.estilo,
            this.arquivoMobile,
            this.arquivoDesktop,
            this.requestService);
    }


    requestService = (erro: Erro400, sucesso: Estilo)  =>{
        if (sucesso) {
            
            if (!this.estilo.uuid) {
                this.listaEstilos.push(sucesso)
            } else {
                for (let i = 0; i < this.listaEstilos.length; i++) {
                    if (this.listaEstilos[i].uuid === sucesso.uuid) {
                        this.listaEstilos[i] = sucesso;
                    }
                }
            }
            this.evento.styles = this.listaEstilos;

            this.arquivoDesktop = null;
            this.arquivoMobile = null;
            this.estilo = new Estilo();
            this.toastr.success('', 'O estilo foi cadastrado com sucesso');
        } else if (erro && erro.error) {
            this.toastr.error('', erro.error.message);
        }
    }

    onChangeFileMobile(file: File) {
        this.arquivoMobile = file;
    }

    onChangeFileDesktop(file: File) {
        this.arquivoDesktop = file;
    }

    editar(estilo: Estilo) {
        this.estilo = new Estilo(estilo);
    }

    removerEstilo(estilo: Estilo) {
        if (!confirm('Deseja realmente remover o estilo?')) {
            return;
        }
        this.eventoService.removerEstilo(this.evento.uuid, estilo.uuid, (erro: Erro400) => {
            if (erro) {
                this.toastr.error('', erro.error.message);
            } else {
                for (let i = 0; i < this.listaEstilos.length; i++) {
                    if (this.listaEstilos[i].uuid === estilo.uuid) {
                        this.listaEstilos.splice(i, 1)

                    }
                }
                this.estilo = new Estilo();
                this.evento.styles = this.listaEstilos

                this.toastr.success('', 'O estilo foi removido com sucesso');

            }
        })
    }

}