import DinamicaPadrao from "../dinamicaPadrao";

export default class Extra extends DinamicaPadrao {
    typeApresentation: string;
    mimeType="";
    arquivoExtra: string;
    constructor(g: Extra = null) {
        super(g);
        if (g) {
            this.typeApresentation = g.typeApresentation ? g.typeApresentation : '';
            this.mimeType = g.mimeType ? g.mimeType : '';
            this.arquivoExtra = g.arquivoExtra ? g.arquivoExtra : '';
        }
    }

    toJSON() {
        return {
            'uuid': this.uuid,
            'name': this.name,
            'code': this.code,
            'url': this.url,
            'presentation': this.presentation,
            'description': this.description,
            'theme': { 'uuid': this.theme.uuid },
            'active': this.active != undefined ? this.active : false,
            'title': this.title,
            'picture': this.picture,
            'typeApresentation': this.typeApresentation,
            'mimeType': this.mimeType,
        }
    }
}
