import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Erro400 } from '../dto/erros';
import { KitIcones, KitIconesSearch, KitIconesCreate, IconePost, Icone, KitIconesDelete} from '../dto/kitIcones';
import { LoginService } from './login.service';


@Injectable({
  providedIn: 'root'
})
export class KitIconesService {
  apiHost: string = environment.apiHost;

  constructor(private http: HttpClient, private loginService: LoginService) { }

  buscar (search: KitIconesSearch, callback: Function){
    const {name, description, standard, page, pageSize} = search;

    let params = "page="+ page + "&pageSize=" + pageSize;

    if(name.length){
      params +="&name="+name;
    }
    if(description.length){
      params +="&description="+description;
    }
    if(standard.length){
      params +="&standard="+standard;
    }
    
    this.http.get(this.apiHost + "/api/v1/icons?" + params, {headers: this.loginService.authorizationHeader()})
    .subscribe(
      (sucess: KitIcones[])=>{
        callback(null, sucess);
      },
      (error: Erro400) =>{
        callback(error, null);
      }
    )
  }

  public search(callback: Function) {
    this.http.get(this.apiHost + '/api/v1/icons').subscribe(
      (sucess: KitIcones[]) => {
        callback(null, sucess);
      },
      (error: any) => {
        callback(error, null);
      }
    );
  }

  public postKit(kit: KitIconesCreate, callback: Function){
    const {uuid} = kit;
    if(uuid){
      this.http.put(this.apiHost + "/api/v1/icons/" + uuid, kit, {headers: this.loginService.authorizationHeader()}).subscribe(
        (sucess: KitIcones) => {
          callback(null, sucess);
        },
        (error: any) => {
          callback(error, null);
        }
      )
    }
    else{
      this.http.post(this.apiHost +"/api/v1/icons", kit, {headers: this.loginService.authorizationHeader()}).subscribe(
        (sucess: KitIcones) => {
          callback(null, sucess);
        },
        (error: any) => {
          callback(error, null);
        }
      )
    }
  }
  public removeKit(kitIconesDelete: KitIconesDelete, callback: Function){
    this.http.delete(this.apiHost + "/api/v1/icons/" + kitIconesDelete.uuid + "?replace=" + kitIconesDelete.replace,
    {headers: this.loginService.authorizationHeader()})
    .subscribe((sucess)=>{
      callback(null, sucess);
    }, (err)=>{
      callback(err, null);
    }
    );
  }
  
  public postIcon(iconPost: IconePost, kitUuid: string, callback: Function ){
    const form: FormData = new FormData();
    form.append("file", iconPost.file);
    form.append("dynamic", iconPost.dynamic);
    form.append("status", iconPost.status.toString());

    this.http.post(this.apiHost + "/api/v1/icons/" + kitUuid + "/icon", form, {headers: this.loginService.authorizationHeader()} )
    .subscribe((sucess)=>{
      callback(null, sucess);
    },(err)=>{
      callback(err, null);
    });
  }
  
  public removeIcon(icon: Icone, kitUuid: string, callback: Function){
    this.http.delete(`${this.apiHost}/api/v1/icons/${kitUuid}/icon?dynamic=${icon.dinamica}&status=${icon.status}`, 
    {headers: this.loginService.authorizationHeader()} ).subscribe(
      (sucess)=>{
        callback(null, sucess);
      },
      (error: any)=>{
        callback(error, null);
      }
    )
  }

}
