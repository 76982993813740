import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Icone, iconeDynamics, IconePost, KitIcones, KitIconesCreate, KitIconesDelete, KitIconesSearch } from 'src/app/dto/kitIcones';
import { KitIconesService } from 'src/app/services/kit-icones.service';


@Component({
  selector: 'app-kit-icones-form',
  templateUrl: './kit-icones-form.component.html',
  styleUrls: ['./kit-icones-form.component.css']
})
export class KitIconesFormComponent implements OnInit {
  kitCreate: KitIconesCreate = new KitIconesCreate();
  kitDelete: KitIconesDelete = new KitIconesDelete();
  iconePost: IconePost = new IconePost();
  kitIcones: KitIcones;
  iconsList: Icone [];
  showSelectNewStandardModal: boolean = false;
  kiUsadoCampanha: boolean;
  showModal: boolean = false;
  kitIconesList: KitIcones [];
  search: KitIconesSearch =  new KitIconesSearch();
  iconeDynamics: Array<object> = iconeDynamics;


  constructor(
    private kitIconesService: KitIconesService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
    ) { }

  ngOnInit() {
    const uuid = this.route.snapshot.paramMap.get("uuid");

    if(uuid){
      const {kitIcones} =  history.state;
      
      if(!kitIcones){
        this.router.navigate(["/kiticones"]);
      }else{
        this.kitCreate = new KitIconesCreate(kitIcones);
        this.kitDelete = new KitIconesDelete(kitIcones);
        this.iconsList = kitIcones.icons;
      }
    }else{
      this.kitCreate = new KitIconesCreate();
      this.iconsList = new Array<Icone>();
    }
   
  }

  getAllKitIcones(reset: boolean = false): void{
    this.kitIconesService.buscar(this.search, (err, sucess)=>{
      if(err){
        console.log(err);
      }else{
        this.kitIconesList = sucess;
      }
    });
  }
  

  removeKit2(){
    if(!this.kitDelete.replace){
      this.toastr.warning("Selecione um kit.");
      return;
    }
    this.removeKit()
  }
  removeKit(){
    if(!confirm("Tem certeza que deseja excluir o kit " + this.kitCreate.name +"?")){
      return;
    }

    this.kitIconesService.removeKit(this.kitDelete,(err, sucess)=>{
      if(err){
        console.log(err.error)
        if(err.error && err.error.message) {
          if(err.error.message === "Kit de ícones para substituir não encontrado."){
            this.kiUsadoCampanha = true;
            
            this.getAllKitIcones();
            this.openModal();
        
          } else if(err.error.message === "O kit de ícones padrão não pode ser excluído, selecione um novo kit padrão antes de excluir este kit"){
            this.kiUsadoCampanha = false;
            this.toastr.error(err.error.message);
          }else{
            this.toastr.error("Erro ao processar a requisição. Tente novamente mais tarde ou contate o suporte, por favor.");

          }
        }
      } else {
        this.toastr.success("O kit de ícones foi removido com sucesso!");
        this.router.navigate(['kiticones'])
      }
    })
  }

  removeIcon(icon: Icone){
    if(!confirm("Tem certeza que deseja excluir o icone ?")){
      return;
    }
    this.spinner.show();
    this.kitIconesService.removeIcon(icon, this.kitCreate.uuid, (err, sucess)=>{
      this.spinner.hide();
      if(err){
        console.log(err);
        this.toastr.error(err.message || "Erro ao processar a solicitação.");
      }else{
        this.toastr.success("Icone removido com sucesso.");
        this.iconsList = this.iconsList.filter(i => i.uuid != icon.uuid)
      }
    })
  }
  submitKit(){
    this.spinner.show();
    this.kitIconesService.postKit(this.kitCreate, (err, sucess)=>{
      this.spinner.hide();
      if(err){
        console.log(err);
        this.toastr.error(err.message || "Erro ao processar a solicitação.");
      }else{
        if(this.kitCreate.uuid){
          this.toastr.success("Kit atualizado com sucesso");
        }else{
          this.toastr.success("Kit criado com sucesso!");
          console.log(sucess);
          this.kitCreate = new KitIconesCreate(sucess);
          this.kitDelete = new KitIconesDelete(sucess);
          console.log(this.kitCreate)
          
        }
      }
    })
  }

  openModal(){
    this.showModal = true;
  }
  closeModal(){
    this.showModal = false;
  }


  onSelectFile(event) {
    if(event.target.files[0].type !== "image/png"){
      this.toastr.error("So são permitidas imagens no formato .png")
      return
    }
    if (event.target.files && event.target.files[0]) {
        this.iconePost.file = event.target.files[0];
    }
    console.log(event.target.files[0].type)
    
  }
  sendIcon(){
    if(!this.iconePost.file){
      this.toastr.error("Selecione um ícone antes de enviar.");
      return;
    }
    this.spinner.show();
    this.kitIconesService.postIcon(this.iconePost, this.kitCreate.uuid, (err, sucess)=>{
      this.spinner.hide();
      if(err){
        console.log(err)
        this.toastr.error(err.message || "Erro ao processar a solicitação.");
      }else{
        this.toastr.success("Ícone adicionado com sucesso");
        this.iconsList = sucess.icons;
      }
    })
  }

}
