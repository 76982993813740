import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import EligibilityError from 'src/app/dto/elegiblidade-async/EligibilityError';
import EligibilityErrorPage from '../../../dto/elegiblidade-async/EligibilityErrorPage';

@Component({
  selector: 'app-arquivo-erros',
  templateUrl: './arquivo-erros.component.html',
  styleUrls: ['./arquivo-erros.component.css']
})
export class ArquivoErrosComponent implements OnInit {

  @Input() erros = new EligibilityErrorPage();
  @Input() name = '';
  @Input() lockLoadMore = false;

  @Output() loadMore = new EventEmitter<void>();
  constructor() { }

  ngOnInit() {
  }

  onLoadMore(){
    this.loadMore.emit();
  }




}
