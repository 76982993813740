import { OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme.service';
import { VideoService } from 'src/app/services/video.service';
import { Theme } from 'src/app/dto/theme';
import { Video } from 'src/app/dto/video';

@Component({
    selector: 'app-video-list',
    templateUrl: './video-list.component.html',
    styleUrls: ['./video-list.component.css']
  })
export class VideoListComponent implements OnInit{

  themes: Theme[]
  name: string = ""
  code: string = ""
  theme: string = ""
  videos: Video[]
  textoBuscar: string = "Buscar"
  classeBuscar: string = "button is-primary"

  constructor(private videoService: VideoService, private themeService: ThemeService, private router: Router){}

  ngOnInit(): void {
    this.name = ""
    this.themeService.themes(null, (erro: any, temasRetornados: Theme[]) => {
      this.themes = temasRetornados
    })
    this.buscar()  
  }

  buscar(){
    this.textoBuscar = "Buscando..."
    this.classeBuscar = "button is-light"

    this.videoService.buscar(this.name, this.theme, this.code, (erro: any, resultado: Video[]) => {
      if(resultado){
          this.videos = resultado
          this.textoBuscar = "Buscar"
          this.classeBuscar = "button is-primary"
      }
    })
  }

  edit(v: Video){
    this.router.navigate(['video/edit/' + v.uuid], {state: {data: v}});
  }

}