export class Interesse {
    uuid: string
    name: string
    active: boolean

    constructor(interesse: Interesse = null){
        if(interesse){
            this.uuid = interesse.uuid;
            this.name = interesse.name;
            this.active = interesse.active;
        } else{
            this.active = false;
        }
    }

    checked(){
        if(this.active){
            return "checked";
        }else{
            return "";
        }
    }
} 
