export class User{
    active: boolean;
    birthdate: string;
    email: string;
    gender: string;
    lastName: string;
    name: string;
    profile: Profile;
    uuid: string;
    age: number;
    enrollment: string;
}

export class Profile{
    uuid: string;
    name: string;
}

export class CreateUser{
    active: boolean;
    birthDate: string;
    email: string;
    gender: string;
    lastName: string;
    name: string;
    profile: string;
    uuid: string;
    password: string;
    nascimento: Date;
    age: number;
    enrollment: string;

    constructor(user: User = null){
        if(user){
            this.active = user.active;
            if(user.birthdate){
                this.nascimento = this.parse(user.birthdate);
            }
            this.email = user.email;
            this.gender = user.gender;
            this.lastName = user.lastName;
            this.name = user.name;
            this.profile = user.profile.uuid;
            this.uuid = user.uuid;
            this.age = user.age;
            this.enrollment = user.enrollment;

        }else{
            this.active = true;
        }
    }

    checked(){
        if(this.active){
            return "checked";
        }else{
            return "";
        }
    }

    formataData(){
        if(this.nascimento){
            this.birthDate = this.format(this.nascimento);
        }
    }

    format(data: Date){
        
        var dia  = data.getDate().toString(),
            diaF = (dia.length == 1) ? '0'+dia : dia,
            mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = (mes.length == 1) ? '0'+mes : mes,
            anoF = data.getFullYear();
        return diaF+"/"+mesF+"/"+anoF;
    }

    parse(data: string){
        var arrDataExclusao = data.split('/');
        var stringFormatada = arrDataExclusao[1] + '/' + arrDataExclusao[0] + '/' +arrDataExclusao[2];
        var dataFormatada1 = new Date(stringFormatada);

        return dataFormatada1;
    }
}