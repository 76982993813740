import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Bloco } from '../dto/bloco-dinamica/bloco';
import { Erro400 } from '../dto/erros';
import { FiltroBuscaDinamica } from '../dto/filtroBuscaDinamica';
import { LoginService } from './login.service';
@Injectable({
  providedIn: 'root'
})
export class BlocoDinamicaService {
  apiHost: string = environment.apiHost;

  constructor( private http : HttpClient, private loginService: LoginService ) { }

  buscar(filtroBuscaDinamica: FiltroBuscaDinamica) {
    let httpParams = new HttpParams();

    if (filtroBuscaDinamica.name) {
      httpParams = httpParams.set('name', filtroBuscaDinamica.name);
    }
    if (filtroBuscaDinamica.theme) {
      httpParams = httpParams.set('theme', filtroBuscaDinamica.theme);
    }
    if (filtroBuscaDinamica.code) {
      httpParams = httpParams.set('code', filtroBuscaDinamica.code);
    }
    return this.http.get(`${this.apiHost}/api/v1/dynamicBlock`, { params: httpParams });
  }

  salvar(bloco: Bloco) {
    return this.http.post(`${this.apiHost}/api/v1/dynamicBlock`, bloco);
  }
  editar(bloco: Bloco) {
    return this.http.put(`${this.apiHost}/api/v1/dynamicBlock/${bloco.uuid}`, bloco);
  }

}
