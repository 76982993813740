export class KitDto{
    uuid: string;
    nome: string;
    descricao: string;
    ativo: boolean;
    trofeuAtivo: string;
    trofeuInativo: string;
    kitCampanhaDiaDto: Array<KitDiaDto>;

    constructor(kit: KitDto = null){
        if(kit){
            this.uuid = kit.uuid;
            this.nome = kit.nome;
            this.descricao = kit.descricao;
            this.ativo = kit.ativo;
            this.trofeuAtivo = kit.trofeuAtivo;
            this.trofeuInativo = kit.trofeuInativo;
            this.kitCampanhaDiaDto = new Array<KitDiaDto>();
            if(kit.kitCampanhaDiaDto){
                kit.kitCampanhaDiaDto.forEach(dia => this.kitCampanhaDiaDto.push(new KitDiaDto(dia)));
            }

        }else{
            this.ativo = false;
            this.kitCampanhaDiaDto = new Array<KitDiaDto>();
        }
    }

    checked(){
        if(this.ativo){
            return "checked";
        }else{
            return "";
        }
    }
}

export class KitDiaDto{
    uuid: string;
    dia: number;
    trofeuAtivo: string;
    trofeuInativo: string;
    kitsDinamica: Array<KitDinamicaDto>;
    class: string = '';
    constructor(dia: KitDiaDto = null){
        if(dia){
            this.uuid = dia.uuid;
            this.dia = dia.dia;
            this.trofeuAtivo = dia.trofeuAtivo;
            this.trofeuInativo = dia.trofeuInativo;
            this.kitsDinamica = dia.kitsDinamica;
            this.class = dia.class;
            if(this.kitsDinamica && this.kitsDinamica.length > 0){
                let nh = this.kitsDinamica.filter(kd => kd.tipo === 'NEW_HABIT');
                if(!nh || nh.length === 0){
                    let kitDinamicaNh = new KitDinamicaDto();
                    kitDinamicaNh.tipo = 'NEW_HABIT';
                    this.kitsDinamica.push(kitDinamicaNh);
                }
                let mural = this.kitsDinamica.filter(kd => kd.tipo === 'MURAL');
                if(!mural || mural.length === 0){
                    let kitDinamicaNh = new KitDinamicaDto();
                    kitDinamicaNh.tipo = 'MURAL';
                    this.kitsDinamica.push(kitDinamicaNh);
                }
            }
        }
    }
}

export class KitDinamicaDto{
    uuid: string;
    tipo: string;
    trofeuAtivo: string;
    trofeuInativo: string;
}
