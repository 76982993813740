import { Theme } from '../theme';
import { BlocoDinamica } from './blocoDinamica';
import { TypeRedirect } from './typeRedirect';

export class Bloco{
    uuid: string;
    code: string;
    nameDay: string;
    name: string;
    active: boolean;
    description: string;
    theme: Theme;
    dynamics: BlocoDinamica[] =  new Array<BlocoDinamica>();
    title: string;
    typeRedirect: TypeRedirect;

    constructor(bd: Bloco = null){
        if(bd){
            this.uuid = bd.uuid;
            this.code = bd.code;
            this.name = bd.name;
            this.active = bd.active;
            this.nameDay = bd.nameDay;
            this.description = bd.description;
            this.theme = bd.theme;
            this.dynamics = bd.dynamics;
            this.title = bd.title;
        }else{
            this.theme = new Theme();
            this.active = false;
        }
    }

    checked(){
        if(this.active){
            return "checked";
        }else{
            return "";
        }
    }
}

