import { HttpHeaders } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Erro400 } from "src/app/dto/erros";
import { EventDto } from "src/app/dto/event";
import { RelatorioService } from "src/app/services/relatorio.service";

@Component({
    selector: 'app-relatorio',
    templateUrl: './relatorio.component.html',
    styleUrls: ['./relatorio.component.css']
  })
export class RelatorioComponent implements OnInit{
    @Input() evento: EventDto;
    tipo: string;

    constructor(private relatorioService: RelatorioService, private toastr: ToastrService){

    }
    
    ngOnInit(): void {
        
    }

    gerar(){
        this.relatorioService.relatorio(this.evento.code, this.tipo, (erro: Erro400, sucesso: any) => {
            if(sucesso){
              const temp: HttpHeaders = sucesso.headers;
              this.downLoadFile(sucesso.body, 'application/ms-excel');
            }else if(erro){
              this.toastr.error('', erro.error.message);
            }
          })
    }

    downLoadFile(data: any, type: string) {
        var blob = new Blob([data], { type: type.toString() });
        var url = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.download = "report-" + this.evento.uuid + ".xlsx";
        anchor.href = url;
        anchor.click();
    }

}