export class EstiloRequest{
    uuid: string
    page: string
    foregroundColor: string
    backgroundColor: string
}

export class Estilo{
    uuid: string
    page: string
    backgroundColor: string
    foregroundColor: string
    backgroundImage: string
    backgroundImageDesktop: string
    event: string
    mimeType: string

    constructor(estilo: Estilo = null){
        if(estilo){
            this.uuid = estilo.uuid
            this.page = estilo.page
            this.backgroundColor = estilo.backgroundColor
            this.foregroundColor = estilo.foregroundColor
            this.event = estilo.event
            this.mimeType = estilo.mimeType
        }
    }
}