import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BibliotecalistComponent } from './views/biblioteca-list/biblioteca-list.component';
import { BlocoDinamicaFormComponent } from './views/bloco-dinamica-form/bloco-dinamica-form.component';
import { BlocoDinamicaListComponent } from './views/bloco-dinamica-list/bloco-dinamica-list.component';
import { ClienteslistComponent } from './views/clientes-list/clientes-list.component';
import { ClientesformComponent } from './views/clients-form/clientes-form.component';
import { ComoFuncionaListComponent } from './views/como-funciona-list/como-funciona-list.component';
import { ComoFuncionaListFormComponent } from './views/como-funciona/como-funciona-list-form/como-funciona-list-form.component';
import { EventoformComponent } from './views/event-form/evento-form.component';
import { EventoslistComponent } from './views/eventos-list/eventos-list.component';
import { ExtraFormComponent } from './views/extra-form/extra-form-component';
import { ExtraListComponent } from './views/extra-list/extra-list.component';
import { FatoFakeFormComponent } from './views/fato-fake-form/fato-fake-form.component';
import { FatoFakeListComponent } from './views/fato-fake-list/fato-fake-list.component';
import { GameFormComponent } from './views/game-form/game-form.component';
import { GameListComponent } from './views/game-list/game-list.component';
import { HomeComponent } from './views/home/home.component';
import { InteresseFormComponent } from './views/interesse-form/interesse-form.component';
import { InteresseListComponent } from './views/interesse-list/interesse-list.component';
import { KitFormComponent } from './views/kit-form/kit-form.component';
import { KitIconesFormComponent } from './views/kit-icones-form/kit-icones-form.component';
import { KitIconesListComponent } from './views/kit-icones-list/kit-icones-list.component';
import { KitListComponent } from './views/kit-list/kit-list.component';
import { LoginComponent } from './views/login/login.component';
import { MensagemListComponent } from './views/mensagem-list/mensagem-list.component';
import { MensagemVideoFormComponent } from './views/mensagem-video-form/mensagem-video-form.component';
import { MensagemVideoListComponent } from './views/mensagem-video-list/mensagem-video-list.component';
import { NewHabitForm } from './views/new-habit-form/new-habit-form.component';
import { NewHabitListComponent } from './views/new-habit-list/new-habit-list.component';
import { ParticipanteForm } from './views/participante-form/participante-form.component';
import { ParticipanteListComponent } from './views/participante-list/participante-list.component';
import { TemplateFormComponent } from './views/template-form/template-form.component';
import { TemplateListComponent } from './views/template-list/template-list.component';
import { ThemesFormComponent } from './views/themes-form/themes-form.component';
import { ThemesListComponent } from './views/themes-list/themes-list.component';
import { UserformComponent } from './views/user-form/user-form.component';
import { UserslistComponent } from './views/users-list/users-list.component';
import { VideoFormComponent } from './views/video-form/video-form.component';
import { VideoListComponent } from './views/video-list/video-list.component';
import { MuralListComponent } from './views/mural-list/mural-list.component';
import { MuralFormComponent } from './views/mural-form/mural-form-component';
const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'users',
    component: UserslistComponent
  },
  {
    path: 'users/edit/:uuid',
    component: UserformComponent
  },
  {
    path: 'users/new',
    component: UserformComponent
  },
  {
    path: 'clients',
    component: ClienteslistComponent
  },
  {
    path: 'events',
    component: EventoslistComponent
  },
  {
    path: 'library',
    component: BibliotecalistComponent
  },
  {
    path: 'clients/new',
    component: ClientesformComponent
  },
  {
    path: 'clients/edit/:uuid',
    component: ClientesformComponent
  },
  {
    path: 'events/new',
    component: EventoformComponent
  },
  {
    path: 'events/edit/:uuid',
    component: EventoformComponent
  },
  {
    path:'events/:uuid/video-message/list',
    component: MensagemVideoListComponent
  },
  {
    path: 'events/:uuid/video-message',
    component: MensagemVideoFormComponent
  },
  {
    path: 'events/:uuid/video-message/:guuid',
    component: MensagemVideoFormComponent
  },
  {
    path: 'themes',
    component: ThemesListComponent
  },
  {
    path: 'themes/edit/:uuid',
    component: ThemesFormComponent
  },
  {
    path: 'themes/new',
    component: ThemesFormComponent
  },
  {
    path: 'factFakes',
    component: FatoFakeListComponent
  },
  {
    path: 'factFake/edit/:uuid',
    component: FatoFakeFormComponent
  },
  {
    path: 'factFake/new',
    component: FatoFakeFormComponent
  },
  {
    path: 'video',
    component: VideoListComponent
  },
  {
    path: 'video/new',
    component: VideoFormComponent
  },
  {
    path: 'video/edit/:uuid',
    component: VideoFormComponent
  },
  {
    path: 'game',
    component: GameListComponent
  },
  {
    path: 'game/new',
    component: GameFormComponent
  },
  {
    path: 'game/edit/:uuid',
    component: GameFormComponent
  },
  {
    path: 'extra',
    component: ExtraListComponent
  },
  {
    path: 'extra/new',
    component: ExtraFormComponent
  },
  {
    path: 'extra/edit/:uuid',
    component: ExtraFormComponent
  },
  {
    path: 'kits',
    component: KitListComponent
  },
  {
    path: 'kits/new',
    component: KitFormComponent
  },
  {
    path: 'kits/edit/:uuid',
    component: KitFormComponent
  },
  {
    path: 'participants',
    component: ParticipanteListComponent
  },
  {
    path: 'participants/edit/:uuid',
    component: ParticipanteForm
  },
  {
    path: 'newhabits',
    component: NewHabitListComponent
  },
  {
    path: 'newhabits/edit/:uuid',
    component: NewHabitForm
  },
  {
    path: 'newhabits/new',
    component: NewHabitForm
  },
  {
    path: 'interesses',
    component: InteresseListComponent
  },
  {
    path: 'interesses/edit/:uuid',
    component: InteresseFormComponent
  },
  {
    path: 'interesses/delete/:uuid',
    component: InteresseFormComponent
  },
  {
    path: 'interesses/new',
    component: InteresseFormComponent
  },
  {
    path: 'messages',
    component: MensagemListComponent
  },
  {
    path: 'kiticones',
    component: KitIconesListComponent
  },
  {
    path: 'kiticones/new',
    component: KitIconesFormComponent
  },
  {
    path: 'kiticones/edit/:uuid',
    component: KitIconesFormComponent
  },

  {
    path: 'templates',
    component: TemplateListComponent
  },
  {
    path: 'templates/new',
    component: TemplateFormComponent
  },
  {
    path: 'templates/edit/:uuid',
    component: TemplateFormComponent
  },
  {
    path: 'templates/delete/:uuid',
    component: TemplateFormComponent
  },
  {
    path: 'comoFunciona',
    component: ComoFuncionaListComponent
  },
  {
    path: 'comoFunciona/new',
    component: ComoFuncionaListFormComponent
  },
  {
    path: 'comoFunciona/edit/:uuid',
    component: ComoFuncionaListFormComponent
  },
  {
    path: 'mural',
    component: MuralListComponent
  },
  {
    path: 'mural/new',
    component: MuralFormComponent
  },
  {
    path: 'mural/edit/:uuid',
    component: MuralFormComponent
  },
  {
    path: 'blocoDinamica',
    component: BlocoDinamicaListComponent
  },
  {
    path: 'bloco/edit/:uuid',
    component: BlocoDinamicaFormComponent
  },
  {
    path: 'bloco/new',
    component: BlocoDinamicaFormComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
