import { Type } from "../views/mensagem-video-form/enum/MensagemType";
import { Event } from "./event";
import { Theme } from "./theme";
import { Video } from "./video";

export class MensagemVideoDTO {
	uuid: string;
	startDate: string;
	endDate: string;
	title: string;
	message: string;
	active: boolean;
	video: string;
	theme: string;
	event: string;

	image: string;

	start: Date;
	end: Date;
	isVideo: boolean;
	type: string ;

	constructor(mensagemVideo: MensagemVideo = null) {
		if (mensagemVideo) {
			this.start = this.parse(mensagemVideo.startDate);
			this.end = this.parse(mensagemVideo.endDate);
			this.uuid = mensagemVideo.uuid;
			this.startDate = mensagemVideo.startDate;
			this.endDate = mensagemVideo.endDate;
			this.title = mensagemVideo.title;
			this.message = mensagemVideo.message;
			this.image = mensagemVideo.image;
			this.active = mensagemVideo.active;
			if(mensagemVideo.video){
				this.video = mensagemVideo.video.uuid;
				this.theme = mensagemVideo.video.theme.uuid;
			}
			this.type = mensagemVideo.type;
			this.isVideo = this.type === Type.Video || this.type === null;

		} else {
			this.active = false;
			this.isVideo = false;
			this.type = "MENSAGEM";
		}
	}

	checked() {
		if (this.active) {
			return "checked";
		} else {
			return "";
		}
	}

	checkedVideo() {
		if (this.isVideo) {
			return "checked";
		} else {
			return "";
		}
	}

	formatarDatasParaEnvio() {
		if (this.start) {
			this.startDate = this.format(this.start);
		}
		if (this.end) {
			this.endDate = this.format(this.end);
		}
	}

	format(data: Date) {
		var dia = data.getDate().toString(),
			diaF = dia.length == 1 ? "0" + dia : dia,
			mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
			mesF = mes.length == 1 ? "0" + mes : mes,
			anoF = data.getFullYear();
		return diaF + "/" + mesF + "/" + anoF;
	}

	parse(data: string) {
		var arrDataExclusao = data.split("/");
		var stringFormatada =
			arrDataExclusao[1] + "/" + arrDataExclusao[0] + "/" + arrDataExclusao[2];
		var dataFormatada1 = new Date(stringFormatada);

		return dataFormatada1;
	}
}

export class MensagemVideo {
	uuid: string;
	startDate: string;
	endDate: string;
	title: string;
	message: string;
	image: string;
	active: boolean;
	video: Video;
	theme: Theme;
	event: Event;
	start: Date;
	end: Date;
	type: string;
}
