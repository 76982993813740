export class FiltroBiblioteca {
    theme: string;
    code: string;
    name: string;
    pageSize: number;
    constructor(theme="", code="", name="", pageSize=Number.MAX_VALUE) {
        this.theme = theme;
        this.code = code;
        this.name = name;
        this.pageSize = pageSize;
    }        
}
