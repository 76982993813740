import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Bloco } from 'src/app/dto/bloco-dinamica/bloco';
import { Theme } from 'src/app/dto/theme';
import { TypeRedirect } from '../../../dto/bloco-dinamica/typeRedirect';
import { Dinamica } from '../../../dto/dinamica';


@Component({
  selector: 'app-dados-bloco',
  templateUrl: './dados-bloco.component.html',
  styleUrls: ['./dados-bloco.component.css']
})
export class DadosBlocoComponent {
  @Input()
  bloco: Bloco;

  @Input()
  themes: Theme[] ;

  typeRedirect: TypeRedirect;

  @Output()
  onChangeBloco: EventEmitter<Bloco> = new EventEmitter<Bloco>();

  dinamicas: Dinamica[];

  salva: boolean = false;



  constructor() { }

  blocoEditado( type: string ) {
    switch (type) {
      case 'ONLY_SAVE':
        this.typeRedirect = TypeRedirect.ONLY_SAVE;
        break;
      case 'SAVE_LIST':
        this.typeRedirect = TypeRedirect.SAVE_LIST;
        break;
      case 'SAVE_AND_NEW':
        this.typeRedirect = TypeRedirect.SAVE_AND_NEW;
        break;
    }
    this.salva = true;
    this.bloco.typeRedirect = this.typeRedirect;
  }
  onChangeBlocoDinamicas(bloco: Bloco) {
    this.bloco = bloco;
    this.onChangeBloco.emit(this.bloco);
    this.salva = false;
  }
}
