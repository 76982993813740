import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoginService } from './login.service';
import { Dinamica } from '../dto/dinamica';
import { Agenda, AgendaRequest, AgendaEditDto } from '../dto/agenda';
import { Erro400 } from '../dto/erros';

@Injectable({
  providedIn: 'root'
})
export class DinamicaService {

  apiHost: string = environment.apiHost;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) { }

  public buscar(tema: string, tipo: string, callback: Function) {
    let params = new HttpParams();
    if(tipo){
        params = params.set('type', tipo);
    }

    this.http.get(this.apiHost + '/api/v1/theme/' + tema + '/dynamics',{headers: this.loginService.authorizationHeader(), params: params}).subscribe(
      (dinamicas: Dinamica[]) => {
        callback(null, dinamicas);
      },
      (error: any) => {
        callback(error, null);
      }
    );
  }

  public deletar(uuidEvento: string, agenda: AgendaRequest, activity: string, confirmDelete: string, callback: Function){
    let params = new HttpParams();
    params = params.set('confirm', confirmDelete !== null ? confirmDelete : "false")
    this.http.delete(this.apiHost + '/api/v1/event/' + uuidEvento + '/schedule/' + agenda.uuid + "/activity/" + activity,
     {headers: this.loginService.authorizationHeader(), params: params}).subscribe(
        (sucesso: Agenda) => {
            callback(null, sucesso);
        },
        (falha: Erro400) => {
            callback(falha, null);
        }
    );
  }
  
  public enviarImagem(uuidDinamica: string, arquivo: File, callback: Function){
    const formData:FormData = new FormData();
    formData.append('file', arquivo);

    this.http.post(this.apiHost + '/api/v1/dynamic/' + uuidDinamica + '/image/', formData,
    {headers: this.loginService.authorizationHeader()}).subscribe(
       (sucesso) => {
           callback(null, sucesso);
       },
       (falha: Erro400) => {
           callback(falha, null);
       }
   );
  }

  public deleteImagem(uuidDinamica: string, callback: Function){
    this.http.delete(this.apiHost + "/api/v1/dynamic/" + uuidDinamica + "/image", 
    {headers: this.loginService.authorizationHeader()}).subscribe(
      (sucesso) => {
          callback(null, sucesso);
      },
      (falha: Erro400) => {
          callback(falha, null);
      });
  }

  public getImagem(uuidDinamica: string, callback: Function){
    this.http.get(this.apiHost + "/api/v1/dynamic/" + uuidDinamica + "/image", 
    {headers: this.loginService.authorizationHeader(), responseType: 'blob'}).subscribe(
      (sucesso) => {
          callback(null, sucesso);
      },
      (falha: Erro400) => {
          callback(falha, null);
      });
  }
}
