import { Theme } from './theme'

export class NewHabit{
    uuid: string
    title: string;
    code: string
    presentation: string
    name: string
    active: boolean
    description: string
    picture: string
    theme: Theme
    cards: Array<CardHabit>;

    constructor(newHabit: NewHabit = null){
        if(newHabit){
            this.uuid = newHabit.uuid;
            this.code = newHabit.code;
            this.presentation = newHabit.presentation;
            this.name = newHabit.name;
            this.active = newHabit.active;
            this.description = newHabit.description;
            this.picture = newHabit.picture;
            this.theme = newHabit.theme;
            this.cards = newHabit.cards;
            this.title = newHabit.title;
        }else{
            this.active = false;
            this.theme = new Theme();
            this.cards = new Array<CardHabit>();
        }
    }

    checked(){
        if(this.active){
            return "checked";
        }else{
            return "";
        }
    }
}

export class CardHabit{
   uuid: string
   order: number
   title: string
   description: string
   picture: string 
}

export class NewHabitsCopiar{
    title: string;
    name: string;
    code: string;
    theme: String;
    presentation: string;
    description: string;

    constructor(copia: NewHabitsCopiar = null){
        if(copia){
            this.code = copia.code;
            this.presentation = copia.presentation;
            this.name = copia.name;
            this.description = copia.description;
            this.theme = copia.theme;
            this.title = copia.title;
        }
    }
}