import { CreateUser, User } from './user';

export class Participante{
    enrollment: string;
    uuid: string;
    active: boolean;
    activationDate: string;
    unit: string;
    subUnit: string;
    user: User;
    campaignCode: string;
    clientUuid: string;
    campaignName: string;
    participantType:string;
    acceptLegalTerm: Date;
    accounted: boolean
    level3: string;

}

export class CreateParticipante{
    user: CreateUser;
    enrollment: string;
    uuid: string;
    active: boolean;
    activationDate: string;	
    unit: string;
    campaignCode: string;
    clientUuid: string;
    campaignName: string;
    participantType:string;
    accounted: boolean;
    subUnit: string;
    level3: string;
    
    constructor(participante: Participante = null){
        if(participante){
            this.uuid = participante.uuid;
            this.unit = participante.unit;
            this.active = participante.active;
            this.enrollment = participante.enrollment;
            this.campaignCode = participante.campaignCode;
            this.clientUuid = participante.clientUuid;
            this.campaignName = participante.campaignName;
            this.user = new CreateUser();
            this.user.active = participante.user.active;
            this.user.birthDate = participante.user.birthdate;
            this.user.email = participante.user.email;
            this.user.gender = participante.user.gender;
            this.user.lastName = participante.user.lastName;
            this.user.name = participante.user.name;
            this.user.profile = participante.user.profile.uuid;
            this.user.uuid = participante.user.uuid;
            this.user.password = "";
            this.user.age = participante.user.age;
            this.user.enrollment = participante.user.enrollment;
            this.accounted = participante.accounted;
            this.participantType = participante.participantType;
            this.subUnit = participante.subUnit;
            this.level3 = participante.level3;

            if(participante.user.birthdate){
                this.user.nascimento = this.user.parse(participante.user.birthdate);
            }
        }else{
            this.user = new CreateUser();
        }
    }

    checked(){
        if(this.active){
            return "checked";
        }else{
            return "";
        }
    }
}
