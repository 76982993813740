export class ComoFunciona{
  helpMessage: string;
  uuid: string;
  titulo: string
  constructor(comoFunciona:ComoFunciona=null){
    if(comoFunciona){
      this.helpMessage = comoFunciona.helpMessage;
      this.uuid = comoFunciona.uuid;
      this.titulo = comoFunciona.titulo;
    }
  }

}

