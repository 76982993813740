import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Client } from '../dto/client';
import { Erro400 } from '../dto/erros';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
  })
export class ClientsService{
    private apiHost: string = environment.apiHost;

    constructor(
        private http:HttpClient,
        private loginService: LoginService
    ) { }

    public buscar(name: string, notes: string, pagina: number, quantidadePorPagina: number, callback: Function){

        let params = new HttpParams();
        if(name){
            params = params.set('name', name);
        }
        if(notes){
            params = params.set('notes', notes);
        }
        
        params = params.set('page', pagina + '');
        params = params.set('pageSize', quantidadePorPagina + '');

        this.http.get(this.apiHost + '/api/v1/client', {headers: this.loginService.authorizationHeader(), params: params}).subscribe(
            (sucesso: Array<Client>) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }

        );
    }

    public get(uuid: string, callback: Function){
        this.http.get(this.apiHost + '/api/v1/client/' + uuid, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: Client) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public criar(cliente: Client, callback: Function){
        this.http.post(this.apiHost + '/api/v1/client', cliente, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: Client) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }

    public editar(user: Client, callback: Function){
        this.http.put(this.apiHost + '/api/v1/client/' + user.uuid, user, {headers: this.loginService.authorizationHeader()}).subscribe(
            (sucesso: Client) => {
                callback(null, sucesso);
            },
            (falha: Erro400) => {
                callback(falha, null);
            }
        );
    }
}