import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

  format(data: Date) {
    const dia  = data.getDate().toString();
    const diaF = (dia.length === 1) ? '0' + dia : dia;
    const mes  = (data.getMonth() + 1).toString(); // +1 pois no getMonth Janeiro começa com zero.
    const mesF = (mes.length === 1) ? '0' + mes : mes;
    const anoF = data.getFullYear();
    return diaF + '/' + mesF + '/' + anoF + ' ' + data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds();
  }

  parse(data: string){
      const arrDataExclusao = data.split('/');
      const stringFormatada = arrDataExclusao[1] + '/' + arrDataExclusao[0] + '/' + arrDataExclusao[2];
      const dataFormatada1 = new Date(stringFormatada);

      return dataFormatada1;
  }

  formatOnlyData(data: Date) {
    const dia  = data.getDate().toString();
    const diaF = (dia.length === 1) ? '0' + dia : dia;
    const mes  = (data.getMonth() + 1).toString(); // +1 pois no getMonth Janeiro começa com zero.
    const mesF = (mes.length === 1) ? '0' + mes : mes;
    const anoF = data.getFullYear();
    return diaF + '/' + mesF + '/' + anoF;
  }
}
