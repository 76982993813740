import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { version } from '../../package.json';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public version: string = version;

  public menuAtivo: boolean = false;

  title = 'Weex Digital BackOffice';

  public exporMenu() {
    this.menuAtivo = ! this.menuAtivo;
  }

  public goHome(): void {
    if (this.loginService.autorizacao() != null) {
      this.router.navigateByUrl('/home')
    } else {
      this.router.navigateByUrl('/')
    }
  }

  public statusLogin: string = "loggedOut";

  public logout():void {
    this.loginService.logout(() => {
      this.router.navigateByUrl('')
    })
  }

  public autenticado():boolean {
    return this.loginService.autorizacao() != null
  }

  private listenLogin(evento:string) {
    this.statusLogin = evento;
  }

  constructor(

    private loginService:LoginService,
    private router:Router
    ) {}

  public ngOnInit() {
    if (this.loginService.autorizacao() != null) {
      this.statusLogin = "loggedIn";
      this.router.navigate(['/home']);
    } else {
      this.statusLogin = "loggedOut";
      this.router.navigate(['/']);
    }
    this.loginService.subscribe((eventoLogin:string) => {
      this.statusLogin = eventoLogin;
    })

  }

}
