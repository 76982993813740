import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Theme } from '../dto/theme';
import { Erro } from '../dto/erros';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  apiHost: string = environment.apiHost;

  constructor(private http: HttpClient) { }

  public themes(name: string, callback: Function) {
    let httpParams: HttpParams = new HttpParams()
    if (name) {
      httpParams = httpParams.append("name", name)
    }
    this.http.get(this.apiHost + '/api/v1/theme', { params: httpParams }).subscribe(
      (themes: Theme[]) => {
        callback(null, themes);
      },
      (error: any) => {
        callback(error, null);
      }
    );
  }

  public get(uuid: string, callback: Function) {
    this.http.get(this.apiHost + '/api/v1/theme/' + uuid).subscribe(
      (theme: Theme) => {
        callback(null, theme);
      },
      (error: any) => {
        callback(error, null);
      }
    );
  }

  public create(theme: Theme, callback: Function) {
    this.http.post(this.apiHost + '/api/v1/theme', theme).subscribe(
      (persistedTheme: Theme) => {
        callback(null, persistedTheme);
      },
      (error: any) => {
        callback(error, null);
      }
    );
  }

  public update(theme: Theme, callback: Function) {
    this.http.put(this.apiHost + '/api/v1/theme/' + theme.uuid, theme).subscribe(
      (persistedTheme: Theme) => {
        callback(null, persistedTheme);
      },
      (error: any) => {
        callback(error, null);
      }
    );
  }

  public delete(uuid: string, callback: Function) {
    this.http.delete(this.apiHost + '/api/v1/theme/' + uuid).subscribe(
      (success: any) => {
        callback(null, true);
      },
      (error: any) => {
        callback(error, null);
      }
    );
  }
}
