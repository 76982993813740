import { Interesse } from './interesse';
import { Subtitle } from './Subtitle';
import { Theme } from './theme';
import { Unidade } from './unidade';

export class AgendaRequest {
    uuid: string;
    day: string;
    interest: string;
    order: number;
    required: boolean;
    nome: string;
    activities: Array<AgendaItemRequest>
    units: Array<String>
    subtitles: Array<Subtitle>
}

export class AgendaDinamica {
    uuid: string;
    theme: string;
    type: string;
    dynamic: string;
    uuidAtividade: string;
    order:number;
}

export class AgendaDiaDto {
  uuid: string;
  day: Date;
  game: string;
  factOrFake: string;
  video: string;
  order: number;
  interest: string;
  interestDetail: Interesse
  required: boolean;
  nome: string;

  requiredCk(){
    if(this.required){
        return "checked";
    }else{
        return "";
    }
}
}

export class Agenda {
    days: AgendaDia[];
}

export class AgendaDia {
    uuid: string;
    day: string;
    trophyEnabled: string;
    trophyDisabled: string;
    activities: AgendaAtividade[];
    order: number;
    interest: string;
    interest_name: string;
    interestDetail: Interesse
    required: boolean;
    nome: string;
    units: Unidade[];
    subtitles: Subtitle[];

    requiredCk(){
      if(this.required){
          return "checked";
      }else{
          return "";
    }
  }

}

export class AgendaDiaView {
    uuid: string;
    day: string;
    class: string;
    interest: string;
    order: number;
    interest_name: string;
    required: boolean;
    name: string;

    constructor(dia: AgendaDia = null) {
        if (dia) {
            this.uuid = dia.uuid;
            this.day = dia.day;
            this.class = '';
            this.interest = dia.interest;
            this.interest_name = dia.interest_name
            this.order = dia.order;
            this.name = dia.nome;
            if (dia.interestDetail) {
                this.interest_name = dia.interestDetail.name
                this.interest = dia.interestDetail.uuid
            }
            this.required = dia.required;
        }
    }
}

export class AgendaAtividade {
    type: string;
    code: string;
    name: string;
    uuid: string;
    uuidDynamic: string;
    theme: Theme;
    trophyEnabled: string;
    trophyDisabled: string;
    order: number;
    subtitle: Subtitle;
}

export class AgendaDiaEditDto {
    uuid: string;
    dia: string;
    novoDia: Date;
}

export class AgendaDiaEditRequest {
    uuid: string;
    day: string;
}

export class AgendaEditDto {
    agenda: AgendaDiaEditRequest[];
}

export class AgendaItemRequest{
    uuid: string;
    uuidAtividade: string;
    order: number;
    subtitle: Subtitle;

    constructor(dia: AgendaAtividade = null) {
        if (dia) {
            this.uuid = dia.uuidDynamic;
            this.uuidAtividade = dia.uuid;
            this.order = dia.order;
            this.subtitle = dia.subtitle;
        }
    }
}
