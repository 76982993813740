import { Client } from "./client";

export class TemplateCopyDTO {
    name: string;

    constructor(copy: TemplateCopyDTO = null) {
        if(copy) {
            this.name = copy.name;
        }
    }
}

export class TemplateDTO {
    uuid: string;
    name: string;
    description: string;
    active: boolean;
    standard: boolean;
    colors: Array<TemplateCor>;
    shadows: Array<TemplateSombra>;
    client: Client;
    clientUuid: string;

    constructor(template: TemplateDTO = null){
        if(template){
            this.uuid = template.uuid;
            this.name = template.name;
            this.description = template.description;
            this.active = template.active;
            this.standard = template.standard;
            this.colors = template.colors;
            this.shadows = template.shadows;
            this.client = template.client;
            this.clientUuid = template.client.uuid;
        } else {
            this.active = false;
            this.standard = false;
            this.colors = new Array<TemplateCor>();
            this.shadows = new Array<TemplateSombra>();
            this.client = null;
        }
    }

    checked() {
		if (this.active || this.standard) {
			return "checked";
		} else {
			return "";
		}
	}
}

export class Template {
    uuid: string;
    name: string;
    description: string;
    active: boolean;
    standard: boolean;
    colors: Array<TemplateCor>;
    shadows: Array<TemplateSombra>;
    client: Client;
}

export class TemplateCor {
    uuid: string;
    code: string;
    color: string;

    constructor(cor: TemplateCor = null){
        if(cor){
            this.uuid = cor.uuid;
            this.code = cor.code;
            this.color = cor.color;
        } else {
            this.code = "";
            this.color = ""
        }
    }
}

export class TemplateSombra {
    uuid: string;
    code: string;
    bgColor: string;
    boxShadow: string;

    constructor(sombra: TemplateSombra = null){
        if(sombra){
            this.uuid = sombra.uuid;
            this.code = sombra.code;
            this.bgColor = sombra.bgColor;
            this.boxShadow = sombra.boxShadow;
        } else {
            this.code = "";
            this.bgColor = "";
            this.boxShadow = ""
        }
    }
}

export class TemplateSearch {
    name: string = '';
    description: string = '';
    client: string = '';
    standard: boolean;
    active: boolean;

    page: number = 0;
    pageSize: number = 20;

    constructor(search: TemplateSearch = null){
        if(search){
            this.name = search.name;
            this.description = search.description;
            this.client = search.client;
            this.standard = search.standard;
            this.active = search.active;
            this.page = search.page;
            this.pageSize = search.pageSize;
        } else {
            this.standard = null;
            this.active = null;
        }
    }
}

export const standardOptions = [
    {
        value: "", label: "Todas"
    },
    {
        value: "true", label: "Sim"
    },
    {
        value: "false", label: "Não"
    }
]
export const activeOptions = [
    {
        value: "", label: "Todas"
    },
    {
        value: "true", label: "Sim"
    },
    {
        value: "false", label: "Não"
    }
]

