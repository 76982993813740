import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Erro400 } from '../dto/erros';
import { Template, TemplateCopyDTO, TemplateDTO, TemplateSearch } from '../dto/template';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  private apiHost: string = environment.apiHost;

  constructor(
    private http:HttpClient,
    private loginService: LoginService
  ) { }

  public buscar(search: TemplateSearch, callback: Function){
    const {name, description, standard, active, client, page, pageSize} = search;
    let params = new HttpParams();
    if(name){
      params = params.set('name', name);
    }
    if(description){
      params = params.set('description', description);
    }
    if(standard){
      params = params.set('standard', standard.toString());
    }
    if(active){
      params = params.set('active', active.toString());
    }
    if(client){
      params = params.set('client', client);
    }else{
      params = params.set('client', 'todos');
    }
    if(page){
      params = params.set('page', page + '');
    }
    if(pageSize){
      params = params.set('pageSize', pageSize + '');
    }

    this.http.get(this.apiHost + "/api/v1/template", {headers: this.loginService.authorizationHeader(), params: params}).subscribe(
      (sucesso: Array<Template>) =>{
        callback(null, sucesso);
      },
      (falha: Erro400)=>{
        callback(falha, null);
      }
    )
  }

  public criar(template: TemplateDTO, callback: Function){
    this.http.post(this.apiHost + "/api/v1/template", template, {headers: this.loginService.authorizationHeader()}).subscribe(
      (sucesso: Template) => {
        callback(null, sucesso);
      },
      (erro: Erro400) => {
        callback(erro, null);
      }
    )
  }

  public editar(template: TemplateDTO, callback: Function){
    this.http.put(this.apiHost + "/api/v1/template/" + template.uuid, template, {headers: this.loginService.authorizationHeader()}).subscribe(
      (sucesso: Template) => {
        callback(null, sucesso);
      },
      (erro: Erro400) => {
        callback(erro, null);
      }
    )
  }

  public apagar(templateUuid: string, novoPadrao: string, callback: Function){
    let params = new HttpParams();
    if(novoPadrao){
      params = params.set('newStandard', novoPadrao);
    }
    this.http.delete(this.apiHost + "/api/v1/template/" + templateUuid, {headers: this.loginService.authorizationHeader(), params: params}).subscribe(
      (sucesso: Template) => {
        callback(null, sucesso);
      },
      (erro: Erro400) => {
        callback(erro, null);
      }
    )
  }

  public clonarTemplate(templateUuid: string, templateCopy: TemplateCopyDTO, callback: Function) {
    this.http.post(this.apiHost + "/api/v1/template/" + templateUuid + "/copy", templateCopy, {headers: this.loginService.authorizationHeader()}).subscribe(
      (sucesso: Template) => {
        callback(null, sucesso);
      },
      (erro: Erro400) => {
        callback(erro, null);
      }
    )
  }

  
}
