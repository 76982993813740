import { Theme } from './theme'
import { Questao } from './questao'

export class FatoFake{
    uuid: string
    code: string
    presentation: string
    name: string
    active: boolean
    description: string
    theme: Theme
    questions: Questao[]
    title: string;

    constructor(ff: FatoFake = null){
        if(ff){
            this.uuid = ff.uuid;
            this.code = ff.code;
            this.presentation = ff.presentation;
            this.name = ff.name;
            this.active = ff.active;
            this.description = ff.description;
            this.theme = ff.theme;
            this.questions = new Array<Questao>();
            if(ff.questions && ff.questions.length > 0){
                ff.questions.forEach(q => {this.questions.push(q)});
            }
            this.title = ff.title;
        }else{
            this.theme = new Theme();
            this.questions = new Array<Questao>();
            this.active = false;
        }
    }

    checked(){
        if(this.active){
            return "checked";
        }else{
            return "";
        }
    }
}
export class FatoFakeClone{
    uuid: string;
    uuidOriginal: string;
    code: string;
    presentation: string;
    name: string;
    description: string;
    theme: string;
    title: string;

    constructor(fatoFakeOriginal: FatoFake = undefined){
        if(fatoFakeOriginal){
            this.uuidOriginal = fatoFakeOriginal.uuid;
            this.presentation = fatoFakeOriginal.presentation;
            this.description = fatoFakeOriginal.description;
            this.theme = fatoFakeOriginal.theme.uuid;
        }
    }
}