import { Component, OnInit } from '@angular/core';
import { Theme } from 'src/app/dto/theme';
import { ThemeService } from 'src/app/services/theme.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-themes-form',
  templateUrl: './themes-form.component.html',
  styleUrls: ['./themes-form.component.css']
})
export class ThemesFormComponent implements OnInit {

  theme: Theme

  constructor(
    private themeService: ThemeService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) {
    const nav = this.router.getCurrentNavigation()
    if(nav && nav.extras && nav.extras.state){
      this.theme = nav.extras.state.theme
    }
  }

  persist(redirect: string){
    this.spinner.show();
    if(this.theme.uuid){
      this.themeService.update(this.theme, (error: any, themePersisted: Theme) => {
        if(themePersisted){
          this.toastr.success('', 'O tema foi editado com sucesso');
          if(redirect){
            this.initializeTheme()
            this.router.navigate([redirect])
          } else{
            this.theme = themePersisted
          }
        }
        if(error){
          if(error.error && error.error.message){
            this.toastr.error('', error.error.message);
          } else{
            this.toastr.error('', 'Falha ao editar tema, entre em contato com o suporte ou tente novamente mais tarde');
          }
        }
        this.spinner.hide();
      });
    }else{
      this.themeService.create(this.theme, (error: any, themePersisted: Theme) => {
        if(themePersisted){
          this.toastr.success('', 'O tema foi editado com sucesso');
          if(redirect){
            this.initializeTheme()
            this.router.navigate([redirect])
          } else{
            this.theme = themePersisted
          }
        }
        if(error){
          if(error.error && error.error.message){
            this.toastr.error('', error.error.message);
          } else{
            this.toastr.error('', 'Falha ao cadastrar tema, entre em contato com o suporte ou tente novamente mais tarde');
          }
        }
        this.spinner.hide();
      });
    }
  }

  delete(){
    this.spinner.show();
    this.themeService.delete(this.theme.uuid, (error: any, success: any) => {
      if(success){
        this.toastr.success('', 'O tema foi excluído com sucesso');
        this.router.navigate(["/themes"])
      }
      if(error){
        if(error.error && error.error.message){
          this.toastr.error('', error.error.message);
        } else{
          this.toastr.error('', 'Falha ao excluir tema, entre em contato com o suporte ou tente novamente mais tarde');
        }
      }
      this.spinner.hide();
    })
  }

  initializeTheme(){
    this.theme = new Theme()
    this.theme.backgroundColor = "#ffffff"
    this.theme.foregroundColor = "#000000"
  }

  ngOnInit() {
    var uuid = this.route.snapshot.paramMap.get("uuid")
    if(!uuid){
      this.initializeTheme()
    } else if(!this.theme || !this.theme.uuid){
      this.router.navigate(["/themes"])
    }
  }

}
